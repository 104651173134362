import React, { useState } from "react";
import PropTypes from "prop-types";
import * as R from "ramda";

import DialogForm from "../forms/dialog/DialogForm";
import Select from "../../components/inputs/selects/Select";
import AsyncSelect from "../../components/inputs/selects/AsyncSelect";
import withFormContainer from "./withFormContainer";
import EmployerSelect from "./EmployerSelect";
import { KeyboardDatePicker } from "@material-ui/pickers";

const partnerTypeOptions = identifiers => [
  {
    label: `Partner ${identifiers.employerIdentifier} Metrics`,
    value: "company_report"
  }
];
const employerTypeOptions = [];
const defaultTypeOptions = [
  { label: "Impact Report", value: "employer_impact_report" }
];
const loanContributionDetailsOption = [
  { label: "Loan Contribution Details", value: "loan_payment_details" }
];
const partnerDefault = loanContributionDetailsOption.concat(defaultTypeOptions);
const typeOptionsFrom = identifiers =>
  R.ifElse(
    R.pipe(
      R.prop("resource"),
      R.equals("partners")
    ),
    R.always(partnerTypeOptions(identifiers).concat(partnerDefault)),
    R.ifElse(
      R.pathEq(["employer", "pay_enabled?"], true),
      R.always(defaultTypeOptions.concat(loanContributionDetailsOption)),
      R.always(defaultTypeOptions.concat(employerTypeOptions))
    )
  );

const createInvoiceIdOption = R.applySpec({
  label: R.prop("invoice_name"),
  value: R.prop("id")
});
const optionsFromReports = R.map(createInvoiceIdOption);

function Form({
  resource,
  open,
  defaultValues,
  stateProps,
  onClose,
  onSubmit,
  onTypeChange,
  onEmployerChange,
  partnerId,
  entityIdentifiers,
  employer = {},
  handleChangeFromDate,
  handleChangeToDate
}) {
  const reportType = stateProps.reportTypeState[0];
  
  const [selectedDate_from, handleDateChange_from] = useState(new Date());
  const [selectedDate_to, handleDateChange_to] = useState(new Date());

  return (
    <DialogForm
      title="Generate Report"
      open={open}
      defaultValues={defaultValues}
      onClose={onClose}
      onSubmit={onSubmit}
      loading={stateProps.generateReport.loading}
    >
      <Select
        name="type"
        label="Type"
        options={typeOptionsFrom(entityIdentifiers)({ resource, employer })}
        onChange={onTypeChange}
        validate={{ required: "required" }}
      />
      {R.includes(reportType, [
        "loan_payment_details",
        "employer_impact_report"
      ]) &&
        R.equals("partners", resource) && (
          <EmployerSelect
            partnerId={partnerId}
            onChange={onEmployerChange}
            validate={{ required: "required" }}
          />
        )}
      {R.includes(reportType, ["loan_payment_details"]) && (
        <AsyncSelect
          name="invoice_id"
          label="Invoice ID"
          validate={{ required: "required" }}
          options={optionsFromReports(stateProps.invoicesState[0])}
          loading={stateProps.getInvoices.loading}
        />
      )}
      <KeyboardDatePicker
        label="From"
        placeholder="02/18/2021"
        value={selectedDate_from}
        onChange={date => {
          handleDateChange_from(date);
          handleChangeFromDate(date);
        }}
        
        format="MM/dd/yyyy"
        name="from_date"
      />
      <KeyboardDatePicker
        label="To"
        placeholder="02/18/2021"
        value={selectedDate_to}
        onChange={date => { 
          handleDateChange_to(date);
          handleChangeToDate(date);
        }}
        
        format="MM/dd/yyyy"
        name="to_date"
      />
    </DialogForm>
  );
}

Form.propTypes = {
  resource: PropTypes.oneOf(["partners", "employers"]).isRequired,
  id: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  employerSelectProps: PropTypes.shape({
    loading: PropTypes.bool,
    employers: PropTypes.array
  })
};

export default withFormContainer(Form);
