import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector, clearFields } from "redux-form";
import { map, differenceWith, isEqual, concat, includes, size } from "lodash";
import Fuse from "fuse.js";
import {
  TextField as TextInput,
  Toggle as ToggleInput
} from "redux-form-material-ui";
import RaisedButton from "material-ui/RaisedButton";
import FlatButton from "material-ui/FlatButton";
import TextField from "material-ui/TextField";
import {
  Table,
  TableBody,
  TableRow,
  TableRowColumn,
  TableHeader,
  TableHeaderColumn
} from "material-ui/Table";
import CircularProgress from "material-ui/CircularProgress";
import { required, maxLength50, positive } from "helpers/validation";
import { CurrencyInput } from "components";
import { DeleteConfirmationModal } from "components/modals";
import Grid from "components/grids/Grid";
import { fetchEmployees } from "employees/actions";
import { createGroup, editGroup } from "groups/actions";
import { openModal } from "app/uiActions";
import { selectEmployeesByEmployer } from "employees/selectors";
import { selectEmployerByID } from "employers/selectors";
import { SORT_ASC } from "constants/index";
import { selectEntityIdentifiers } from "partners/selectors";

const styles = {
  inputUnderline: { borderColor: "#258ffa" },
  inputLabel: { color: "#00acc1" },
  raisedButton: { borderRadius: "3px" },
  width60: { width: "60%" },
  width100: { width: "100%" },
  formButtonsBar: { marginTop: "20px", float: "right" },
  payToggle: {
    width: "20%",
    fontWeight: "bold",
    display: "inline-block",
    marginTop: "5%",
    marginRight: "10%"
  },
  sendInviteToggle: {
    fontWeight: "bold",
    display: "inline-block",
    marginTop: "5%",
    marginRight: "10%"
  },
  inline40: { display: "inline-block", width: "40%" },
  spaceRight: { marginRight: "5%" },
  employeeOptionsContainer: {
    display: "flex",
    alignItems: "columns",
    justifyContent: "space-between"
  },
  employeeSelectContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "10px",
    height: "250px",
    overflow: "auto"
  },
  search: {
    marginTop: "10px",
    alignSelf: "flex-end",
    flexBasis: "30%"
  },
  tableColumnWidth20: { paddingLeft: 0, width: "20%" },
  tableColumnWidth30: { paddingLeft: 0, width: "30%" },
  employeesNumber: {
    marginTop: "30px",
    marginLeft: "30px",
    display: "inline-block"
  },
  payToggleInfo: {
    position: "absolute",
    top: "35px",
    right: "20%",
    width: "50%",
    fontSize: "11px",
    textAlign: "center",
    fontWeight: "bold"
  }
};

export class GroupForm extends React.Component {
  static propTypes = {
    employees: PropTypes.array,
    ultiproReady: PropTypes.bool,
    initialEmployeeIDs: PropTypes.array,
    employerID: PropTypes.string.isRequired,
    employerPayEnabled: PropTypes.bool.isRequired,
    formName: PropTypes.string.isRequired,
    backButtonLabel: PropTypes.string,
    submitButtonLabel: PropTypes.string,
    fetchEmployees: PropTypes.func.isRequired,
    label: PropTypes.string,
    groupID: PropTypes.string,
    isDefaultGroup: PropTypes.bool,
    openModal: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired
  };

  static defaultProps = {
    submitButtonLabel: "Save"
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedEmployeeIDs: props.initialEmployeeIDs,
      searchTerm: ""
    };
  }

  componentDidMount() {
    const sort = { field: "full_name", order: SORT_ASC };
    const limit = "all";
    this.props.fetchEmployees(this.props.employerID, sort, null, limit);
  }

  _clearPayFields = () => {
    const { formName, clearFields } = this.props;
    clearFields(
      formName,
      false,
      false,
      "services_attributes[0].match_amount",
      "services_attributes[0].lifetime_cap"
    );
  };

  _search = e => {
    this.setState({ searchTerm: e.target.value });
  };

  _submit = values => {
    const {
      createGroup,
      editGroup,
      form,
      employerID,
      groupID,
      initialEmployeeIDs,
      ultiproReady,
      onSuccess
    } = this.props;
    const { selectedEmployeeIDs } = this.state;

    switch (form) {
      case "groupCreate":
        return createGroup(
          values,
          form,
          employerID,
          selectedEmployeeIDs,
          onSuccess
        );
      case "groupEdit":
        const addedEmployeeIDs = differenceWith(
          selectedEmployeeIDs,
          initialEmployeeIDs,
          isEqual
        );
        const removedEmployeeIDs = differenceWith(
          initialEmployeeIDs,
          selectedEmployeeIDs,
          isEqual
        );

        // If employer is not set up for Ultipro, remove this key as it's currently irrelevant for non-Ultipro employers
        if (!ultiproReady) {
          delete values.automatic_invite;
        }

        const isValuesChanged = !isEqual(values, this.props.initialValues);
        const areEmployeesChanged =
          addedEmployeeIDs.length > 0 || removedEmployeeIDs.length > 0;

        const editAction = () => {
          editGroup(
            values,
            form,
            groupID,
            employerID,
            addedEmployeeIDs,
            removedEmployeeIDs
          );
        };

        return isValuesChanged || areEmployeesChanged
          ? this._openConfirmationModal(editAction)
          : editAction();
      default:
        return null;
    }
  };

  _openConfirmationModal(editAction) {
    this.props.openModal(
      <DeleteConfirmationModal
        title="Edit Group"
        deleteAction={() => editAction()}
        message={
          'All users added or existing this group will immediately have group settings applied to them. All removed users will be moved to "Default" group.'
        }
        isDeleteAction={false}
        buttonLabel="Update"
      />
    );
  }

  isSelected = employeeID => {
    return includes(this.state.selectedEmployeeIDs, employeeID);
  };

  handleRowSelection = (selectedRows, employees) => {
    // e.g. If employees [a, b, c] are originally selected, and
    // the employee search result shows only employees [c, d], and
    // employee [d] is checked and employee [c] is unchecked,
    // => the selected employees should be [a, b, d].
    //
    // selectedEmployeeIDs = [a, b, c], employeeIDs = [c, d], newSelectedEmployeeIDs = [d]
    if (selectedRows === "all") {
      const ids = employees.map(employee => {
        return employee.id;
      });

      this.setState({ selectedEmployeeIDs: ids });
      return;
    } else if (selectedRows === "none") {
      this.setState({ selectedEmployeeIDs: [] });
      return;
    }

    const newSelectedEmployeeIDs = map(selectedRows, row => employees[row].id);
    const employeeIDs = map(employees, employee => employee.id);

    // [a, b, c] - [c, d] = [a, b]
    const uniqSelectedEmployeeIDs = differenceWith(
      this.state.selectedEmployeeIDs,
      employeeIDs,
      isEqual
    );

    // [a, b] + [d] = [a, b, d]
    const totalSelectedEmployeeIDs = concat(
      uniqSelectedEmployeeIDs,
      newSelectedEmployeeIDs
    );

    this.setState({ selectedEmployeeIDs: totalSelectedEmployeeIDs });
  };

  employeeSelectTable(employees) {
    return (
      <Table
        multiSelectable={true}
        onRowSelection={(...args) =>
          this.handleRowSelection(...args, employees)
        }
      >
        <TableHeader
          displaySelectAll={true}
          adjustForCheckbox={true}
          enableSelectAll={true}
        >
          <TableRow>
            <TableHeaderColumn style={styles.tableColumnWidth30}>
              Name
            </TableHeaderColumn>
            <TableHeaderColumn style={styles.tableColumnWidth20}>
              Group Name
            </TableHeaderColumn>
            <TableHeaderColumn style={styles.tableColumnWidth30}>
              Email
            </TableHeaderColumn>
          </TableRow>
        </TableHeader>

        <TableBody deselectOnClickaway={false} displayRowCheckbox={true}>
          {employees.map(employee => (
            <TableRow key={employee.id} selected={this.isSelected(employee.id)}>
              <TableRowColumn style={styles.tableColumnWidth30}>
                {employee.full_name}
              </TableRowColumn>
              <TableRowColumn style={styles.tableColumnWidth20}>
                {employee.group_name}
              </TableRowColumn>
              <TableRowColumn style={styles.tableColumnWidth30}>
                {employee.email}
              </TableRowColumn>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }

  convertToFloat(numString) {
    return parseFloat(numString.replace(/,/g, ""));
  }

  render() {
    const {
      handleSubmit,
      handlePrev,
      submitButtonLabel,
      ultiproReady,
      payEnabled,
      employerPayEnabled,
      employees,
      isDefaultGroup,
      isLoading,
      entityIdentifiers
    } = this.props;

    var fuseOptions = {
      shouldSort: true,
      threshold: 0.6,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: ["full_name"]
    };

    const { searchTerm } = this.state;
    const employeesSearchResult =
      searchTerm.length > 0
        ? new Fuse(employees, fuseOptions).search(searchTerm)
        : employees;

    return (
      <div>
        <form onSubmit={handleSubmit(this._submit)}>
          <Grid container spacing={32} alignItems="center">
            <Grid item>
              <Field
                name="name"
                component={TextInput}
                floatingLabelText="Name"
                floatingLabelShrinkStyle={styles.inputLabel}
                underlineStyle={styles.inputUnderline}
                validate={[required, maxLength50]}
                disabled={isDefaultGroup}
              />
            </Grid>
            <Grid item>
              {ultiproReady && (
                <Field
                  name="automatic_invite"
                  component={ToggleInput}
                  label="Auto Send Invite"
                  style={styles.sendInviteToggle}
                />
              )}
            </Grid>
          </Grid>
          <div style={{ display: "flex", position: "relative" }}>
            <Field
              name="pay_enabled?"
              component={ToggleInput}
              label="PAY"
              style={styles.payToggle}
              onChange={(event, newValue, prevValue, name) => {
                return newValue ? event : event && this._clearPayFields();
              }}
              autoFocus={true}
              disabled={!employerPayEnabled}
            />
            {employerPayEnabled ? (
              <div style={styles.width100}>
                <Field
                  name="services_attributes[0].match_amount"
                  component={CurrencyInput}
                  label="Contribution Amount *"
                  floatingLabelShrinkStyle={
                    payEnabled ? styles.inputLabel : null
                  }
                  style={{ ...styles.inline40, ...styles.spaceRight }}
                  validate={payEnabled ? [required, positive] : []}
                  disabled={!payEnabled || !employerPayEnabled}
                  parse={val => this.convertToFloat(val) || 0}
                />
                <Field
                  name="services_attributes[0].lifetime_cap"
                  component={CurrencyInput}
                  label="Lifetime Cap"
                  floatingLabelShrinkStyle={
                    payEnabled ? styles.inputLabel : null
                  }
                  style={styles.inline40}
                  disabled={!payEnabled || !employerPayEnabled}
                  parse={val => {
                    const matchVal = this.convertToFloat(val);
                    return isFinite(matchVal) ? matchVal : null;
                  }}
                />
              </div>
            ) : (
              <div style={styles.payToggleInfo}>
                {`To turn on pay for this group, turn pay on for your ${entityIdentifiers.employerIdentifier}`}
              </div>
            )}
          </div>
          <div>
            <div style={styles.employeeOptionsContainer}>
              <div style={styles.search}>
                <TextField
                  hintText="Search"
                  style={{ width: "100%" }}
                  onChange={e => this._search(e)}
                />
              </div>
            </div>
            <div style={styles.employeeSelectContainer}>
              {isLoading ? (
                <CircularProgress size={40} />
              ) : employeesSearchResult.length === 0 ? (
                `No ${entityIdentifiers.employeeIdentifier}s`
              ) : (
                this.employeeSelectTable(employeesSearchResult)
              )}
            </div>
          </div>
          <div style={styles.employeesNumber}>
            {size(this.state.selectedEmployeeIDs)}
            {` ${entityIdentifiers.employeeIdentifier}s Selected`}
          </div>
          <div style={styles.formButtonsBar}>
            <FlatButton
              label="Cancel"
              type="button"
              onClick={handlePrev}
              style={{ marginRight: "12px" }}
            />
            <RaisedButton
              label={submitButtonLabel}
              type="submit"
              buttonStyle={styles.raisedButton}
              style={styles.raisedButton}
              primary={true}
            />
          </div>
        </form>
      </div>
    );
  }
}

const inputValueSelector = (formName, ...other) =>
  formValueSelector(formName)(...other);

const mapStateToProps = (state, props) => {
  const employerID = props.employerID;
  const employees = selectEmployeesByEmployer(state, employerID);
  const employer = selectEmployerByID(state, employerID);
  const ultiproReady = employer && employer.ultipro_ready;
  const entityIdentifiers = selectEntityIdentifiers(state);

  return {
    form: props.formName,
    payEnabled: inputValueSelector(props.formName, state, "pay_enabled?"),
    employees,
    ultiproReady,
    isLoading: state.employees.isFetching,
    entityIdentifiers
  };
};

const mapDispatchToProps = (_dispatch, _ownProps) => {
  return {
    clearFields,
    openModal,
    fetchEmployees,
    createGroup,
    editGroup
  };
};

GroupForm = reduxForm({
  // destroyOnMount=false and forceUnregisterOnUnmount=true required for stepper
  // wizard so that form data is not lost across steps.
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true
})(GroupForm);

export default connect(
  mapStateToProps,
  mapDispatchToProps()
)(GroupForm);
