import React from "react";
import { format } from "date-fns";
import Grid from "@material-ui/core/Grid";

import useFetch from "components/containers/utils/useFetch";
import DialogForm from "components/forms/dialog/Dialog";
import EmailField from "components/inputs/Email";
import NameField from "components/inputs/Name";
import VestedField from "components/inputs/date/KeyboardDatePicker";
import CheckboxField from "components/inputs/checkbox/Field";
import GroupSelect from "../forms/GroupSelect";

const defaultValues = {
  personal_id: "",
  email: "",
  first_name: "",
  last_name: "",
  group_id: "",
  employee_status: true,
  invite_now: "",
  vested_started_on: null
};

function EmployeeCreateModal({ employerID, onSuccess }) {
  const [open, setOpen] = React.useState(true);
  const createEmployer = useFetch({ method: "post" });

  function handleClose() {
    setOpen(false);
  }

  function handleSubmit(values) {
    function handleSuccess() {
      if (onSuccess) onSuccess();
      setOpen(false);
    }

    function handleError() {
      setOpen(false);
    }

    const path = `/employees`;
    const data = {
      type: "employees",
      attributes: {
        ...values,
        effective_on: values.employee_status ? format(Date.now(), "P") : null,
        deactivated_on: !values.employee_status
          ? format(Date.now(), "P")
          : null,
        personal_ids_attributes: [
          {
            employer_id: employerID,
            pid: values.personal_id
          }
        ],
        employer_id: employerID,
        user_attributes: {
          email: values.email
        }
      }
    };
    createEmployer.makeFetch({
      path,
      data,
      onSuccess: handleSuccess,
      onError: handleError
    });
  }

  return (
    <DialogForm
      title="New Employee"
      open={open}
      defaultValues={defaultValues}
      onClose={handleClose}
      onSubmit={handleSubmit}
      disabled={createEmployer.loading}
      loading={createEmployer.loading}
    >
      {({ handleSubmit, loading, ...fieldProps }) => (
        <>
          <NameField name="personal_id" label="Personal Id" {...fieldProps} />
          <EmailField {...fieldProps} />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <NameField name="first_name" label="First Name" {...fieldProps} />
            </Grid>
            <Grid item xs={6}>
              <NameField name="last_name" label="Last Name" {...fieldProps} />
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={6}>
              <GroupSelect employerId={employerID} {...fieldProps} />
            </Grid>
            <Grid item xs={6}>
              <VestedField
                name="vested_started_on"
                label="Employment Start Date"
                {...fieldProps}
              />
            </Grid>
          </Grid>
          <Grid container direction="column">
            <Grid item xs={6}>
              <CheckboxField
                name="employee_status"
                label="Active"
                checked={defaultValues.employee_status}
                {...fieldProps}
              />
            </Grid>
            <Grid item xs={6}>
              <CheckboxField
                name="invite_now"
                label="Invite Now"
                {...fieldProps}
              />
            </Grid>
          </Grid>
        </>
      )}
    </DialogForm>
  );
}

export default EmployeeCreateModal;
