import urlService from "../../rest/urlService";

function routeService({ urlService }) {
  const _urlService = urlService;
  const _baseUrl = _urlService.getBase();

  const _prefix = () => {
    return `${_baseUrl}/employers`;
  };

  const getEmployerReports = employerId => {
    return `${_prefix()}/${employerId}/reports`;
  };

  const getEmployerReportDownload = (employerId, reportId) => {
    return `${_prefix()}/${employerId}/reports/${reportId}/download`;
  };

  const getRefiVendors = employerId => {
    return `${_prefix()}/${employerId}/refi_vendors`;
  };

  const toggleVendor = employerId => {
    return `${_prefix()}/${employerId}/toggle_refi_vendor`;
  };

  const update = employerId => {
    return `${_prefix()}/${employerId}/`;
  };

  const testUltiproCredentials = employerId => {
    return `${_prefix()}/${employerId}/test_ultipro_credentials`;
  };

  const ultiproSyncNow = employerId => {
    return `${_prefix()}/${employerId}/ultipro_sync`;
  };

  const ultiproLogs = employerId => {
    return `${_prefix()}/${employerId}/ultipro_logs`;
  };

  const bulkUpload = employerId => {
    return `${_prefix()}/${employerId}/batch_upserts`;
  };

  const bulkUploadHistory = employerId => {
    return `${_prefix()}/${employerId}/batch_upserts`;
  };

  const bulkUploadJobs = (employerId, batchId) => {
    return `${_prefix()}/${employerId}/batch_upserts/${batchId}`;
  };

  const jobDetails = (employerId, batchId) => {
    return `${_prefix()}/${employerId}/batch_upserts/${batchId}/batch_upsert_job_details`;
  };

  const csvDownload = (employerId, batchId) => {
    return `${_prefix()}/${employerId}/batch_upserts/${batchId}/batch_upsert_job_details.csv`;
  };

  const getContacts = employerId => {
    return `${_prefix()}/${employerId}/contacts`;
  };

  const createContact = () => {
    return `${_baseUrl}/contacts`;
  };

  const updateContact = contactId => {
    return `${_baseUrl}/contacts/${contactId}`;
  };

  const deleteContact = contactId => {
    return `${_baseUrl}/contacts/${contactId}`;
  };

  const getImpactReport = employerId => {
    return `${_prefix()}/${employerId}/download_employer_impact_report`;
  };

  return {
    getEmployerReports,
    getEmployerReportDownload,
    getRefiVendors,
    toggleVendor,
    update,
    testUltiproCredentials,
    ultiproSyncNow,
    ultiproLogs,
    bulkUpload,
    bulkUploadHistory,
    bulkUploadJobs,
    jobDetails,
    csvDownload,
    createContact,
    updateContact,
    deleteContact,
    getContacts,
    getImpactReport
  };
}

export default routeService({ urlService });
