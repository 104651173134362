import initialState from "./initialState";
import {
  CREATE_EMPLOYEE_SUCCESS,
  EDIT_EMPLOYEE_SUCCESS,
  FETCH_EMPLOYEE,
  FETCH_EMPLOYEE_FAILURE,
  FETCH_EMPLOYEE_SUCCESS,
  FETCH_EMPLOYEES,
  FETCH_EMPLOYEES_FAILURE,
  FETCH_EMPLOYEES_SUCCESS,
  SEND_EMPLOYEE_INVITE,
  SEND_EMPLOYEE_INVITE_FAILURE,
  SEND_EMPLOYEE_INVITE_SUCCESS
} from "../actions/types";
import keyBy from "lodash/keyBy";
import includes from "lodash/includes";
import concat from "lodash/concat";

export const employeesReducer = (
  previousState = initialState.employees,
  { type, payload }
) => {
  switch (type) {
    case FETCH_EMPLOYEES:
    case FETCH_EMPLOYEE:
    case SEND_EMPLOYEE_INVITE:
      return { ...previousState, isFetching: true };
    case FETCH_EMPLOYEES_FAILURE:
    case FETCH_EMPLOYEE_FAILURE:
    case SEND_EMPLOYEE_INVITE_SUCCESS:
    case SEND_EMPLOYEE_INVITE_FAILURE:
      return { ...previousState, isFetching: false };
    case FETCH_EMPLOYEES_SUCCESS:
      return {
        ...previousState,
        byID: {
          ...previousState.byID,
          ...keyBy(payload.employees.data, "id")
        },
        items: payload.employees.data.map(item => item.id),
        total: payload.employees.total,
        receivedAt: Date.now(),
        isFetching: false
      };
    case FETCH_EMPLOYEE_SUCCESS:
      console.log("FETCH_EMPLOYEE_SUCCESS");
      return {
        ...previousState,
        byID: { ...previousState.byID, [payload.id]: payload },
        items: includes(previousState.items, payload.id)
          ? previousState.items
          : concat(previousState.items, payload.id),
        isFetching: false,
        receivedAt: Date.now()
      };
    case CREATE_EMPLOYEE_SUCCESS:
      return {
        ...previousState,
        byID: { ...previousState.byID, [payload.id]: payload },
        items: concat(previousState.items, payload.id),
        total: previousState.total + 1
      };
    case EDIT_EMPLOYEE_SUCCESS:
      return {
        ...previousState,
        byID: { ...previousState.byID, [payload.id]: payload }
      };
    default:
      return previousState;
  }
};
