import React from "react";
import compose from "recompose/compose";
import { connect } from "react-redux";

/** legacy code, will remove once new form is done */
import { openModal } from "app/uiActions";
import { GroupCreateModal } from "../modals";
import { allowEmployerManager } from "services/permission/service";
import NewButton from "components/buttons/action/New";

const PageAction = ({
  disabled,
  employerId,
  employerLocked,
  openModal,
  stateProps: { requestData },
  employerPayEnabled
}) => {
  const handleNewClick = () => {
    const handleSuccess = () => requestData();

    openModal(
      <GroupCreateModal
        employerID={employerId}
        onSuccess={handleSuccess}
        employerPayEnabled={employerPayEnabled}
      />
    );
  };

  return allowEmployerManager() ? (
    <NewButton disabled={employerLocked || disabled} onClick={handleNewClick}>
      New Group
    </NewButton>
  ) : null;
};

export default compose(
  connect(
    null,
    {
      openModal
    }
  )
)(PageAction);
