import React from "react";
import PropTypes from "prop-types";
import * as R from "ramda";
import { useSnackbar } from "notistack";

import log from "../logging/service";
import fetchService from "./fetchService";

const mergeSort = o => ({ ...o, sort: { order: o.order, field: o.orderBy } });
const createParams = R.pipe(
  R.ifElse(
    R.allPass([R.prop("order"), R.prop("orderBy")]),
    mergeSort,
    R.identity
  ),
  R.dissoc("order"),
  R.dissoc("orderBy")
);

function useMakeFetch({ method }) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [data, setData] = React.useState([]);
  const [total, setTotal] = React.useState(0);

  async function makeFetch({
    path,
    onSuccess,
    onError,
    successMessage,
    errorMessage,
    ...props
  }) {
    try {
      closeSnackbar();
      setLoading(true);

      let response;
      if (method === "get") {
        const params = createParams(props);
        response = await fetchService.get(path, params);
      } else if (method === "post") {
        response = await fetchService.post(path, props.data);
      } else if (method === "delete") {
        response = await fetchService.del(path);
      } else if (method === "put") {
        response = await fetchService.put(path);
      } else if (method === "patch") {
        response = await fetchService.patch(path, props.data);
      } else if (method === "fetchFile") {
        response = await fetchService.fetchFile(path, props);
      } else if (method === "fetchFileData") {
        response = await fetchService.fetchFileData(path, props);
      }

      const dataFromResponse = R.path(["data", "data"], response);
      const totalFromResponse = R.path(["data", "meta", "total"], response);
      setData(dataFromResponse);
      setTotal(totalFromResponse);
      successMessage && enqueueSnackbar(successMessage, { variant: "success" });
      if (onSuccess) {
        onSuccess({ data: dataFromResponse, total: totalFromResponse });
      }
    } catch (e) {
      if (onError) onError();
      enqueueSnackbar(e.message, { variant: "error" });
      setError(errorMessage || e.message);
      log.error(e.message);
    }

    setLoading(false);
  }

  return {
    makeFetch,
    loading,
    error,
    data,
    total
  };
}

useMakeFetch.propTypes = {
  method: PropTypes.oneOf(["get", "post", "patch", "put"]).isRequired
};

export default useMakeFetch;
