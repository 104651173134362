import React from "react";
import { Route, Redirect } from "react-router-dom";
import { includes } from "lodash";

import {
  ROLE,
  TOKEN,
  SYSTEM_ADMIN,
  PARTNER_ADMIN,
  EMPLOYERS_ADMIN,
  EMPLOYER_MANAGER,
  SUPPORT_REP,
  BENEFITS_ADMIN,
  PARTNER_ID,
  EMPLOYER_ID,
  TWO_FACTOR,
  PARTNER_MANAGER
} from "../constants";
import { ResetPassword, RequestPassword } from "../login";
import { AdminUserRegistration } from "../adminUsers";
import Verify from "../twoFactor/Verify";
import { Employer, EmployerSettings, EmployerDashboard } from "../employers";

/** new list page, turn on when ready */
import EmployersList from "../employers/list/EmployersList";
import EmployeesList from "../employees/components/list/EmployeesList";
import GroupsList from "../groups/components/list/GroupsList";
import AdminUsers from "../partners/list/UsersList";
import GlobalSettings from "../vault/list/AdminUsersList";
import {
  PartnerSettings,
  PartnerDashboard,
  GeneralSettings,
  RefiSettings
} from "../partners";
import { EmployeeShow } from "../employees";
import { GroupShow } from "../groups";
import EmployerRefiVendorsPage from "../employers/refi-vendors/Page";
import EmployerContactsPage from "../employers/contacts/ContactsList";
import EmployerUltiproPage from "../employers/ultipro/Page";
import UltiproLogsPage from "../employers/ultipro-logs/Page";
import GlobalSearchPage from "../global-search/Page";
import BulkHistoryPage from "../employers/bulk-history/List";
import BulkHistoryJobsPage from "../employers/bulk-history/JobsList";
import PartnerReportPage from "../partners/reports/ReportPage";
import EmployerReportPage from "../employers/reports/ReportPage";
import InvoicesPage from "../employers/invoices/InvoicesPage";
import InvoicePage from "../employers/invoices/InvoicePage";
import EmployerContributionPage from "../employers/contribution/ContributionList";

// We want the index route to be the show page for the employer or the employer list
export const defaultRoute = () => {
  const role = localStorage.getItem(ROLE);
  const employer_id = localStorage.getItem(EMPLOYER_ID);
  const partner_id = localStorage.getItem(PARTNER_ID);
  const token = localStorage.getItem(TOKEN);

  // Redirect to login if not authenticated
  let route = "/login";
  if (token) {
    if (role === TWO_FACTOR) {
      route = `/two_factor/verify`;
    } else if (role === BENEFITS_ADMIN) {
      route = `/employers/${employer_id}/dashboard`;
    } else if (
      includes([EMPLOYERS_ADMIN, EMPLOYER_MANAGER, SUPPORT_REP], role)
    ) {
      route = `/partners/${partner_id}/employers`;
    } else if (includes([PARTNER_ADMIN, SYSTEM_ADMIN, PARTNER_MANAGER], role)) {
      route = `/partners/${partner_id}/dashboard`;
    }
  }

  return route;
};

const hasPermission = allowed => {
  return includes(allowed, localStorage.getItem(ROLE));
};

export function customRoutes() {
  return [
    <Route exact path="/" render={() => <Redirect to={defaultRoute()} />} />,
    <Route
      exact
      path="/registrations/*"
      component={AdminUserRegistration}
      noLayout
    />,
    <Route exact path="/passwords/*" component={ResetPassword} noLayout />,
    <Route exact path="/passwords" component={RequestPassword} noLayout />,
    <Route exact path="/two_factor/verify" component={Verify} noLayout />,
    <Route
      exact
      path="/partners/:partnerID/employers"
      render={props => {
        return hasPermission([
          SYSTEM_ADMIN,
          PARTNER_ADMIN,
          PARTNER_MANAGER,
          EMPLOYERS_ADMIN,
          EMPLOYER_MANAGER,
          SUPPORT_REP
        ]) ? (
          <EmployersList {...props} />
        ) : (
          <Redirect to={defaultRoute()} />
        );
      }}
    />,
    <Route
      exact
      path="/partners/:partnerID/employers/:employerID"
      render={props => {
        return hasPermission([
          SYSTEM_ADMIN,
          PARTNER_ADMIN,
          PARTNER_MANAGER,
          EMPLOYERS_ADMIN,
          EMPLOYER_MANAGER,
          SUPPORT_REP,
          BENEFITS_ADMIN
        ]) ? (
          <Employer {...props} />
        ) : (
          <Redirect to={defaultRoute()} />
        );
      }}
    />,
    <Route
      exact
      path="/employers/:employerID/dashboard"
      render={props => {
        return hasPermission([
          SYSTEM_ADMIN,
          PARTNER_ADMIN,
          PARTNER_MANAGER,
          EMPLOYERS_ADMIN,
          EMPLOYER_MANAGER,
          SUPPORT_REP,
          BENEFITS_ADMIN
        ]) ? (
          <Employer
            {...props}
            render={({
              employerID,
              employer,
              employerStats,
              employerStatsLoading,
              employerReports,
              employerReportsIsLoading,
              requestDownloadReport
            }) => {
              return (
                <EmployerDashboard
                  employerID={employerID}
                  benefitStats={employerStats.benefitStats}
                  advisorStats={employerStats.advisorStats}
                  payEnabled={employer["pay_enabled?"]}
                  employerName={employer.name}
                  totalEmployees={employer.total_employees}
                  invitedEmployeesCount={employer.invited_employees_count}
                  isLoading={employerStatsLoading}
                  employerReports={employerReports}
                  employer={employer}
                  employerReportsIsLoading={employerReportsIsLoading}
                  requestDownloadReport={requestDownloadReport}
                />
              );
            }}
          />
        ) : (
          <Redirect to={defaultRoute()} />
        );
      }}
    />,
    <Route
      exact
      path="/employers/:employerID/reports"
      render={props => {
        return hasPermission([
          SYSTEM_ADMIN,
          PARTNER_ADMIN,
          PARTNER_MANAGER,
          EMPLOYERS_ADMIN,
          EMPLOYER_MANAGER,
          SUPPORT_REP,
          BENEFITS_ADMIN
        ]) ? (
          <Employer
            {...props}
            render={employer => {
              return (
                <EmployerReportPage
                  employerID={employer.employerID}
                  employer={employer.employer}
                  {...props}
                />
              );
            }}
          />
        ) : (
          <Redirect to={defaultRoute()} />
        );
      }}
    />,
    <Route
      exact
      path="/employers/:employerID/settings/settings"
      render={props => {
        return hasPermission([
          SYSTEM_ADMIN,
          PARTNER_ADMIN,
          PARTNER_MANAGER,
          EMPLOYERS_ADMIN,
          EMPLOYER_MANAGER,
          SUPPORT_REP,
          BENEFITS_ADMIN
        ]) ? (
          <Employer
            {...props}
            render={employer => {
              return <EmployerSettings employer={employer} />;
            }}
          />
        ) : (
          <Redirect to={defaultRoute()} />
        );
      }}
    />,
    <Route
      exact
      path="/employers/:employerID/invoices/list"
      render={props => {
        return hasPermission([
          SYSTEM_ADMIN,
          PARTNER_ADMIN,
          EMPLOYERS_ADMIN,
          BENEFITS_ADMIN,
          PARTNER_MANAGER,
          EMPLOYER_MANAGER,
          SUPPORT_REP
        ]) ? (
          <InvoicesPage {...props} />
        ) : (
          <Redirect to={defaultRoute()} />
        );
      }}
    />,
    <Route
      exact
      path="/employers/:employerID/invoices/contributions"
      render={props => {
        return hasPermission([
          SYSTEM_ADMIN,
          PARTNER_ADMIN,
          EMPLOYERS_ADMIN,
          BENEFITS_ADMIN,
          PARTNER_MANAGER,
          EMPLOYER_MANAGER,
          SUPPORT_REP
        ]) ? (
          <Employer
            {...props}
            render={employer => {
              return (
                <EmployerContributionPage employerId={employer.employerID} />
              );
            }}
          />
        ) : (
          <Redirect to={defaultRoute()} />
        );
      }}
    />,
    <Route
      exact
      path="/employers/:employerID/employees/list"
      render={props => {
        return hasPermission([
          SYSTEM_ADMIN,
          PARTNER_ADMIN,
          PARTNER_MANAGER,
          EMPLOYERS_ADMIN,
          EMPLOYER_MANAGER,
          SUPPORT_REP,
          BENEFITS_ADMIN
        ]) ? (
          <Employer
            {...props}
            render={employer => {
              return (
                <EmployeesList
                  employer={employer.employer}
                  employerID={employer.employerID}
                  employerName={employer.name}
                />
              );
            }}
          />
        ) : (
          <Redirect to={defaultRoute()} />
        );
      }}
    />,
    <Route
      exact
      path="/employers/:employerID/employees/bulkHistory"
      render={props => (
        <Employer
          {...props}
          render={employer => {
            return (
              <BulkHistoryPage
                employer={employer.employer}
                employerID={employer.employerID}
                employerName={employer.name}
              />
            );
          }}
        />
      )}
    />,
    <Route
      exact
      path="/employers/:employerID/batch_upserts/:batchId"
      render={props => (
        <Employer
          {...props}
          render={({ employerID }) => {
            return (
              <BulkHistoryJobsPage
                employerID={employerID}
                batchId={props.match.params.batchId}
                {...props}
              />
            );
          }}
        />
      )}
    />,
    <Route
      exact
      path="/employers/:employerID/employees/:employeeID"
      render={props => {
        return hasPermission([
          SYSTEM_ADMIN,
          PARTNER_ADMIN,
          PARTNER_MANAGER,
          EMPLOYERS_ADMIN,
          EMPLOYER_MANAGER,
          SUPPORT_REP,
          BENEFITS_ADMIN
        ]) ? (
          <Employer
            {...props}
            render={({ employer }) => {
              return (
                <EmployeeShow
                  employeeID={props.match.params.employeeID}
                  employerName={employer.name}
                  employerID={employer.id}
                />
              );
            }}
          />
        ) : (
          <Redirect to={defaultRoute()} />
        );
      }}
    />,

    <Route
      exact
      path="/employers/:employerID/settings/refi"
      render={props => {
        return hasPermission([SYSTEM_ADMIN, PARTNER_ADMIN]) ? (
          <Employer
            {...props}
            render={employer => <EmployerRefiVendorsPage employer={employer} />}
          />
        ) : (
          <Redirect to={defaultRoute()} />
        );
      }}
    />,
    <Route
      exact
      path="/employers/:employerID/settings/contacts"
      render={props => {
        return hasPermission([
          SYSTEM_ADMIN,
          PARTNER_ADMIN,
          PARTNER_MANAGER,
          EMPLOYERS_ADMIN,
          EMPLOYER_MANAGER,
          SUPPORT_REP,
          BENEFITS_ADMIN
        ]) ? (
          <Employer
            {...props}
            render={employer => (
              <EmployerContactsPage {...props} {...employer} />
            )}
          />
        ) : (
          <Redirect to={defaultRoute()} />
        );
      }}
    />,
    <Route
      exact
      path="/employers/:employerID/groups"
      render={props => {
        return hasPermission([
          SYSTEM_ADMIN,
          PARTNER_ADMIN,
          PARTNER_MANAGER,
          EMPLOYERS_ADMIN,
          EMPLOYER_MANAGER,
          SUPPORT_REP,
          BENEFITS_ADMIN
        ]) ? (
          <Employer
            {...props}
            render={(
              { employer, employerID },
              employerStats,
              employerStatsLoading,
              onGroupClick
            ) => {
              return (
                <GroupsList
                  employer={employer}
                  employerID={employerID}
                  employerLocked={employer["locked?"]}
                  employerPayEnabled={employer["pay_enabled?"]}
                  onGroupSelect={groupID => onGroupClick(groupID)}
                />
              );
            }}
          />
        ) : (
          <Redirect to={defaultRoute()} />
        );
      }}
    />,
    <Route
      exact
      path="/employers/:employerID/groups/:groupID"
      render={props => {
        return hasPermission([
          SYSTEM_ADMIN,
          PARTNER_ADMIN,
          PARTNER_MANAGER,
          EMPLOYERS_ADMIN,
          EMPLOYER_MANAGER,
          SUPPORT_REP,
          BENEFITS_ADMIN
        ]) ? (
          <Employer
            {...props}
            render={({ employer }) => {
              return (
                <GroupShow
                  employer={employer}
                  groupID={props.match.params.groupID}
                  employerID={employer.id}
                  employerLocked={employer["locked?"]}
                  employerPayEnabled={employer["pay_enabled?"]}
                  {...props}
                />
              );
            }}
          />
        ) : (
          <Redirect to={defaultRoute()} />
        );
      }}
    />,
    <Route
      exact
      path="/employers/:employerID/settings/ultipro/settings"
      render={props => {
        return hasPermission([
          SYSTEM_ADMIN,
          PARTNER_ADMIN,
          PARTNER_MANAGER,
          EMPLOYERS_ADMIN,
          EMPLOYER_MANAGER,
          SUPPORT_REP,
          BENEFITS_ADMIN
        ]) ? (
          <Employer
            {...props}
            render={({ employer, employerID }) => {
              return (
                <EmployerUltiproPage
                  employer={employer}
                  employerID={employerID}
                />
              );
            }}
          />
        ) : (
          <Redirect to={defaultRoute()} />
        );
      }}
    />,
    <Route
      exact
      path="/employers/:employerID/settings/ultipro/logs"
      render={props => {
        return hasPermission([
          SYSTEM_ADMIN,
          PARTNER_ADMIN,
          PARTNER_MANAGER,
          EMPLOYERS_ADMIN,
          EMPLOYER_MANAGER,
          SUPPORT_REP,
          BENEFITS_ADMIN
        ]) ? (
          <Employer
            {...props}
            render={({ employerID }) => {
              return <UltiproLogsPage employerId={employerID} />;
            }}
          />
        ) : (
          <Redirect to={defaultRoute()} />
        );
      }}
    />,
    <Route
      exact
      path="/employers/:employerID/invoices/:invoiceId"
      render={props => {
        const invoice = props.location.state && props.location.state.invoice;
        const { employerID } = props.match.params;
        return hasPermission([
          SYSTEM_ADMIN,
          PARTNER_ADMIN,
          BENEFITS_ADMIN,
          PARTNER_MANAGER,
          EMPLOYERS_ADMIN,
          EMPLOYER_MANAGER,
          SUPPORT_REP
        ]) ? (
          invoice ? (
            <InvoicePage {...props} />
          ) : (
            <Redirect to={`/employers/${employerID}/invoices/`} />
          )
        ) : (
          <Redirect to={defaultRoute()} />
        );
      }}
    />,
    <Route
      exact
      path="/partners/:partnerID/dashboard"
      render={props => {
        return hasPermission([SYSTEM_ADMIN, PARTNER_ADMIN, PARTNER_MANAGER]) ? (
          <PartnerDashboard {...props} />
        ) : (
          <Redirect to={defaultRoute()} />
        );
      }}
    />,
    <Route
      exact
      path="/partners/:partnerID/reports"
      render={props => {
        return hasPermission([SYSTEM_ADMIN, PARTNER_ADMIN, PARTNER_MANAGER]) ? (
          <PartnerReportPage {...props} />
        ) : (
          <Redirect to={defaultRoute()} />
        );
      }}
    />,
    <Route
      exact
      path="/partners/:partnerID/settings"
      render={props => {
        return hasPermission([SYSTEM_ADMIN, PARTNER_ADMIN]) ? (
          <PartnerSettings {...props} />
        ) : (
          <Redirect to={defaultRoute()} />
        );
      }}
    />,
    <Route
      exact
      path="/partners/:partnerID/settings/general"
      render={props => {
        return hasPermission([SYSTEM_ADMIN, PARTNER_ADMIN]) ? (
          <PartnerSettings
            {...props}
            render={partner => <GeneralSettings partner={partner} />}
          />
        ) : (
          <Redirect to={defaultRoute()} />
        );
      }}
    />,
    <Route
      exact
      path="/partners/:partnerID/settings/refi"
      render={props => {
        return hasPermission([SYSTEM_ADMIN, PARTNER_ADMIN]) ? (
          <PartnerSettings
            {...props}
            render={partner => <RefiSettings partner={partner} />}
          />
        ) : (
          <Redirect to={defaultRoute()} />
        );
      }}
    />,
    <Route
      exact
      path="/partners/:partnerID/settings/users"
      render={props => {
        return hasPermission([SYSTEM_ADMIN, PARTNER_ADMIN]) ? (
          <PartnerSettings
            {...props}
            render={partner => <AdminUsers {...props} />}
          />
        ) : (
          <Redirect to={defaultRoute()} />
        );
      }}
    />,
    <Route
      exact
      path="/partners/:partnerID/globalSearch"
      render={props => <GlobalSearchPage {...props} />}
    />,
    <Route
      exact
      path="/employers/:employerID/globalSearch"
      render={props => <GlobalSearchPage {...props} />}
    />,
    <Route
      exact
      path="/vault"
      render={props => {
        return hasPermission([SYSTEM_ADMIN]) ? (
          <GlobalSettings {...props} />
        ) : (
          <Redirect to={defaultRoute()} />
        );
      }}
    />
  ];
}

export default customRoutes();
