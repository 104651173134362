import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { pick, get } from "lodash";
import { selectEntityIdentifiers } from "partners/selectors";
import SLGModalForm from "components/modals/SLGModalForm";
import { EmployeeForm } from "../forms";

export class EmployeeEditModal extends React.Component {
  static propTypes = {
    selectedEmployee: PropTypes.object.isRequired
  };

  render() {
    const {
      onClose,
      selectedEmployee,
      onSuccess,
      entityIdentifiers
    } = this.props;
    const personalIds = selectedEmployee && selectedEmployee.personal_ids;
    const initialFields = pick(selectedEmployee, [
      "first_name",
      "last_name",
      "employee_status",
      "email",
      "vested_started_on",
      "group_id"
    ]);

    if (personalIds && personalIds.length) {
      initialFields.personal_id = personalIds[0].pid;
    }
    const formName = "employeeEdit";

    return (
      <SLGModalForm
        title={`Edit ${entityIdentifiers.employeeIdentifier}`}
        formName={formName}
        close={onClose}
        contentStyle={{ width: "40%" }}
      >
        <EmployeeForm
          formName={formName}
          label={`Edit ${entityIdentifiers.employeeIdentifier}`}
          backButtonLabel="Cancel"
          submitButtonLabel="Save"
          employerID={get(selectedEmployee, "employer_id")}
          employeeID={selectedEmployee.id}
          employee={selectedEmployee}
          enableReinitialize={true}
          emailDisabled={true}
          initialValues={initialFields}
          onSuccess={onSuccess}
        />
      </SLGModalForm>
    );
  }
}

const mapStateToProps = state => {
  return { entityIdentifiers: selectEntityIdentifiers(state) };
};

export default compose(connect(mapStateToProps))(EmployeeEditModal);
