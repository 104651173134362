import React from "react";
import PropTypes from "prop-types";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { NavLink } from "react-router-dom";
import cx from "classnames";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Hidden from "@material-ui/core/Hidden";
import Collapse from "@material-ui/core/Collapse";
import Icon from "@material-ui/core/Icon";
import Divider from "@material-ui/core/Divider";
import Slide from "@material-ui/core/Slide";

// core components
import HeaderLinks from "packages/mui-dashboard/components/Header/HeaderLinks.jsx";

import sidebarStyle from "packages/mui-dashboard/assets/jss/material-dashboard-pro-react/components/sidebarStyle.jsx";

var ps;

// We've created this component so we can have a ref to the wrapper of the links that appears in our sidebar.
// This was necessary so that we could initialize PerfectScrollbar on the links.
// There might be something with the Hidden component from material-ui, and we didn't have access to
// the links, and couldn't initialize the plugin.
class SidebarWrapper extends React.Component {
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.sidebarWrapper, {
        suppressScrollX: true,
        suppressScrollY: false
      });
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }
  render() {
    const { className, user, headerLinks, links } = this.props;
    return (
      <div className={className} ref="sidebarWrapper">
        {user}
        {headerLinks}
        {links}
      </div>
    );
  }
}

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openAvatar: false,
      openPartnerSettings: this.activeRoute("/settings"),
      openEmployer: true,
      miniActive: true
    };
  }

  openCollapse = collapse => {
    var st = {};
    st[collapse] = !this.state[collapse];
    this.setState(st);
  };

  getShortName(partnerName) {
    return partnerName.split(" ").reduce((res, val) => {
      return res + val.charAt(0);
    }, "");
  }

  activeRoute = routeName => {
    const { location } = this.props;
    if (location == null) return false;
    return location.pathname.indexOf(routeName) > -1;
  };

  handleItemClick = (partnerId, setSelectedPartner) => () => {
    setSelectedPartner(partnerId);
    this.openCollapse("openAvatar");
  };

  render() {
    const {
      classes,
      color,
      image,
      routes,
      bgColor,
      rtlActive,
      partners,
      menuEntity,
      setSelectedPartner,
      createPartner,
      userName,
      isSystemAdmin
    } = this.props;
    const itemText =
      classes.itemText +
      " " +
      cx({
        [classes.itemTextMini]: this.props.miniActive && this.state.miniActive,
        [classes.itemTextMiniRTL]:
          rtlActive && this.props.miniActive && this.state.miniActive,
        [classes.itemTextRTL]: rtlActive
      });
    const collapseItemText =
      classes.collapseItemText +
      " " +
      cx({
        [classes.collapseItemTextMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.collapseItemTextMiniRTL]:
          rtlActive && this.props.miniActive && this.state.miniActive,
        [classes.collapseItemTextRTL]: rtlActive
      });
    const userWrapperClass =
      classes.user +
      " " +
      cx({
        [classes.whiteAfter]: bgColor === "white"
      });
    const caret =
      classes.caret +
      " " +
      cx({
        [classes.caretRTL]: rtlActive
      });
    const collapseItemMini =
      classes.collapseItemMini +
      " " +
      cx({
        [classes.collapseItemMiniRTL]: rtlActive
      });

    const partnerLinks = partners
      .filter(val => val.id !== menuEntity.id)
      .map(partner => {
        return (
          <ListItem className={classes.collapseItem} key={partner.id}>
            <NavLink
              to={`/partners/${partner.id}/dashboard`}
              onClick={this.handleItemClick(partner.id, setSelectedPartner)}
              className={classes.itemLink + " " + classes.userCollapseLinks}
            >
              <span className={collapseItemMini}>
                {this.getShortName(partner.name)}
              </span>
              <ListItemText
                primary={partner.name}
                disableTypography={true}
                className={collapseItemText}
              />
            </NavLink>
          </ListItem>
        );
      });

    var user = (
      <div className={userWrapperClass}>
        <List className={classes.list}>
          <ListItem className={classes.item + " " + classes.userItem}>
            <NavLink
              to={"#"}
              className={classes.itemLink + " " + classes.userCollapseButton}
              onClick={() => this.openCollapse("openAvatar")}
            >
              <ListItemText
                primary={menuEntity.name}
                secondary={
                  this.props.isSystemAdmin ? (
                    <b
                      className={
                        caret +
                        " " +
                        classes.userCaret +
                        " " +
                        (this.state.openAvatar ? classes.caretActive : "")
                      }
                    />
                  ) : null
                }
                disableTypography={true}
                className={itemText + " " + classes.userItemText}
              />
            </NavLink>
            <Collapse in={this.state.openAvatar} unmountOnExit>
              <List className={classes.list + " " + classes.collapseList}>
                {partnerLinks}
                {isSystemAdmin ? (
                  <ListItem className={classes.collapseItem} key="create">
                    <span
                      onClick={createPartner}
                      className={
                        classes.itemLink + " " + classes.userCollapseLinks
                      }
                    >
                      <span className={collapseItemMini}>+</span>
                      <ListItemText
                        primary={"Create Partner"}
                        disableTypography={true}
                        className={collapseItemText}
                      />
                    </span>
                  </ListItem>
                ) : null}
              </List>
            </Collapse>
          </ListItem>
        </List>
      </div>
    );
    var links = (
      <List className={classes.list}>
        {routes.map((prop, key) => {
          if (prop.redirect) {
            return null;
          }
          if (prop.isText) {
            return (
              <ListItem key={key} className={classes.item}>
                {prop.divider ? (
                  <Divider className={classes.whiteDivider} />
                ) : null}
                <ListItemText
                  className={classes.itemText}
                  disableTypography={true}
                  primary={prop.name}
                />
              </ListItem>
            );
          }
          if (prop.collapse) {
            const navLinkClasses =
              classes.itemLink +
              " " +
              cx({
                [" " + classes.collapseActive]: this.activeRoute(prop.path)
              });
            const itemText =
              classes.itemText +
              " " +
              cx({
                [classes.itemTextMini]:
                  this.props.miniActive && this.state.miniActive,
                [classes.itemTextMiniRTL]:
                  rtlActive && this.props.miniActive && this.state.miniActive,
                [classes.itemTextRTL]: rtlActive
              });
            const collapseItemText =
              classes.collapseItemText +
              " " +
              cx({
                [classes.collapseItemTextMini]:
                  this.props.miniActive && this.state.miniActive,
                [classes.collapseItemTextMiniRTL]:
                  rtlActive && this.props.miniActive && this.state.miniActive,
                [classes.collapseItemTextRTL]: rtlActive
              });
            const itemIcon =
              classes.itemIcon +
              " " +
              cx({
                [classes.itemIconRTL]: rtlActive
              });
            const caret =
              classes.caret +
              " " +
              cx({
                [classes.caretRTL]: rtlActive
              });
            return (
              <Slide
                key={key}
                in
                appear={!prop.skipTransition}
                direction="left"
              >
                <ListItem key={key} className={classes.item}>
                  {prop.divider ? (
                    <Divider inset className={classes.whiteDivider} />
                  ) : null}
                  <NavLink
                    to={"#"}
                    className={navLinkClasses}
                    onClick={() => this.openCollapse(prop.state)}
                  >
                    {prop.mini && (
                      <span className={collapseItemMini}>{prop.mini}</span>
                    )}
                    {prop.icon && (
                      <ListItemIcon className={itemIcon}>
                        {typeof prop.icon === "string" ? (
                          <Icon>{prop.icon}</Icon>
                        ) : (
                          <prop.icon />
                        )}
                      </ListItemIcon>
                    )}
                    <ListItemText
                      primary={prop.name}
                      secondary={
                        <b
                          className={
                            caret +
                            " " +
                            (this.state[prop.state] ? classes.caretActive : "")
                          }
                        />
                      }
                      disableTypography={true}
                      className={itemText}
                    />
                  </NavLink>
                  <Collapse in={this.state[prop.state]} unmountOnExit>
                    <List className={classes.list + " " + classes.collapseList}>
                      {prop.views &&
                        prop.views.map((prop, key) => {
                          if (prop.redirect) {
                            return null;
                          }
                          const navLinkClasses =
                            classes.collapseItemLink +
                            " " +
                            cx({
                              [" " + classes[color]]: this.activeRoute(
                                prop.path
                              )
                            });
                          const collapseItemMini =
                            classes.collapseItemMini +
                            " " +
                            cx({
                              [classes.collapseItemMiniRTL]: rtlActive
                            });
                          return (
                            <ListItem
                              key={key}
                              className={classes.collapseItem}
                            >
                              <NavLink
                                to={prop.path}
                                onClick={prop.onClick || (() => {})}
                                className={navLinkClasses}
                              >
                                <span className={collapseItemMini}>
                                  {prop.mini}
                                </span>
                                <ListItemText
                                  primary={prop.name}
                                  disableTypography={true}
                                  className={collapseItemText}
                                />
                              </NavLink>
                            </ListItem>
                          );
                        })}
                    </List>
                  </Collapse>
                </ListItem>
              </Slide>
            );
          }
          const navLinkClasses =
            classes.itemLink +
            " " +
            cx({
              [" " + classes[color]]: this.activeRoute(prop.path)
            });
          const itemText =
            classes.itemText +
            " " +
            cx({
              [classes.itemTextMini]:
                this.props.miniActive && this.state.miniActive,
              [classes.itemTextMiniRTL]:
                rtlActive && this.props.miniActive && this.state.miniActive,
              [classes.itemTextRTL]: rtlActive
            });
          const itemIcon =
            classes.itemIcon +
            " " +
            cx({
              [classes.itemIconRTL]: rtlActive
            });
          return (
            <ListItem key={key} className={classes.item}>
              <NavLink
                to={prop.path}
                onClick={prop.onClick || (() => {})}
                className={navLinkClasses}
              >
                {prop.mini && (
                  <span className={classes.itemIconMini}>{prop.mini}</span>
                )}
                {prop.icon && (
                  <ListItemIcon className={itemIcon}>
                    {typeof prop.icon === "string" ? (
                      <Icon>{prop.icon}</Icon>
                    ) : (
                      <prop.icon />
                    )}
                  </ListItemIcon>
                )}
                <ListItemText
                  primary={prop.name}
                  disableTypography={true}
                  className={itemText}
                />
              </NavLink>
            </ListItem>
          );
        })}
      </List>
    );

    const drawerPaper =
      classes.drawerPaper +
      " " +
      cx({
        [classes.drawerPaperMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.drawerPaperRTL]: rtlActive
      });
    const sidebarWrapper =
      classes.sidebarWrapper +
      " " +
      cx({
        [classes.drawerPaperMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.sidebarWrapperWithPerfectScrollbar]:
          navigator.platform.indexOf("Win") > -1
      });
    return (
      <div ref="mainPanel">
        <Hidden mdUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={rtlActive ? "left" : "right"}
            open={this.props.open}
            classes={{
              paper: drawerPaper + " " + classes[bgColor + "Background"]
            }}
            onClose={this.props.handleDrawerToggle}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
          >
            <SidebarWrapper
              className={sidebarWrapper}
              user={user}
              headerLinks={
                <HeaderLinks rtlActive={rtlActive} userName={userName} />
              }
              links={links}
            />
            {image !== undefined ? (
              <div
                className={classes.background}
                style={{ backgroundImage: "url(" + image + ")" }}
              />
            ) : null}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            onMouseOver={() => this.setState({ miniActive: false })}
            onMouseOut={() => this.setState({ miniActive: true })}
            anchor={rtlActive ? "right" : "left"}
            variant="permanent"
            open
            classes={{
              paper: drawerPaper + " " + classes[bgColor + "Background"]
            }}
          >
            <SidebarWrapper
              className={sidebarWrapper}
              user={user}
              links={links}
            />
            {image !== undefined ? (
              <div
                className={classes.background}
                style={{ backgroundImage: "url(" + image + ")" }}
              />
            ) : null}
          </Drawer>
        </Hidden>
      </div>
    );
  }
}

Sidebar.defaultProps = {
  bgColor: "blue",
  menuEntity: { name: "", id: "" },
  userName: ""
};

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  bgColor: PropTypes.oneOf(["white", "black", "blue"]),
  rtlActive: PropTypes.bool,
  color: PropTypes.oneOf([
    "white",
    "red",
    "orange",
    "green",
    "blue",
    "purple",
    "rose"
  ]),
  logo: PropTypes.string,
  logoText: PropTypes.string,
  image: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  partners: PropTypes.arrayOf(PropTypes.object),
  menuEntity: PropTypes.shape({ name: PropTypes.string, id: PropTypes.string }),
  setSelectedPartner: PropTypes.func.isRequired,
  createPartner: PropTypes.func.isRequired,
  isSystemAdmin: PropTypes.bool.isRequired,
  userName: PropTypes.string
};

export default withStyles(sidebarStyle)(Sidebar);
