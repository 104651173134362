import React from "react";
import compose from "recompose/compose";
import { connect } from "react-redux";
import Tooltip from "@material-ui/core/Tooltip";

/** legacy code, will remove once new form is done */
import { openModal } from "app/uiActions";
import { deleteGroup } from "groups/actions";
import { DeleteConfirmationModal } from "components/modals";
import { allowEmployerManager } from "services/permission/service";
import GroupActions from "components/layouts/list/Actions";
import withSizeSmall from "components/buttons/withSizeSmall";
import InfoButtonComponent from "components/buttons/icon/Info";
import EditButton from "components/buttons/action/Edit";
import DeleteButton from "components/buttons/action/Delete";
import { GroupEditModal } from "../modals";

const InfoButton = withSizeSmall(InfoButtonComponent);

const TooltipTiTle = () => (
  <span>
    Contributions are currently being processed.
    <br />
    Cannot update groups at this time.
  </span>
);

const ActionsCell = ({
  openModal,
  deleteGroup,
  employerId,
  employerPayEnabled,
  employerLocked,
  stateProps: { requestData },
  ...group
}) => {
  const locked = employerLocked || group["locked?"];
  const allowDelete = group.name !== "default";

  const handelEditClick = () => {
    openModal(
      <GroupEditModal
        group={group}
        employerID={employerId}
        employerPayEnabled={employerPayEnabled}
      />
    );
  };

  const handleDeleteClick = () => {
    const handleSuccess = () => requestData();

    openModal(
      <DeleteConfirmationModal
        title="Delete Group"
        deleteAction={() => deleteGroup(group.id, employerId, handleSuccess)}
        resourceName="group"
        name={group.name}
        message={
          'Deleting this group will move all assigned users to "Default" group.'
        }
      />
    );
  };

  return (
    <GroupActions>
      {allowEmployerManager() && (
        <>
          <EditButton disabled={locked} onClick={handelEditClick}>
            Edit
          </EditButton>
          {allowDelete && (
            <DeleteButton disabled={locked} onClick={handleDeleteClick}>
              Delete
            </DeleteButton>
          )}
          {locked && (
            <Tooltip title={<TooltipTiTle />}>
              <span>
                <InfoButton disabled />
              </span>
            </Tooltip>
          )}
        </>
      )}
    </GroupActions>
  );
};

const mapDispatchToProps = (_dispatch, _ownProps) => {
  return { openModal, deleteGroup };
};

export default compose(
  connect(
    null,
    mapDispatchToProps()
  )
)(ActionsCell);
