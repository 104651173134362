/* eslint-disable */
import { saveAs } from "file-saver";
import isEmpty from "lodash/isEmpty";

import {
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  DELETE,
  DOWNLOAD
} from "./types";
import { jsonApiHttpClient, queryParameters } from "./fetch";
import {
  formatEmployeesCreate,
  formatEmployersUpdate,
  formatEmployeesUpdate,
  formatEmployersCreate,
  formatPartnersGetOne,
  parseJSONResp
} from "../helpers/restClientHelpers";
import { setAuthDetails } from "../helpers/authHelpers";

const slgRestClient = (apiUrl, httpClient = jsonApiHttpClient) => {
  /**
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE' or 'GET_LIST'
   * @param {String} resource Name of the resource to fetch, e.g. 'employees'
   * @param {Object} params The REST request params, depending on the type
   * @returns {Object} { url, options } The HTTP request parameters
   */
  const convertRESTRequestToHTTP = (type, resource, params) => {
    let url = "";
    let parentResource =
      resource === "groups" ? `employers/${params.employerID}/` : "";
    const options = {};
    switch (type) {
      case GET_MANY_REFERENCE:
      case GET_LIST:
        const { page, limit } = params.pagination;
        const { field, order } = params.sort;
        var query = {};

        // TODO: Remove limit hack !!!!
        if (limit) {
          query["page[limit]"] = limit;
        } else {
          query["page[number]"] = page;
        }

        // filter should always default to an array
        params.filter.forEach((filterObj, i) => {
          Object.keys(filterObj).forEach(key => {
            let filterField = `filter[${i}][${key}]`;
            query[filterField] = filterObj[key];
          });
        });

        // TODO: Remove this???? looks like it's not used anywhere
        if (type === "GET_MANY_REFERENCE") {
          const targetFilter = `filter[${params.target}]`;
          query[targetFilter] = params.id;
        }

        if (order === "ASC") {
          query.sort = field;
        } else {
          query.sort = "-" + field;
        }

        /** returned fields */
        if (params.fields) {
          const fields = params.fields;
          Object.keys(fields).forEach(fieldName => {
            const fieldObj = fields[fieldName];
            Object.keys(fieldObj).forEach(key => {
              const fieldStr = `fields[${key}][${fieldName}]`;
              query[fieldStr] = fieldObj[key];
            });
          });
        }

        url = `${apiUrl}/${parentResource}${resource}?${queryParameters(
          query
        )}`;
        break;
      case GET_ONE:
        url = `${apiUrl}/${parentResource}${resource}/${params.id}`;
        break;

      // TODO: Remove this???? looks like it's not used anywhere
      case GET_MANY:
        var query = { "filter[id]": params.ids.toString() };
        url = `${apiUrl}/${resource}?${queryParameters(query)}`;
        break;
      case UPDATE:
        url = `${apiUrl}/${parentResource}${resource}/${params.id}`;
        options.method = "PATCH";

        if (resource === "employers") {
          params = formatEmployersUpdate(params);
        } else if (resource === "employees") {
          params = formatEmployeesUpdate(params);
        }

        if (
          _.indexOf(
            ["registrations", "passwords", "disable", "enable"],
            resource
          ) > -1
        ) {
          url = `${apiUrl}/${resource}`;
          options.method = "PUT";
        }

        var attrs = {};
        Object.keys(params.data).forEach(
          key => (attrs[key] = params.data[key])
        );

        const useType = resource === "employees" ? "customers" : resource;
        const updateParams = {
          data: { type: useType, id: params.id, attributes: attrs }
        };

        options.body = JSON.stringify(updateParams);
        break;
      case CREATE:
        url = `${apiUrl}/${parentResource}${resource}`;
        options.method = "POST";

        if (resource === "employers") {
          params = formatEmployersCreate(params);
        } else if (resource === "employees") {
          params = formatEmployeesCreate(params);
        }

        if (params) {
          const createParams = {
            data: { type: resource, attributes: params.data }
          };
          options.body = JSON.stringify(createParams);
        }
        break;
      case DELETE:
        url = `${apiUrl}/${parentResource}${resource}/${params.id}`;
        options.method = "DELETE";
        break;
      case DOWNLOAD:
        url = `${apiUrl}/${resource}/${params.id}`;

        if (resource === "partners") {
          url = `${apiUrl}/${resource}/${params.id}/download_company_report`;
        }
        if (resource === "employers") {
          url = `${apiUrl}/${resource}/${params.id}/download_employer_report`;
        }
        break;
      default:
        throw new Error(`Unsupported fetch action type ${type}`);
    }
    return { url, options };
  };

  /**
   * @param {Object} response HTTP response from fetch()
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The REST request params, depending on the type
   * @returns {Object} REST response
   */
  const convertHTTPResponseToREST = (response, type, resource, params) => {
    const { headers, json } = response;
    if (
      response.headers.get("Content-Type" === "application/vnd.ms-excel") ||
      isEmpty(response.body)
    ) {
      return;
    }

    switch (type) {
      case GET_MANY_REFERENCE:
      case GET_LIST:
        // This should probably be in a helper method
        var jsonData = json.data.map(function(dic) {
          var interDic = Object.assign(
            { id: dic.id },
            dic.attributes,
            dic.meta
          );
          if (dic.relationships) {
            Object.keys(dic.relationships).forEach(function(key) {
              var keyString = key + "_id";
              if (dic.relationships[key].data) {
                //if relationships have a data field --> assume id in data field
                const dicData = dic.relationships[key].data;
                if (Array.isArray(dicData)) {
                  /** for now just deal with 1st array elem */
                  interDic[keyString] = dicData[0] && dicData[0].id;
                } else {
                  interDic[keyString] = dicData.id;
                }
              } else if (dic.relationships[key].links) {
                //if relationships have a link field
                var link = dic.relationships[key].links["self"];
                httpClient(link).then(function(response) {
                  interDic[key] = {
                    data: response.json.data,
                    count: response.json.data.length
                  };
                  interDic["count"] = response.json.data.length;
                });
              }
            });
          }

          return interDic;
        });
        if (resource === "employees") {
          jsonData = parseJSONResp(json);
        }
        if (resource === "employers") {
          jsonData = parseJSONResp(json);
        }
        if (resource === "notes") {
          jsonData = parseJSONResp(json);
        }
        if (type === "GET_MANY_REFERENCE") {
          // Hackey way to get total to come to our hackey custom ReferenceManyFieldPagination component
          // Write a reducer that takes total as a separate piece of data
          let data = jsonData.map(item => {
            return Object.assign({}, item, { total: json.meta["total"] });
          });

          return { data: data };
        }

        if (resource === "admin_users") {
          jsonData = parseJSONResp(json);
        }
        if (resource === "partners") {
          jsonData = parseJSONResp(json);
        }
        if (resource === "groups") {
          jsonData = parseJSONResp(json);
        }
        return { data: jsonData, total: json.meta["total"] };
      case GET_MANY:
        jsonData = json.data.map(function(obj) {
          return Object.assign({ id: obj.id }, obj.attributes);
        });
        return { data: jsonData };
      case UPDATE:
        if (resource === "registrations" || resource === "passwords") {
          const authToken = response.headers.get("Authorization");
          setAuthDetails(authToken);
          return { data: {} };
        }
        if (resource === "disable" || resource === "enable") {
          const authToken = response.headers.get("Authorization");
          setAuthDetails(authToken);

          return { data: {} };
        }
        if (resource === "partners") {
          return json;
        }
        if (resource === "admin_users") {
          let newAdminUser = parseJSONResp(json);
          return { data: newAdminUser };
        }
        if (resource === "notes") {
          return { data: parseJSONResp(json) };
        }
        if (resource === "groups") {
          return { data: parseJSONResp(json) };
        }
        if (resource === "employees") {
          return { data: parseJSONResp(json) };
        }

      case CREATE:
        if (resource === "passwords") {
          // Password response doesn't return anything
          return { data: {} };
        }
        if (resource === "verify") {
          const authToken = response.headers.get("Authorization");
          setAuthDetails(authToken);

          return { data: JSON.parse(response.body) };
        }
        if (resource === "employers") {
          var returnData = Object.assign(
            {},
            { id: json.data.id },
            json.data.attributes,
            json.meta
          );

          returnData = parseJSONResp(json);
          return { data: returnData };
        }

        if (resource === "admin_users") {
          let newAdminUser = parseJSONResp(json);
          return { data: newAdminUser };
        }

        if (resource === "notes") {
          return { data: parseJSONResp(json) };
        }

        if (resource === "groups") {
          return { data: parseJSONResp(json) };
        }

        return {
          data: Object.assign({ id: json.data.id }, json.data.attributes)
        };
      case DELETE:
        if (resource === "admin_users") {
          return { data: {} };
        }
        return { data: json };

      case GET_ONE:
        // Basic return data response
        var returnData = Object.assign(
          {},
          { id: json.data.id },
          json.data.attributes,
          json.meta
        );

        if (resource === "employers" || resource === "employees") {
          returnData = parseJSONResp(json);
        } else if (resource === "partners") {
          returnData = formatPartnersGetOne(returnData, json.included);
        } else if (resource === "groups") {
          returnData = parseJSONResp(json);
        }

        return {
          data: returnData
        };
      case DOWNLOAD:
        // Extract a file name from the Content-Disposition header.
        const rawFileName = response.headers.get("Content-Disposition");
        const fileName = rawFileName.match(/filename="(.+)"/)[1];

        saveAs(response.body, fileName);
        return { data: {} };
      default:
        return { data: json.data };
    }
  };

  /**
   * @param {string} type Request type, e.g GET_LIST
   * @param {string} resource Resource name, e.g. "posts"
   * @param {Object} payload Request parameters. Depends on the request type
   * @returns {Promise} the Promise for a REST response
   */
  return (type, resource, params) => {
    const { url, options } = convertRESTRequestToHTTP(type, resource, params);
    return httpClient(url, options).then(response =>
      convertHTTPResponseToREST(response, type, resource, params)
    );
  };
};

export default slgRestClient(process.env.REACT_APP_API_HOST);
