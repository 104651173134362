import * as React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import compose from "recompose/compose";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { selectEntityIdentifiers } from "partners/selectors";
import { selectBatchCreateEligibleEmployeesFetching } from "employers/selectors-ext/bulkUploadSelectors";
import WrapWithLoading from "components/loading/WrapWithLoading";
import Dialog from "components/modals/ConfirmationDialog";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import DataGrid, {
  Column,
  Editing,
  Paging,
  Pager,
  SearchPanel,
  Lookup,
  RequiredRule,
  PatternRule
} from "devextreme-react/data-grid";
import Papa from "papaparse";

const styles = {
  fileContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginTop: 20
  },
  fileTextContainer: {
    marginRight: 20
  },
  buttonContainer: {},
  button: {},
  input: {
    display: "none"
  }
};

const defaultFileRecord = {
  personal_id: "",
  employee_status: "active",
  employment_started_on: "",
  first_name: "",
  last_name: "",
  email: "",
  group: "default",
  invite_now: "N"
};

class EligibilityFileUpload extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    onUploadClick: PropTypes.func.isRequired,
    loading: PropTypes.bool
  };

  state = {
    columns: [
      { name: "personal_id", title: "Personal ID" },
      { name: "employee_status", title: "Status" },
      { name: "employment_started_on", title: "Start Date" },
      { name: "first_name", title: "First Name" },
      { name: "last_name", title: "Last Name" },
      { name: "email", title: "Email" },
      { name: "group", title: "Group" },
      { name: "invite_now", title: "Invite Now?" }
    ],
    file: null,
    employeesList: []
  };

  handleUploadClick = () => {
    const { onUploadClick } = this.props;
    const { file, employeesList } = this.state;
    employeesList.map(employee => delete employee.id);
    if (onUploadClick) onUploadClick(file, employeesList);
    this.setState({ employeesList: [], file: null });
  };

  handleFileChange = e => this.parseCsvFile(e.target.files.item(0));

  parseCsvFile = file => {
    this.setState({ file });

    const transformHeader = header => {
      return header.toLowerCase().replace(/\W/g, "_");
    };
    const mapHeaderName = header => {
      switch (header) {
        case "id":
        case "personal_id":
          return "personal_id";
        case "vested_started_on":
        case "employment_started_on":
          return "employment_started_on";
        case "group":
        case "new_group":
          return "group";
        default:
          return header;
      }
    };
    const mapFileDataValue = (attributeName, value) => {
      switch (attributeName) {
        case "invite_now":
          return value.toUpperCase();
        case "group":
        case "email":
        case "employee_status":
          return value.toLowerCase();
        default:
          return value;
      }
    };

    Papa.parse(file, {
      skipEmptyLines: true,
      transformHeader: transformHeader,
      complete: results => {
        let fileHeaders = results.data.shift();
        let fileEmployeesData = [];
        let mappedEmployeesData = [];
        let tempEmployee = {};

        for (let y = 0; y < results.data.length; y++) {
          tempEmployee = {};
          for (let i = 0; i < fileHeaders.length; i++) {
            tempEmployee["id"] = `${y}${i}`;
            let attributeName = mapHeaderName(transformHeader(fileHeaders[i]));
            tempEmployee[attributeName] = mapFileDataValue(
              attributeName,
              results.data[y][i]
            );
          }
          fileEmployeesData.push(tempEmployee);
        }
        fileEmployeesData.forEach(fileEmployee => {
          this.state.columns.forEach(column => {
            fileEmployee[column.name] =
              fileEmployee[column.name] || defaultFileRecord[column.name];
          });

          mappedEmployeesData.push(fileEmployee);
        });
        this.setState({ employeesList: mappedEmployeesData });
      }
    });
  };

  showFileData() {
    const { loading } = this.props;

    return (
      <WrapWithLoading loading={loading}>
        <DataGrid
          id="gridContainer"
          dataSource={this.state.employeesList}
          keyExpr="id"
          allowColumnReordering={true}
          showBorders={true}
        >
          <SearchPanel visible={true} />
          <Paging enabled={true} defaultPageSize={10} />
          <Pager
            showPageSizeSelector={true}
            allowedPageSizes={[10, 20, 50]}
            showInfo={true}
          />
          <Editing
            mode="cell"
            allowUpdating={true}
            allowDeleting={true}
            allowAdding={true}
          />
          <Column dataField="personal_id" caption="Personal ID" />
          <Column dataField="employee_status" caption="Status">
            <Lookup dataSource={["active", "inactive"]} />
          </Column>
          <Column
            dataField="employment_started_on"
            caption="Start Date"
            dataType="date"
          />
          <Column dataField="first_name" caption="First Name">
            <RequiredRule />
          </Column>
          <Column dataField="last_name" caption="Last Name">
            <RequiredRule />
          </Column>
          <Column dataField="invite_now" caption="Invite Now">
            <Lookup dataSource={["Y", "N"]} />
          </Column>
          <Column dataField="email" width={130}>
            <RequiredRule />
            <PatternRule
              pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,5}$"
              message="Email is invalid"
            />
          </Column>
          <Column dataField="group" caption="Group" />
        </DataGrid>
      </WrapWithLoading>
    );
  }

  renderUploadRules() {
    const { classes, loading, entityIdentifiers } = this.props;
    const { file } = this.state;

    return (
      <WrapWithLoading loading={loading}>
        <div>
          <Typography variant="subtitle1" gutterBottom>
            {`File Requirements: (download ${entityIdentifiers.employeeIdentifier.toLowerCase()} list to see example)`}
          </Typography>
        </div>
        <div>
          <Typography variant="body2">- Must be CSV file type</Typography>
        </div>
        <div>
          <Typography variant="body2">
            {`- Personal_ID: Unique Identifier for ${entityIdentifiers.employeeIdentifier} (cannot be changed)`}
          </Typography>
        </div>
        <div>
          <Typography variant="body2">
            {`- Email: Email address for ${entityIdentifiers.employeeIdentifier.toLowerCase()}`}
          </Typography>
        </div>
        <div>
          <Typography variant="body2">
            {`- First_name: First name of ${entityIdentifiers.employeeIdentifier.toLowerCase()}`}
          </Typography>
        </div>
        <div>
          <Typography variant="body2">
            {`- Last_name: Last name of ${entityIdentifiers.employeeIdentifier.toLowerCase()}`}
          </Typography>
        </div>
        <div>
          <Typography variant="body2">
            {`- ${entityIdentifiers.employeeIdentifier}_status: "Active" or "Inactive"`}
          </Typography>
        </div>
        <div>
          <Typography variant="body2">
            - Group: Must match name exactly or user will be added to default
            group
          </Typography>
        </div>
        <div>
          <Typography variant="body2">
            {`- employment_started_on: ${entityIdentifiers.employeeIdentifier} start date that is used as a start
            date for Vested Pay -- in the format of YYYY-MM-DD, ex: 2017-10-21`}
          </Typography>
        </div>
        <div style={{ marginTop: 10 }}></div>
        <div className={classes.fileContainer}>
          <div className={classes.fileTextContainer}>
            {file && `File selected: ${file.name}`}
          </div>
          <div className={classes.buttonContainer}>
            <input
              accept=".csv"
              className={classes.input}
              id="contained-button-file"
              type="file"
              onChange={this.handleFileChange}
            />
            <label htmlFor="contained-button-file">
              <Button
                variant="contained"
                component="span"
                color="primary"
                className={classes.button}
                disabled={loading}
              >
                <Typography style={{ color: "white" }} noWrap>
                  Select File
                </Typography>
              </Button>
            </label>
          </div>
        </div>
      </WrapWithLoading>
    );
  }

  render() {
    const { entityIdentifiers } = this.props;
    const disableUpload = this.state.employeesList.length === 0;

    return (
      <Dialog
        title="Users File Upload"
        subTitle={`Create and update ${entityIdentifiers.employeeIdentifier.toLowerCase()}s using personal ID as unique id`}
        disabled={disableUpload}
        maxWidth={"md"}
        submitButtonProps={{
          label: "Send",
          onClick: this.handleUploadClick,
          closeOnSubmit: false
        }}
        content={
          this.state.employeesList.length === 0
            ? this.renderUploadRules()
            : this.showFileData()
        }
      />
    );
  }
}

const mapStateToProps = (state, _ownProps) => {
  const loading = selectBatchCreateEligibleEmployeesFetching(state);
  const entityIdentifiers = selectEntityIdentifiers(state);

  return { loading, entityIdentifiers };
};
const mapDispatchToProps = (_dispatch, _ownProps) => {
  return {};
};
const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
);

export default withStyles(styles)(enhance(EligibilityFileUpload));
