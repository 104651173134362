import { call, put, all, takeLatest } from "redux-saga/effects";
import { destroy } from "redux-form";
import get from "lodash/get";
import moment from "moment";
import { queryParameters } from "rest/fetch";
import fetchService from "rest/fetchApiService";
import slgRestClient from "rest/slgRestClient";
import { GET_LIST, CREATE, UPDATE, DELETE, GET_ONE } from "rest/types";
import { jsonApiHttpClient } from "rest/fetch";
import log from "services/logging/service";
import {
  FETCH_EMPLOYEES_SUCCESS,
  FETCH_EMPLOYEES_FAILURE,
  FETCH_EMPLOYEES,
  FETCH_EMPLOYEE_SUCCESS,
  FETCH_EMPLOYEE_FAILURE,
  FETCH_EMPLOYEE,
  CREATE_EMPLOYEE,
  CREATE_EMPLOYEE_FAILURE,
  CREATE_EMPLOYEE_SUCCESS,
  EDIT_EMPLOYEE,
  EDIT_EMPLOYEE_SUCCESS,
  EDIT_EMPLOYEE_FAILURE,
  IMPERSONATE_USER,
  IMPERSONATE_USER_SUCCESS,
  IMPERSONATE_USER_FAILURE,
  FETCH_EMPLOYEE_NOTES,
  FETCH_EMPLOYEE_NOTES_SUCCESS,
  FETCH_EMPLOYEE_NOTES_FAILURE,
  CREATE_EMPLOYEE_NOTE,
  CREATE_EMPLOYEE_NOTE_SUCCESS,
  CREATE_EMPLOYEE_NOTE_FAILURE,
  EDIT_EMPLOYEE_NOTE,
  EDIT_EMPLOYEE_NOTE_SUCCESS,
  EDIT_EMPLOYEE_NOTE_FAILURE,
  DELETE_EMPLOYEE_NOTE,
  DELETE_EMPLOYEE_NOTE_SUCCESS,
  DELETE_EMPLOYEE_NOTE_FAILURE,
  SEND_EMPLOYEE_INVITE,
  SEND_EMPLOYEE_INVITE_SUCCESS,
  SEND_EMPLOYEE_INVITE_FAILURE,
  DOWNLOAD_EMPLOYEES_TO_CSV,
  DOWNLOAD_EMPLOYEES_TO_CSV_SUCCESS,
  DOWNLOAD_EMPLOYEES_TO_CSV_FAILURE
} from "../actions/types";
import { SORT_DESC, PER_PAGE } from "constants/index";
import { showNotification, closeAllModals } from "app/uiActions";
import { FETCH_ERROR } from "auth/authActions";

function _submissionErrorNotification(errorMessage) {
  return showNotification(
    errorMessage + ", please update and resubmit.",
    "warning"
  );
}

// API Call (Might make sense to separate at a later date)
function requestEmployees(
  employerID,
  sort,
  page = 1,
  limit,
  query,
  type = "employer",
  fields,
  filter
) {
  sort = sort || { field: "id", order: SORT_DESC };

  let useFilter = filter ? [filter] : [];
  if (type === "partner") {
    useFilter.push({ partner_id_eq: employerID });
  } else {
    useFilter.push({ employer_id_eq: employerID });
  }
  const params = {
    // ransack grouping syntax
    filter: useFilter,
    pagination: { page, perPage: PER_PAGE, limit }, // Should match api
    sort,
    fields
  };

  if (query) {
    // Creating a ransack search predicate
    const queryObject = [
      "full_name_cont",
      "notes_note_cont",
      "user_email_cont"
    ].reduce(
      (acc, searchKey) => {
        acc[searchKey] = query;
        return acc;
      },
      { m: "or" }
    );

    params.filter.push(queryObject);
  }

  return slgRestClient(GET_LIST, "employees", params)
    .then(resp => {
      return { data: resp.data, total: resp.total };
    })
    .catch(e => log.error(e.message));
}

function* fetchEmployees(action) {
  try {
    const employerID = action.payload.employerID;
    const employees = yield call(
      requestEmployees,
      employerID,
      action.payload.sort,
      action.payload.page,
      action.payload.limit,
      action.payload.query,
      action.payload.type,
      action.payload.fields,
      action.payload.filter
    );
    yield put({
      type: FETCH_EMPLOYEES_SUCCESS,
      payload: { employees, employerID }
    });
  } catch (e) {
    yield put({ type: FETCH_EMPLOYEES_FAILURE, message: e.message });
    yield put({ type: FETCH_ERROR, e });
    yield put(
      showNotification("An error occurred while fetching employees", "warning")
    );
    log.error(e.message);
  }
}

function requestEmployee(employeeID) {
  const params = { id: employeeID };

  return slgRestClient(GET_ONE, "employees", params)
    .then(resp => resp.data)
    .catch(e => log.error(e.message));
}

function* fetchEmployee(action) {
  try {
    const employee = yield call(requestEmployee, action.payload.employeeID);
    yield put({ type: FETCH_EMPLOYEE_SUCCESS, payload: employee });
  } catch (e) {
    yield put({ type: FETCH_EMPLOYEE_FAILURE, message: e.message });
    yield put({ type: FETCH_ERROR, e });
    yield put(
      showNotification("An error occurred while fetching employee", "warning")
    );
    log.error(e.message);
  }
}

// API Call to fetch the impersonation url for employee
function fetchImpersonationUrl(employeeID) {
  return jsonApiHttpClient(
    `${process.env.REACT_APP_API_HOST}/employees/${employeeID}/impersonation_url`
  )
    .then(resp => get(JSON.parse(resp.body), "data.url"))
    .catch(e => log.error(e.message));
}

function openNewWindowFor(url) {
  return window.open(url, "_blank");
}

function* impersonateUser(action) {
  try {
    const url = yield call(fetchImpersonationUrl, action.payload.employeeID);
    yield put({ type: IMPERSONATE_USER_SUCCESS });
    openNewWindowFor(url);
  } catch (e) {
    yield put({ type: IMPERSONATE_USER_FAILURE, message: e.message });
    yield put({ type: FETCH_ERROR, e });
    yield put(
      showNotification(
        "An error occurred while fetching the impersonation url",
        "warning"
      )
    );
    log.error(e.message);
  }
}

function requestEmployeeCreate(data) {
  return slgRestClient(CREATE, "employees", { data }).then(resp => resp.data);
}

function* createEmployee(action) {
  try {
    const employeeCreate = yield call(
      requestEmployeeCreate,
      action.payload.data
    );

    yield put({
      type: CREATE_EMPLOYEE_SUCCESS,
      payload: employeeCreate
    });

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
    yield put(destroy(action.formName));
    yield put(closeAllModals());
    yield put(showNotification("Employee created successfully"));
  } catch (e) {
    yield put({ type: CREATE_EMPLOYEE_FAILURE, message: e.message });
    yield put(_submissionErrorNotification(e.message));
    log.error(e.message);
  }
}

function requestEmployeeEdit(data, employeeID) {
  return slgRestClient(UPDATE, "employees", {
    id: employeeID,
    ...{ data }
  })
    .then(resp => resp.data)
    .catch(e => log.error(e.message));
}

function* editEmployee(action) {
  try {
    const employeeEdit = yield call(
      requestEmployeeEdit,
      action.payload.data,
      action.employeeID
    );

    /** TODO: removed until backend provide personal_ids after update */
    const payload = Object.assign({}, employeeEdit, {
      personal_ids: action.payload.data.personal_ids_attributes
    });

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }

    yield put({ type: EDIT_EMPLOYEE_SUCCESS, payload });
    yield put(destroy(action.formName));
    yield put(closeAllModals());
    yield put(showNotification("Employee edited successfully"));
  } catch (e) {
    yield put({ type: EDIT_EMPLOYEE_FAILURE, message: e.message });
    yield put(_submissionErrorNotification(e.message));
    log.error(e.message);
  }
}

function requestEmployeeNotes(employeeID, sort, page = 1) {
  sort = sort || { field: "id", order: SORT_DESC };

  const params = {
    filter: [
      {
        customer_id_eq: employeeID
      }
    ],
    pagination: { page, perPage: PER_PAGE }, // Should match api
    sort
  };
  return slgRestClient(GET_LIST, "notes", params)
    .then(resp => {
      return { data: resp.data, total: resp.total };
    })
    .catch(e => log.error(e.message));
}

function* fetchEmployeeNotes(action) {
  try {
    const employeeNotes = yield call(
      requestEmployeeNotes,
      action.payload.employeeID,
      action.payload.sort,
      action.payload.page
    );
    yield put({
      type: FETCH_EMPLOYEE_NOTES_SUCCESS,
      payload: employeeNotes
    });
  } catch (e) {
    yield put({
      type: FETCH_EMPLOYEE_NOTES_FAILURE,
      message: e.message
    });
    yield put({ type: FETCH_ERROR, e });
    yield put(
      showNotification("An error occurred while fetching notes", "warning")
    );
    log.error(e.message);
  }
}

function requestEmployeeNotesCreate(data, employeeID) {
  const notableAttributes = {
    notable_id: employeeID,
    notable_type: "Customer"
  };
  return slgRestClient(CREATE, "notes", {
    data: { ...data, ...notableAttributes }
  })
    .then(resp => resp.data)
    .catch(e => log.error(e.message));
}

function* createEmployeeNote(action) {
  try {
    const employeeNoteCreate = yield call(
      requestEmployeeNotesCreate,
      action.payload.data,
      action.employeeID
    );
    yield put({
      type: CREATE_EMPLOYEE_NOTE_SUCCESS,
      payload: employeeNoteCreate
    });
    yield put(destroy(action.formName));
    yield put(closeAllModals());
    yield put(showNotification("Note created successfully"));
    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield put({
      type: CREATE_EMPLOYEE_NOTE_FAILURE,
      message: e.message
    });
    yield put(_submissionErrorNotification(e.message));
    log.error(e.message);
  }
}

const requestSendEmployeeInvite = async employeeId =>
  await slgRestClient(CREATE, `employees/${employeeId}/resend_invite`);

function* sendEmployeeInvite(action) {
  try {
    const invitationCreate = yield call(
      requestSendEmployeeInvite,
      action.payload.employee.id
    );
    yield put({
      type: SEND_EMPLOYEE_INVITE_SUCCESS,
      payload: invitationCreate
    });
    yield put(
      showNotification(
        `${action.payload.employee.full_name} has been successfully invited.`
      )
    );
  } catch (e) {
    yield put({
      type: SEND_EMPLOYEE_INVITE_FAILURE,
      message: e.message
    });
    yield put(_submissionErrorNotification(e.message));
    log.error(e.message);
  }
}

function requestEmployeeNotesEdit(data, noteID) {
  const payload = { id: noteID, data };
  return slgRestClient(UPDATE, "notes", payload).then(resp => resp.data);
}

function* editEmployeeNote(action) {
  try {
    const employeeNoteEdit = yield call(
      requestEmployeeNotesEdit,
      action.payload.data,
      action.noteID
    );
    yield put({
      type: EDIT_EMPLOYEE_NOTE_SUCCESS,
      payload: employeeNoteEdit
    });
    yield put(destroy(action.formName));
    yield put(closeAllModals());
    yield put(showNotification("Note edited successfully"));
    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield put({
      type: EDIT_EMPLOYEE_NOTE_FAILURE,
      message: e.message
    });
    yield put(_submissionErrorNotification(e.message));
    log.error(e.message);
  }
}

function requestEmployeeNoteDelete(id) {
  return slgRestClient(DELETE, "notes", { id });
}

function* deleteEmployeeNote(action) {
  try {
    yield call(requestEmployeeNoteDelete, action.payload.noteID);
    yield put({
      type: DELETE_EMPLOYEE_NOTE_SUCCESS,
      payload: action.payload.noteID
    });
    yield put(closeAllModals());
    yield put(showNotification("Note deleted successfully"));
    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield put({
      type: DELETE_EMPLOYEE_NOTE_FAILURE,
      message: e.message
    });
    yield put(_submissionErrorNotification(e.message));
    log.error(e.message);
  }
}

async function requestDownloadEmployeesToCsv(employerID, type = "employer") {
  let filter = [];
  if (type === "partner") {
    filter.push({ partner_id_eq: employerID });
  } else {
    filter.push({ employer_id_eq: employerID });
  }

  let query = {};
  filter.forEach((filterObj, i) => {
    Object.keys(filterObj).forEach(key => {
      let filterField = `filter[${i}][${key}]`;
      query[filterField] = filterObj[key];
    });
  });

  const url = `${
    process.env.REACT_APP_API_HOST
  }/employees.csv?${queryParameters(query)}`;

  return await fetchService.fetchFileAttachment(
    url,
    `Employees_${moment().format()}.csv`
  );
}

function* downloadEmployeesToCsv(action) {
  try {
    yield call(
      requestDownloadEmployeesToCsv,
      action.payload.employerID,
      action.payload.type
    );
    yield put({ type: DOWNLOAD_EMPLOYEES_TO_CSV_SUCCESS });
    yield put(showNotification("Employees downloaded successfully"));
  } catch (e) {
    yield put({ type: DOWNLOAD_EMPLOYEES_TO_CSV_FAILURE, message: e.message });
    yield put(
      showNotification(
        "An error occurred while downloading employees to csv",
        "warning"
      )
    );
    log.error(e.message);
  }
}

export default function* employeeSaga() {
  yield all([
    takeLatest(FETCH_EMPLOYEES, fetchEmployees),
    takeLatest(FETCH_EMPLOYEE, fetchEmployee),
    takeLatest(IMPERSONATE_USER, impersonateUser),
    takeLatest(CREATE_EMPLOYEE, createEmployee),
    takeLatest(EDIT_EMPLOYEE, editEmployee),
    takeLatest(FETCH_EMPLOYEE_NOTES, fetchEmployeeNotes),
    takeLatest(CREATE_EMPLOYEE_NOTE, createEmployeeNote),
    takeLatest(EDIT_EMPLOYEE_NOTE, editEmployeeNote),
    takeLatest(DELETE_EMPLOYEE_NOTE, deleteEmployeeNote),
    takeLatest(SEND_EMPLOYEE_INVITE, sendEmployeeInvite),
    takeLatest(DOWNLOAD_EMPLOYEES_TO_CSV, downloadEmployeesToCsv)
  ]);
}
