import { call, put, all, takeLatest } from "redux-saga/effects";
import { destroy } from "redux-form";
import { map, concat, omit } from "lodash";
import log from "../../services/logging/service";
import {
  FETCH_GROUPS,
  FETCH_GROUPS_SUCCESS,
  FETCH_GROUPS_FAILURE,
  FETCH_GROUP,
  FETCH_GROUP_SUCCESS,
  FETCH_GROUP_FAILURE,
  CREATE_GROUP,
  CREATE_GROUP_SUCCESS,
  CREATE_GROUP_FAILURE,
  EDIT_GROUP,
  EDIT_GROUP_SUCCESS,
  EDIT_GROUP_FAILURE,
  DELETE_GROUP,
  DELETE_GROUP_SUCCESS,
  DELETE_GROUP_FAILURE
} from "../actions/types";
import { FETCH_ERROR } from "auth";
import { GET_LIST, GET_ONE, CREATE, UPDATE, DELETE } from "rest/types";
import { SORT_ASC, PER_PAGE } from "constants/index";
import { showNotification, closeAllModals } from "app/uiActions";
import slgRestClient from "rest/slgRestClient";

function _submissionErrorNotification(errorMessage) {
  return showNotification(
    errorMessage + ", please update and resubmit.",
    "warning"
  );
}

// API Call (Might make sense to separate at a later date)
function requestGroups(employerID, sort, page = 1, limit) {
  sort = sort || { field: "created_at", order: SORT_ASC };

  const params = {
    // ransack search predicate syntax
    filter: [{ employer_id_eq: employerID }],
    employerID,
    pagination: { page, perPage: PER_PAGE, limit }, // Should match api
    sort
  };

  return slgRestClient(GET_LIST, "groups", params)
    .then(resp => {
      return { data: resp.data, total: resp.total };
    })
    .catch(e => log.error(e.message));
}

function* fetchGroups(action) {
  try {
    const employerID = action.payload.employerID;
    const groups = yield call(
      requestGroups,
      employerID,
      action.payload.sort,
      action.payload.page,
      action.payload.limit
    );
    yield put({
      type: FETCH_GROUPS_SUCCESS,
      payload: { groups, employerID }
    });
  } catch (e) {
    yield put({ type: FETCH_GROUPS_FAILURE, message: e.message });
    yield put({ type: FETCH_ERROR, e });
    yield put(
      showNotification("An error occurred while fetching groups", "warning")
    );
    log.error(e.message);
  }
}

function requestGroup(groupID, employerID) {
  return slgRestClient(GET_ONE, "groups", {
    id: groupID,
    employerID
  })
    .then(resp => resp.data)
    .catch(e => log.error(e.message));
}

function* fetchGroup(action) {
  try {
    const group = yield call(
      requestGroup,
      action.payload.groupID,
      action.employerID
    );
    yield put({ type: FETCH_GROUP_SUCCESS, payload: group });
  } catch (e) {
    yield put({ type: FETCH_GROUP_FAILURE, message: e.message });
    yield put({ type: FETCH_ERROR, e });
    yield put(
      showNotification("An error occurred while fetching group", "warning")
    );
    log.error(e.message);
  }
}

function requestGroupCreate(data, employerID, employeeIDs) {
  const employeesList = map(employeeIDs, employeeID => {
    return { id: employeeID };
  });

  const params = {
    ...omit(data, "pay_enabled?"),
    employer_id: employerID,
    employees_attributes: employeesList
  };

  if (data["pay_enabled?"]) {
    params.services_attributes = [
      {
        ...omit(data.services_attributes[0], "match_amount"),
        match_amount_cents: data.services_attributes[0].match_amount * 100,
        type: "EmployerService::FlatMatch"
      }
    ];
  }

  return slgRestClient(CREATE, "groups", {
    ...{ data: params },
    employerID
  })
    .then(resp => resp.data)
    .catch(e => log.error(e.message));
}

function* createGroup(action) {
  try {
    const groupCreate = yield call(
      requestGroupCreate,
      action.payload.data,
      action.employerID,
      action.employeeIDs
    );
    yield put({ type: CREATE_GROUP_SUCCESS, payload: groupCreate });
    yield put(destroy(action.formName));
    yield call(action.onSuccess);
    yield put(closeAllModals());
    yield put(showNotification("Group created successfully"));
  } catch (e) {
    yield put({ type: CREATE_GROUP_FAILURE, message: e.message });
    yield put(_submissionErrorNotification(e.message));
    log.error(e.message);
  }
}

function requestGroupEdit(
  data,
  groupID,
  employerID,
  addedEmployeeIDs,
  removedEmployeeIDs
) {
  const employeesList = concat(
    map(addedEmployeeIDs, employeeID => {
      return { id: employeeID };
    }),
    map(removedEmployeeIDs, employeeID => {
      return { id: employeeID, _destroy: true };
    })
  );

  const params = {
    ...omit(data, "pay_enabled?", "services_attributes"),
    employees_attributes: employeesList
  };

  if (data["pay_enabled?"]) {
    params.services_attributes = [
      {
        ...omit(data.services_attributes[0], "match_amount"),
        match_amount_cents:
          (data.services_attributes[0].match_amount || 0) * 100,
        lifetime_cap_cents:
          (data.services_attributes[0].lifetime_cap || 0) * 100,
        type: "EmployerService::FlatMatch"
      }
    ];
  } else if (data.services_attributes[0]) {
    params.services_attributes = [
      {
        type: "EmployerService::FlatMatch",
        id: data.services_attributes[0].id,
        _destroy: true
      }
    ];
  }

  return slgRestClient(UPDATE, "groups", {
    id: groupID,
    employerID,
    ...{ data: params }
  })
    .then(resp => resp.data)
    .catch(e => log.error(e.message));
}

function* editGroup(action) {
  try {
    const groupEdit = yield call(
      requestGroupEdit,
      action.payload.data,
      action.groupID,
      action.employerID,
      action.addedEmployeeIDs,
      action.removedEmployeeIDs
    );
    yield put({ type: EDIT_GROUP_SUCCESS, payload: groupEdit });
    yield put(destroy(action.formName));
    yield put(closeAllModals());
    yield put(showNotification("Group edited successfully"));
  } catch (e) {
    yield put({ type: EDIT_GROUP_FAILURE, message: e.message });
    yield put(_submissionErrorNotification(e.message));
    log.error(e.message);
  }
}

function requestGroupDelete(groupID, employerID) {
  const params = { id: groupID, employerID };

  return slgRestClient(DELETE, "groups", params)
    .then(resp => resp)
    .catch(e => log.error(e.message));
}

function* deleteGroup(action) {
  try {
    yield call(
      requestGroupDelete,
      action.payload.groupID,
      action.payload.employerID
    );
    yield put({
      type: DELETE_GROUP_SUCCESS,
      payload: action.payload.groupID
    });
    yield call(action.payload.onSuccess);
    yield put(closeAllModals());
    yield put(showNotification("Group deleted successfully"));
  } catch (e) {
    yield put({ type: DELETE_GROUP_FAILURE, message: e.message });
    yield put(_submissionErrorNotification(e.message));
    log.error(e.message);
  }
}

export default function* groupSaga() {
  yield all([
    takeLatest(FETCH_GROUPS, fetchGroups),
    takeLatest(FETCH_GROUP, fetchGroup),
    takeLatest(CREATE_GROUP, createGroup),
    takeLatest(EDIT_GROUP, editGroup),
    takeLatest(DELETE_GROUP, deleteGroup)
  ]);
}
