import contacts from "./contactsReducers";
import reportsReducer from "./reportsReducers";
import ultipro from "./ultiproReducers";
import bulkUpload from "./bulkUploadReducers";

export default {
  employerReports: reportsReducer,
  ultipro,
  bulkUpload,
  employerContacts: contacts
};
