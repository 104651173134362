import React, { Component } from "react";
import PropTypes from "prop-types";
import { pick, map } from "lodash";
import SLGModalForm from "components/modals/SLGModalForm";
import { GroupForm } from "../forms";

export class GroupEditModal extends Component {
  static propTypes = {
    group: PropTypes.object.isRequired,
    employerID: PropTypes.string.isRequired,
    employerPayEnabled: PropTypes.bool
  };

  render() {
    const { onClose, group, employerID, employerPayEnabled } = this.props;

    const initialFields = pick(group, [
      "name",
      "pay_enabled?",
      "services_attributes",
      "automatic_invite"
    ]);

    // `match_amount` is only for GroupForm.
    //
    // TODO: This transformation between dollars/cents shouldn't live in a component, and not in the render
    // function. A serializer/deserializer service needs to be injected at the API abstraction layer to handle
    // these type of transformations.
    if (initialFields.services_attributes[0]) {
      initialFields.services_attributes[0].match_amount =
        initialFields.services_attributes[0].match_amount_cents / 100;
      if (initialFields.services_attributes[0].lifetime_cap_cents) {
        initialFields.services_attributes[0].lifetime_cap =
          initialFields.services_attributes[0].lifetime_cap_cents / 100;
      }
    }

    return (
      <SLGModalForm
        title="Edit Group"
        formName="groupEdit"
        close={onClose}
        contentStyle={{ width: "700px" }}
      >
        <GroupForm
          formName="groupEdit"
          label="Edit Group"
          backButtonLabel="Cancel"
          submitButtonLabel="Save"
          enableReinitialize={true}
          initialValues={initialFields}
          employerID={employerID}
          employerPayEnabled={employerPayEnabled}
          groupID={group.id}
          isDefaultGroup={group.name === "default"}
          initialEmployeeIDs={map(group.employees, employee => employee.id)}
        />
      </SLGModalForm>
    );
  }
}

export default GroupEditModal;
