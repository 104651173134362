import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import compose from "recompose/compose";
import reduce from "lodash/reduce";
import round from "lodash/round";
import {
  FlexibleXYPlot,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  VerticalBarSeries,
  Hint
} from "react-vis";
import Payment from "@material-ui/icons/Payment";
import DateRange from "@material-ui/icons/DateRange";
import Language from "@material-ui/icons/Language";
import People from "@material-ui/icons/People";
import Person from "@material-ui/icons/Person";
import AttachMoney from "@material-ui/icons/AttachMoney";

import { formatCurrency } from "helpers/formattingHelpers";
import {
  StatCard,
  TableGraph,
  StyledGridContainer,
  StyledGridItem
} from "components";
import { selectEntityIdentifiers } from "../partners/selectors";

export class Stats extends Component {
  state = {
    hoveredCell: false
  };

  filterEnrollmentData = enrollmentData => {
    const { entityIdentifiers } = this.props;

    const barGraphShortNames = {
      [`Total ${entityIdentifiers.employeeIdentifier}s`]: "Total",
      [`Active ${entityIdentifiers.employeeIdentifier}s`]: "Active",
      [`Debt Holding ${entityIdentifiers.employeeIdentifier}s`]: "Debt Holding",
      [`Active ${entityIdentifiers.employeeIdentifier}s in Pay`]: "In Pay"
    };

    return reduce(
      enrollmentData,
      (result, obj) => {
        if (!obj.barGraph || !obj.y) {
          return result;
        }

        result.push({ x: barGraphShortNames[obj.x], y: obj.y });
        return result;
      },
      []
    );
  };

  render() {
    const { isLoading, partnerStats, entityIdentifiers } = this.props;
    const { hoveredCell } = this.state;
    const {
      pay_benefit: benefitStats,
      advisor: advisorStats,
      employers
    } = partnerStats;

    const totalEmployees = employers.all_employees_count;
    const allFamilyCount = employers.all_family_count;

    const enrollmentData = [
      {
        x: `Total ${entityIdentifiers.employerIdentifier}s`,
        y: employers.employers_count || 0,
        barGraph: false
      },
      {
        x: `Total ${entityIdentifiers.employeeIdentifier}s`,
        y: totalEmployees || 0,
        barGraph: true
      },
      {
        x: `Active ${entityIdentifiers.employeeIdentifier}s`,
        y: employers.eligible_employees_count || 0,
        barGraph: true
      },
      {
        x: `Enrolled ${entityIdentifiers.employeeIdentifier}s`,
        y: employers.enrolled_employees_count || 0,
        barGraph: false
      },
      {
        x: `Debt Holding ${entityIdentifiers.employeeIdentifier}s`,
        y: employers.active_employees_count || 0,
        barGraph: true
      },
      {
        x: `Active ${entityIdentifiers.employeeIdentifier}s in Pay`,
        y: employers.employees_on_last_pdr || 0,
        barGraph: true
      },
      {
        x: "Total # of Loans",
        y: advisorStats.total_loan_count || 0
      }
    ];

    if (allFamilyCount > 0) {
      enrollmentData.splice(5, 0, {
        x: "Invited Family Members",
        y: allFamilyCount
      });
    }

    const vaultPayData = [
      {
        x: `Active ${entityIdentifiers.employerIdentifier}s With Pay`,
        y: employers.pay_employers_count || 0
      },
      {
        x: `Average ${entityIdentifiers.employeeIdentifier} Pay Savings`,
        y: formatCurrency(benefitStats.avg_savings_with_benefit, true)
      },
      {
        x: `Average ${entityIdentifiers.employeeIdentifier} Pay Time Saved (mos.)`,
        y: `${round(benefitStats.avg_time_saved_with_benefit)} months`
      },
      {
        x: "Total Contributions Distributed",
        y: formatCurrency(employers.distributed_funds, true)
      }
    ];

    return (
      <React.Fragment>
        <StyledGridContainer>
          <StyledGridItem xs={12} sm={6} md={6} lg={3}>
            <StatCard
              title={`Total ${entityIdentifiers.employeeIdentifier} Debt Balance`}
              data={formatCurrency(advisorStats.total_balance, true)}
              icon={<AttachMoney />}
              footer="Advisor"
              isLoading={isLoading}
              color="info"
            />
          </StyledGridItem>
          <StyledGridItem xs={12} sm={6} md={6} lg={3}>
            <StatCard
              title={`Average ${entityIdentifiers.employeeIdentifier} Debt Balance`}
              data={formatCurrency(advisorStats.avg_balance, true)}
              icon={<Person />}
              footer="Advisor"
              isLoading={isLoading}
              color="rose"
            />
          </StyledGridItem>
          <StyledGridItem xs={12} sm={6} md={6} lg={3}>
            <StatCard
              title={`Average ${entityIdentifiers.employeeIdentifier} Monthly Payment`}
              data={formatCurrency(advisorStats.avg_monthly_payment, true)}
              icon={<Payment />}
              footer="Advisor"
              isLoading={isLoading}
              color="success"
            />
          </StyledGridItem>
          <StyledGridItem xs={12} sm={6} md={6} lg={3}>
            <StatCard
              title={`Average ${entityIdentifiers.employeeIdentifier} Payoff`}
              data={`${round(advisorStats.avg_payoff_time)} months`}
              icon={<DateRange />}
              footer="Advisor"
              isLoading={isLoading}
              color="warning"
            />
          </StyledGridItem>
        </StyledGridContainer>

        <StyledGridContainer>
          <StyledGridItem xs={12}>
            <TableGraph
              title="Enrollment"
              icon={<People />}
              isLoading={isLoading}
              tableData={enrollmentData.map(val => [val.x, val.y])}
              graph={
                <FlexibleXYPlot
                  xType="ordinal"
                  fill="#ff7843"
                  stroke="#ff7843"
                  stackBy="y"
                  yDomain={[0, totalEmployees]}
                >
                  <VerticalGridLines />
                  <HorizontalGridLines />
                  <XAxis />
                  <YAxis
                    title={`Total ${entityIdentifiers.employeeIdentifier}s`}
                  />
                  <VerticalBarSeries
                    data={this.filterEnrollmentData(enrollmentData)}
                    barWidth={0.25}
                    onValueMouseOver={v => {
                      this.setState({ hoveredCell: v });
                    }}
                    onValueMouseOut={() =>
                      this.setState({ hoveredCell: false })
                    }
                  />
                  {hoveredCell && (
                    <Hint
                      value={hoveredCell}
                      align={{
                        horizontal: Hint.ALIGN.AUTO,
                        vertical: Hint.ALIGN.BOTTOM
                      }}
                    >
                      <div className="rv-hint__content">{hoveredCell.y}</div>
                    </Hint>
                  )}
                </FlexibleXYPlot>
              }
            />
          </StyledGridItem>
        </StyledGridContainer>
        <StyledGridContainer>
          <StyledGridItem xs={12}>
            <TableGraph
              title="Pay"
              icon={<Language />}
              tableData={vaultPayData.map(val => [val.x, val.y])}
              isLoading={isLoading}
            />
          </StyledGridItem>
        </StyledGridContainer>
      </React.Fragment>
    );
  }
}

Stats.propTypes = {
  partnerStats: PropTypes.shape({
    pay_benefit: PropTypes.object,
    advisor: PropTypes.object,
    employers: PropTypes.object
  }),
  isLoading: PropTypes.bool.isRequired
};

Stats.defaultProps = {
  partnerStats: {
    pay_benefit: {},
    advisor: {},
    employers: {}
  }
};

function mapStateToProps(state) {
  const entityIdentifiers = selectEntityIdentifiers(state);

  return {
    entityIdentifiers
  };
}

export default compose(connect(mapStateToProps))(Stats);
