import { put, takeLatest, all, call } from "redux-saga/effects";
import { showNotification } from "../app/uiActions";
import { push } from "react-router-redux";
import { CREATE, UPDATE } from "../rest/types";
import slgRestClient from "../rest/slgRestClient";
import {
  VERIFY_TWO_FACTOR,
  VERIFY_TWO_FACTOR_SUCCESS,
  VERIFY_TWO_FACTOR_FAILURE,
  ENABLE_TWO_FACTOR,
  ENABLE_TWO_FACTOR_SUCCESS,
  ENABLE_TWO_FACTOR_FAILURE,
  DISABLE_TWO_FACTOR,
  DISABLE_TWO_FACTOR_SUCCESS,
  DISABLE_TWO_FACTOR_FAILURE,
  hideTwoFactor
} from "./twoFactorActions";
import Cookies from "universal-cookie";
import moment from "moment";
import log from "../services/logging/service";

function requestVerifyTwoFactor(data) {
  return slgRestClient(CREATE, "verify", { data })
    .then(resp => resp.data)
    .catch(e => log.error(e.message));
}

function setPartnerDeviceIdCookie(data) {
  if (data.partner_device_id !== undefined) {
    const cookies = new Cookies();
    cookies.set("partner_device_id", data.partner_device_id, {
      maxAge: moment.duration(1, "month").asSeconds()
    });
  }
}

function* verifyTwoFactor(action) {
  try {
    const respData = yield call(requestVerifyTwoFactor, action.payload.data);
    yield call(setPartnerDeviceIdCookie, respData);
    yield put({ type: VERIFY_TWO_FACTOR_SUCCESS });
    yield put(push("/"));
    yield put(showNotification("Verification successful!"));
  } catch (e) {
    yield put({ type: VERIFY_TWO_FACTOR_FAILURE, message: e.message });
    yield put(showNotification(e.message, "warning"));
    log.error(e.message);
  }
}

function requestEnableTwoFactor(data) {
  return slgRestClient(UPDATE, "enable", { data })
    .then(resp => resp.data)

    .catch(e => log.error(e.message));
}

function* enableTwoFactor(action) {
  try {
    yield call(requestEnableTwoFactor, action.payload.data);
    yield put({ type: ENABLE_TWO_FACTOR_SUCCESS });
    yield put(hideTwoFactor());
    yield put(push("/two_factor/verify"));
  } catch (e) {
    yield put({ type: ENABLE_TWO_FACTOR_FAILURE, message: e.message });
    yield put(showNotification("Failed to enable 2FA", "warning"));
    log.error(e.message);
  }
}

function requestDisableTwoFactor(data) {
  return slgRestClient(UPDATE, "disable", { data })
    .then(resp => resp.data)
    .catch(e => log.error(e.message));
}

function removePartnerDeviceId() {
  const cookies = new Cookies();
  return cookies.remove("partner_device_id");
}

function* disableTwoFactor(action) {
  try {
    yield call(requestDisableTwoFactor, action.payload.data);
    yield call(removePartnerDeviceId);
    yield put({ type: DISABLE_TWO_FACTOR_SUCCESS });
    yield put(showNotification("2FA disabled successfully!"));
    yield put(hideTwoFactor());
  } catch (e) {
    yield put({ type: DISABLE_TWO_FACTOR_FAILURE, message: e.message });
    yield put(showNotification(e.message, "warning"));
    log.error(e.message);
  }
}

export default function* twoFactorSaga() {
  yield all([
    takeLatest(VERIFY_TWO_FACTOR, verifyTwoFactor),
    takeLatest(ENABLE_TWO_FACTOR, enableTwoFactor),
    takeLatest(DISABLE_TWO_FACTOR, disableTwoFactor)
  ]);
}
