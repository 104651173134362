import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import PropTypes from "prop-types";

import {
  TextField as TextInput,
  SelectField as SelectInput
} from "redux-form-material-ui";
import MenuItem from "material-ui/MenuItem";
import RaisedButton from "material-ui/RaisedButton";
import FlatButton from "material-ui/FlatButton";

import { WithPermission } from "../../auth";
import { SYSTEM_ADMIN } from "../../constants";
import { InlinedRadioButton, CurrencyInput } from "../../components";
import stateData from "../../helpers/stateData";
import { required, maxLength50, maxLength15 } from "../../helpers/validation";

const styles = {
  raisedButton: { borderRadius: "3px" },
  inline20: { display: "inline-block", width: "20%" },
  inline30: { display: "inline-block", width: "30%" },
  inline40: { display: "inline-block", width: "40%" },
  spaceRight: { marginRight: "5%" },
  inlineRadio: { display: "flex", width: "100%", marginTop: "12px" },
  formButtonsBar: { marginTop: "20px", float: "right" }
};

export class PartnerBillingForm extends Component {
  handleSelect = (event, index, value) => this.setState({ value });

  menuItems(items) {
    return items.map(item => (
      <MenuItem key={item.id} value={item.id} primaryText={item.name} />
    ));
  }

  render() {
    const {
      handleSubmit,
      handlePrev,
      backButtonLabel,
      submitButtonLabel
    } = this.props;

    return (
      <div>
        <form onSubmit={handleSubmit}>
          <div>
            <Field
              name="billing_address_attributes.street_1"
              component={TextInput}
              floatingLabelText="Address 1 *"
              floatingLabelShrinkStyle={styles.inputLabel}
              underlineStyle={styles.inputUnderline}
              fullWidth={true}
              validate={required}
              autoFocus={true}
            />
          </div>
          <div>
            <Field
              name="billing_address_attributes.street_2"
              component={TextInput}
              floatingLabelText="Address 2"
              floatingLabelShrinkStyle={styles.inputLabel}
              underlineStyle={styles.inputUnderline}
              fullWidth={true}
            />
          </div>
          <div style={{ display: "flex" }}>
            <Field
              name="billing_address_attributes.city"
              component={TextInput}
              floatingLabelText="City *"
              floatingLabelShrinkStyle={styles.inputLabel}
              underlineStyle={styles.inputUnderline}
              style={Object.assign({}, styles.inline40, styles.spaceRight)}
              validate={[required, maxLength50]}
            />
            <Field
              name="billing_address_attributes.state"
              component={SelectInput}
              floatingLabelText="State *"
              floatingLabelShrinkStyle={styles.inputLabel}
              underlineStyle={styles.inputUnderline}
              style={Object.assign({}, styles.inline30, styles.spaceRight)}
              validate={required}
            >
              {this.menuItems(stateData)}
            </Field>
            <Field
              name="billing_address_attributes.zip_code"
              component={TextInput}
              floatingLabelText="Zip *"
              floatingLabelShrinkStyle={styles.inputLabel}
              underlineStyle={styles.inputUnderline}
              style={styles.inline20}
              validate={[required, maxLength15]}
            />
          </div>
          <WithPermission value={SYSTEM_ADMIN}>
            <div>
              <Field
                name="transaction_fee"
                component={CurrencyInput}
                label="Transaction Fee *"
                style={Object.assign({}, styles.inline30, styles.spaceRight)}
                validate={required}
                parse={val => {
                  return parseFloat(val.replace(/,/g, "") || 0);
                }}
              />
            </div>
            <div style={styles.inlineRadio}>
              <span style={styles.inline40}>Bill Fees To:</span>
              <InlinedRadioButton
                addLabel
                label="Bill Fees To"
                name="bill_fee_to_employer"
              />
            </div>
            <div style={styles.inlineRadio}>
              <span style={styles.inline40}>Bill Funds Request To:</span>
              <InlinedRadioButton
                addLabel
                label="Bill PDR To"
                name="bill_pdr_to_employer"
              />
            </div>
            <div>
              <Field
                name="invoice_lead_days"
                component={SelectInput}
                floatingLabelText="Invoice Payment Lead Time *"
                floatingLabelShrinkStyle={styles.inputLabel}
                underlineStyle={styles.inputUnderline}
                style={styles.halfWidth}
                validate={[required]}
              >
                {[4, 7, 10, 14, 21].map(item => (
                  <MenuItem
                    value={item}
                    key={item}
                    primaryText={`${item} days`}
                  />
                ))}
              </Field>
            </div>
          </WithPermission>
          <div style={styles.formButtonsBar}>
            {handlePrev ? (
              <FlatButton
                label={backButtonLabel}
                type="button"
                onClick={handlePrev}
                style={{ marginRight: "12px" }}
              />
            ) : null}
            <RaisedButton
              label={submitButtonLabel}
              type="submit"
              buttonStyle={styles.raisedButton}
              style={styles.raisedButton}
              primary={true}
            />
          </div>
        </form>
      </div>
    );
  }
}

PartnerBillingForm.propTypes = {
  formName: PropTypes.string.isRequired,
  handlePrev: PropTypes.func,
  backButtonLabel: PropTypes.string,
  submitButtonLabel: PropTypes.string
};

PartnerBillingForm.defaultProps = {
  backButtonLabel: "Back",
  submitButtonLabel: "Save"
};

function mapStateToProps(state, props) {
  return {
    form: props.formName
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true
  })(PartnerBillingForm)
);
