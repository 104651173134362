import React from "react";
import * as R from "ramda";
 
import useFetch from "../containers/utils/useFetch";
var moment = require('moment'); 
const defaultValues = {
  type: "",
  employer: "",
  invoice_id: "",
  from_date: null,
  to_date: null
};

export default WC => ({
  resource,
  partnerId,
  employerId,
  open,
  onClose,
  onGenerateReportCompleted,
  ...props
}) => {
  const [generatingReport, setGeneratingReport] = React.useState(false);
  const [reportType, setReportType] = React.useState("");
  const [employers, setEmployers] = React.useState([]);
  const [invoices, setInvoices] = React.useState([]);
  const [selectedEmployer, setSelectedEmployer] = React.useState(employerId);
  const [fromDate, setFromDate] = React.useState("");
  const [toDate, setToDate] = React.useState("");

  const generateReport = useFetch({ method: "post" });
  const getEmployers = useFetch({ method: "get" });
  const getInvoices = useFetch({ method: "get" });

  /** post call needs to be completed before clsoing modal */
  const generateReportLoading = generateReport.loading;
  React.useEffect(() => {
    if (generateReportLoading) {
      setGeneratingReport(true);
    } else if (generatingReport && !generateReportLoading) {
      setGeneratingReport(false);
      if (onGenerateReportCompleted) onGenerateReportCompleted();
      onClose();
    }
  }, [
    generateReportLoading,
    onClose,
    generatingReport,
    onGenerateReportCompleted
  ]);

  /** get employers */
  let latestGetEmployer = React.useRef(getEmployers.makeFetch);
  React.useEffect(() => {
    latestGetEmployer.current = getEmployers.makeFetch;
  });
  React.useEffect(() => {
    if (open && partnerId && resource === "partners") {
      function handleSuccess({ data }) {
        setEmployers(data);
      }
      const filter = {
        "0": {
          partner_id_eq: partnerId
        }
      };
      const path = `/employers`;
      latestGetEmployer.current({ path, filter, onSuccess: handleSuccess });
    }
  }, [open, resource, partnerId]);

  /** get invoices */
  let latestGetInvoices = React.useRef(getInvoices.makeFetch);
  React.useEffect(() => {
    latestGetInvoices.current = getInvoices.makeFetch;
  });
  React.useEffect(() => {
    if (open && selectedEmployer && reportType !== "employer_impact_report") {
      function handleSuccess({ data }) {
        setInvoices(data);
      }
      const filter = {
        "0": {
          employer_id_eq: selectedEmployer
        }
      };
      const path = `/invoices`;
      latestGetInvoices.current({ path, filter, onSuccess: handleSuccess });
    }
  }, [open, selectedEmployer, reportType]);

  function handleSubmit(data) {
   
    const id = resource === "partners" ? partnerId : employerId;
    const addId = R.ifElse(
      ({ resource }) => resource === "partners",
      ({ data }) => ({ ...data, partner_id: partnerId,from_date:fromDate,to_date:toDate }),
      ({ data }) => ({ ...data })
    );
    const mergedData = addId({ resource, data, fromDate, toDate});
    const path = `/${resource}/${id}/reports`;
    generateReport.makeFetch({ path, data: mergedData });
  }

  function handleTypeChange(e) {
    setReportType(e.target.value);
  }

  function handleEmployerChange(v) {
    const id = R.pathOr("", ["id"], v);
    setSelectedEmployer(id);
  }

  function handleClose() {
    if (!generateReport.loading) {
      setReportType(defaultValues.type);
      onClose();
    }
  }
  function handleChangeFromDate(e){
    let from_date = moment(e).format("YYYY-MM-DD")
    setFromDate(from_date)
  }
  function handleChangeToDate(e){
    let to_date = moment(e).format("YYYY-MM-DD")
    setToDate(to_date)
  }
  const stateProps = {
    reportTypeState: [reportType, setReportType],
    employersState: [employers, setEmployers],
    invoicesState: [invoices, setInvoices],
    selectedEmployerState: [selectedEmployer, setSelectedEmployer],
    generateReport,
    getEmployers,
    getInvoices,
    fromDateState:[fromDate, setFromDate],
    toDateState:[toDate, setToDate]
  };

  const handlers = {
    onTypeChange: handleTypeChange,
    onClose: handleClose,
    onEmployerChange: handleEmployerChange,
    onSubmit: handleSubmit,
    handleChangeFromDate: handleChangeFromDate,
    handleChangeToDate: handleChangeToDate
  };

  return (
    <WC
      stateProps={stateProps}
      {...handlers}
      defaultValues={defaultValues}
      resource={resource}
      partnerId={partnerId}
      employerId={employerId}
      open={open}
      loading={generateReport.loading}
      {...props}
    />
  );
};
