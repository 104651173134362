import React from "react";
import * as R from "ramda";

import getFileTypeFromName from "../../services/utils/getFileTypeFromName";
import withEmployerIdFromRouter from "../../components/containers/utils/withEmployerIdFromRouter";
import BaseReportPage from "../../components/reports/ReportPage";
import withProps from "../../components/reports/withProps";
import Form from "../../components/reports/GenerateReportForm";
import withReportsFilter from "./withReportsFilter";

const createGetReportPath = employerId =>
  employerId && `/employers/${employerId}/reports`;

const createFileDownloadPath = employerId => row => {
  const fileType = getFileTypeFromName(row.file_name);
  return (
    employerId &&
    `/employers/${employerId}/download_employer${
      fileType === "pdf" ? "_impact" : ""
    }_report`
  );
};

function extraProps(pageProps) {
  const employerId = pageProps.employerId;
  function renderForm(formProps) {
    const ReportForm = R.compose(
      withProps(() => ({
        employerId,
        resource: "employers",
        employer: pageProps.employer,
        ...formProps
      }))
    )(Form);

    return <ReportForm />;
  }

  return {
    path: createGetReportPath(employerId),
    employerId,
    renderGenerateForm: renderForm,
    createDownloadPathFromRow: createFileDownloadPath(employerId)
  };
}

export default R.compose(
  withEmployerIdFromRouter,
  withProps(extraProps),
  withReportsFilter
)(BaseReportPage);
