import {
  FETCH_EMPLOYEES,
  FETCH_EMPLOYEE,
  IMPERSONATE_USER,
  CREATE_EMPLOYEE,
  EDIT_EMPLOYEE,
  FETCH_EMPLOYEE_NOTES,
  CREATE_EMPLOYEE_NOTE,
  EDIT_EMPLOYEE_NOTE,
  DELETE_EMPLOYEE_NOTE,
  SEND_EMPLOYEE_INVITE,
  DOWNLOAD_EMPLOYEES_TO_CSV
} from "./types";

export const fetchEmployees = (
  id,
  sort,
  page,
  limit,
  query,
  type = "employer",
  fields,
  filter
) => {
  return {
    type: FETCH_EMPLOYEES,
    payload: { employerID: id, sort, page, limit, query, type, fields, filter }
  };
};

export const fetchEmployee = employeeID => {
  return {
    type: FETCH_EMPLOYEE,
    payload: { employeeID }
  };
};

export const impersonateUser = employeeID => {
  return {
    type: IMPERSONATE_USER,
    payload: { employeeID }
  };
};

export const createEmployee = (data, formName, employerID, onSuccess) => {
  return {
    type: CREATE_EMPLOYEE,
    payload: { data: { ...data, employer_id: employerID } },
    formName,
    onSuccess
  };
};

export const editEmployee = (data, formName, employeeID, onSuccess) => {
  return {
    type: EDIT_EMPLOYEE,
    payload: { data },
    formName,
    employeeID,
    onSuccess
  };
};

export const fetchEmployeeNotes = (id, sort, page) => {
  return {
    type: FETCH_EMPLOYEE_NOTES,
    payload: { employeeID: id, sort, page }
  };
};

export const createEmployeeNote = (data, formName, employeeID, onSuccess) => {
  return {
    type: CREATE_EMPLOYEE_NOTE,
    payload: { data },
    formName,
    employeeID,
    onSuccess
  };
};

export const editEmployeeNote = (data, formName, noteID, onSuccess) => {
  return {
    type: EDIT_EMPLOYEE_NOTE,
    payload: { data },
    formName: formName,
    noteID,
    onSuccess
  };
};

export const deleteEmployeeNote = (noteID, onSuccess) => {
  return {
    type: DELETE_EMPLOYEE_NOTE,
    payload: { noteID },
    onSuccess
  };
};

export const sendEmployeeInvite = employee => {
  return {
    type: SEND_EMPLOYEE_INVITE,
    payload: { employee }
  };
};

export const downloadEmployeesToCsv = (employerID, type) => {
  return {
    type: DOWNLOAD_EMPLOYEES_TO_CSV,
    payload: { employerID, type }
  };
};
