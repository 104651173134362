import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Field,
  reduxForm,
  formValueSelector,
  clearFields as clearFieldsAction
} from "redux-form";
import moment from "moment";
import { range } from "lodash";

import {
  TextField as TextInput,
  Toggle as ToggleInput,
  SelectField as SelectInput
} from "redux-form-material-ui";
import MenuItem from "material-ui/MenuItem";
import RaisedButton from "material-ui/RaisedButton";
import FlatButton from "material-ui/FlatButton";

import stateData from "../../helpers/stateData";
import { required, maxLength50, maxLength15 } from "../../helpers/validation";
import { WithPermission } from "auth";
import {
  SYSTEM_ADMIN,
  PARTNER_ADMIN,
  EMPLOYERS_ADMIN,
  EMPLOYER_MANAGER
} from "../../constants";

const styles = {
  inputUnderline: { borderColor: "#258ffa" },
  inputLabel: { color: "#00acc1" },
  raisedButton: { borderRadius: "3px" },
  inline20: { display: "inline-block", width: "20%" },
  inline30: { display: "inline-block", width: "30%" },
  inline40: { display: "inline-block", width: "40%" },
  spaceRight: { marginRight: "5%" },
  formButtonsBar: { marginTop: "20px", float: "right" },
  payForm: { marginTop: "20px" },
  payToggle: { width: "20%", fontWeight: "bold" },
  contributionsNote: {
    position: "absolute",
    bottom: "12px",
    right: "0",
    width: "35%",
    fontSize: "12px",
    textAlign: "center",
    fontWeight: "bold"
  }
};

export class EmployerPayForm extends Component {
  handleSelect = (event, index, value) => this.setState({ value });

  menuItems(items) {
    return items.map(item => (
      <MenuItem key={item.id} value={item.id} primaryText={item.name} />
    ));
  }

  _clearPayFields = () => {
    const { formName, clearFields } = this.props;
    clearFields(formName, false, false, "contribution_date", "payroll_cycle");
  };

  render() {
    const {
      handleSubmit,
      handlePrev,
      backButtonLabel,
      submitButtonLabel,
      payEnabled,
      hasLockedGroups,
      wrapperStyle
    } = this.props;

    const monthData = moment
      .months()
      .map(month => Object.assign({}, { name: month }, { id: month }));

    const yearData = range(
      moment().year(),
      moment()
        .add(4, "years")
        .year()
    ).map(year => Object.assign({}, { name: year }, { id: year }));

    const addressForm = (
      <React.Fragment>
        <div>
          <Field
            name="billing_address.street_1"
            component={TextInput}
            floatingLabelText="Address 1 *"
            floatingLabelShrinkStyle={styles.inputLabel}
            underlineStyle={styles.inputUnderline}
            fullWidth={true}
            validate={required}
          />
        </div>
        <div>
          <Field
            name="billing_address.street_2"
            component={TextInput}
            floatingLabelText="Address 2"
            floatingLabelShrinkStyle={styles.inputLabel}
            underlineStyle={styles.inputUnderline}
            fullWidth={true}
          />
        </div>
        <div style={{ display: "flex" }}>
          <Field
            name="billing_address.city"
            component={TextInput}
            floatingLabelText="City *"
            floatingLabelShrinkStyle={styles.inputLabel}
            underlineStyle={styles.inputUnderline}
            style={Object.assign({}, styles.inline40, styles.spaceRight)}
            validate={[required, maxLength50]}
          />
          <Field
            name="billing_address.state"
            component={SelectInput}
            floatingLabelText="State *"
            floatingLabelShrinkStyle={styles.inputLabel}
            underlineStyle={styles.inputUnderline}
            style={Object.assign({}, styles.inline30, styles.spaceRight)}
            validate={required}
          >
            {this.menuItems(stateData)}
          </Field>
          <Field
            name="billing_address.zip_code"
            component={TextInput}
            floatingLabelText="Zip *"
            floatingLabelShrinkStyle={styles.inputLabel}
            underlineStyle={styles.inputUnderline}
            style={styles.inline20}
            validate={[required, maxLength15]}
          />
        </div>
      </React.Fragment>
    );

    const payForm = (
      <div>
        <div style={{ display: "flex", position: "relative" }}>
          <Field
            name="contribution_date.month"
            component={SelectInput}
            floatingLabelText="Start Month *"
            floatingLabelFixed={true}
            floatingLabelShrinkStyle={
              hasLockedGroups ? null : styles.inputLabel
            }
            underlineStyle={styles.inputUnderline}
            style={Object.assign({}, styles.inline30, styles.spaceRight)}
            validate={required}
            disabled={hasLockedGroups}
          >
            {this.menuItems(monthData)}
          </Field>
          <Field
            name="contribution_date.year"
            component={SelectInput}
            floatingLabelText="Start Year *"
            floatingLabelFixed={true}
            floatingLabelShrinkStyle={
              hasLockedGroups ? null : styles.inputLabel
            }
            underlineStyle={styles.inputUnderline}
            style={Object.assign({}, styles.inline20, styles.spaceRight)}
            validate={required}
            disabled={hasLockedGroups}
          >
            {this.menuItems(yearData)}
          </Field>
          {hasLockedGroups ? (
            <div style={styles.contributionsNote}>
              Contributions are currently being processed. Cannot update Pay
              Settings at this time.
            </div>
          ) : (
            <div style={styles.contributionsNote}>
              Contributions sent on the 9th of the month
            </div>
          )}
        </div>
        <div style={{ display: "flex" }}>
          <Field
            name="payroll_cycle"
            component={SelectInput}
            floatingLabelText="Interval *"
            floatingLabelShrinkStyle={
              hasLockedGroups ? null : styles.inputLabel
            }
            underlineStyle={styles.inputUnderline}
            style={Object.assign({}, styles.inline30, styles.spaceRight)}
            validate={required}
            disabled={hasLockedGroups}
          >
            <MenuItem value={"monthly"} primaryText="Monthly" />
            <MenuItem value={"quarterly"} primaryText="Quarterly" />
            <MenuItem value={"annually"} primaryText="Annually" />
          </Field>
        </div>
      </div>
    );

    const content = (
      <div style={wrapperStyle}>
        {addressForm}
        <div style={styles.payForm}>
          <WithPermission
            value={[
              SYSTEM_ADMIN,
              PARTNER_ADMIN,
              EMPLOYERS_ADMIN,
              EMPLOYER_MANAGER
            ]}
          >
            <Field
              name="pay_enabled?"
              component={ToggleInput}
              label="PAY"
              style={styles.payToggle}
              onChange={(event, newValue, prevValue, name) => {
                return newValue ? event : event && this._clearPayFields();
              }}
              autoFocus={true}
              disabled={hasLockedGroups}
            />

            {payEnabled ? payForm : null}
          </WithPermission>
        </div>
      </div>
    );

    return (
      <div>
        <form onSubmit={handleSubmit}>
          {content}
          <div style={styles.formButtonsBar}>
            {handlePrev ? (
              <FlatButton
                label={backButtonLabel}
                type="button"
                onClick={handlePrev}
                style={{ marginRight: "12px" }}
              />
            ) : null}
            <RaisedButton
              label={submitButtonLabel}
              type="submit"
              buttonStyle={styles.raisedButton}
              style={styles.raisedButton}
              primary={true}
            />
          </div>
        </form>
      </div>
    );
  }
}

EmployerPayForm.propTypes = {
  formName: PropTypes.string.isRequired,
  handlePrev: PropTypes.func,
  backButtonLabel: PropTypes.string,
  submitButtonLabel: PropTypes.string,
  enableReinitialize: PropTypes.bool,
  hasLockedGroups: PropTypes.bool
};

EmployerPayForm.defaultProps = {
  backButtonLabel: "Back",
  submitButtonLabel: "Save",
  enableReinitialize: false
};

const selector = (formName, ...other) => formValueSelector(formName)(...other);

function mapStateToProps(state, props) {
  return {
    form: props.formName,
    enableReinitialize: props.enableReinitialize, // needed to reset payroll month/year on edit
    payEnabled: selector(props.formName, state, "pay_enabled?")
  };
}

export default connect(
  mapStateToProps,
  { clearFields: clearFieldsAction }
)(
  reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true
  })(EmployerPayForm)
);
