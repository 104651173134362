export const FETCH_EMPLOYEES = "FETCH_EMPLOYEES_LIST";
export const FETCH_EMPLOYEES_FAILURE = "FETCH_EMPLOYEES_LIST_FAILURE";
export const FETCH_EMPLOYEES_SUCCESS = "FETCH_EMPLOYEES_LIST_SUCCESS";

export const FETCH_EMPLOYEE = "FETCH_EMPLOYEE";
export const FETCH_EMPLOYEE_FAILURE = "FETCH_EMPLOYEE_FAILURE";
export const FETCH_EMPLOYEE_SUCCESS = "FETCH_EMPLOYEE_SUCCESS";

export const IMPERSONATE_USER = "IMPERSONATE_USER";
export const IMPERSONATE_USER_FAILURE = "IMPERSONATE_USER_FAILURE";
export const IMPERSONATE_USER_SUCCESS = "IMPERSONATE_USER_SUCCESS";

export const CREATE_EMPLOYEE = "CREATE_EMPLOYEE";
export const CREATE_EMPLOYEE_SUCCESS = "CREATE_EMPLOYEE_SUCCESS";
export const CREATE_EMPLOYEE_FAILURE = "CREATE_EMPLOYEE_FAILURE";

export const EDIT_EMPLOYEE = "EDIT_EMPLOYEE";
export const EDIT_EMPLOYEE_FAILURE = "EDIT_EMPLOYEE_FAILURE";
export const EDIT_EMPLOYEE_SUCCESS = "EDIT_EMPLOYEE_SUCCESS";

export const FETCH_EMPLOYEE_NOTES = "FETCH_EMPLOYEE_NOTES_LIST";
export const FETCH_EMPLOYEE_NOTES_FAILURE = "FETCH_EMPLOYEE_NOTES_LIST_FAILURE";
export const FETCH_EMPLOYEE_NOTES_SUCCESS = "FETCH_EMPLOYEE_NOTES_LIST_SUCCESS";

export const CREATE_EMPLOYEE_NOTE = "CREATE_EMPLOYEE_NOTE";
export const CREATE_EMPLOYEE_NOTE_SUCCESS = "CREATE_EMPLOYEE_NOTE_SUCCESS";
export const CREATE_EMPLOYEE_NOTE_FAILURE = "CREATE_EMPLOYEE_NOTE_FAILURE";

export const EDIT_EMPLOYEE_NOTE = "EDIT_EMPLOYEE_NOTE";
export const EDIT_EMPLOYEE_NOTE_SUCCESS = "EDIT_EMPLOYEE_NOTE_SUCCESS";
export const EDIT_EMPLOYEE_NOTE_FAILURE = "EDIT_EMPLOYEE_NOTE_FAILURE";

export const DELETE_EMPLOYEE_NOTE = "DELETE_EMPLOYEE_NOTE";
export const DELETE_EMPLOYEE_NOTE_SUCCESS = "DELETE_EMPLOYEE_NOTE_SUCCESS";
export const DELETE_EMPLOYEE_NOTE_FAILURE = "DELETE_EMPLOYEE_NOTE_FAILURE";

export const SEND_EMPLOYEE_INVITE = "SEND_EMPLOYEE_INVITE";
export const SEND_EMPLOYEE_INVITE_SUCCESS = "SEND_EMPLOYEE_INVITE_SUCCESS";
export const SEND_EMPLOYEE_INVITE_FAILURE = "SEND_EMPLOYEE_INVITE_FAILURE";

export const DOWNLOAD_EMPLOYEES_TO_CSV = "DOWNLOAD_EMPLOYEES_TO_CSV";
export const DOWNLOAD_EMPLOYEES_TO_CSV_SUCCESS =
  "DOWNLOAD_EMPLOYEES_TO_CSV_SUCCESS";
export const DOWNLOAD_EMPLOYEES_TO_CSV_FAILURE =
  "DOWNLOAD_EMPLOYEES_TO_CSV_FAILURE";
