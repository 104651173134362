import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import moment from "moment";
import { get } from "lodash";
import {
  TextField as TextInput,
  DatePicker as DateInput,
  SelectField as SelectInput,
  Checkbox
} from "redux-form-material-ui";
import RaisedButton from "material-ui/RaisedButton";
import FlatButton from "material-ui/FlatButton";
import MenuItem from "material-ui/MenuItem";
import CircularProgress from "material-ui/CircularProgress";
import { required, maxLength50, email, formatDateTimezone } from "helpers";
import { editEmployee, createEmployee } from "employees/actions";
import { fetchGroups } from "groups/actions";
import { selectEmployerByID } from "employers/selectors";
import { selectGroupsByEmployer } from "groups/selectors/index";
import { SORT_ASC } from "constants/index";

const styles = {
  inputUnderline: { borderColor: "#258ffa" },
  inputLabel: { color: "#00acc1" },
  raisedButton: { borderRadius: "3px" },
  width70: { width: "70%" },
  width50: { width: "50%" },
  formButtonsBar: { marginTop: "20px", float: "right" },
  activeToggle: { width: "20%", fontWeight: "bold", marginTop: "10px" },
  activeToggle2: { width: "27%", fontWeight: "bold", marginTop: "10px" },
  buttonIcon: { height: "20px" },
  disabledInputMessage: { color: "#0000004d", fontSize: "12px" },
  checkbox: { marginTop: "10px", marginBottom: "16px" }
};

export class EmployeeForm extends Component {
  static propTypes = {
    formName: PropTypes.string.isRequired,
    submitButtonLabel: PropTypes.string,
    emailDisabled: PropTypes.bool,
    employeeID: PropTypes.string,
    employerID: PropTypes.string,
    employee: PropTypes.object,
    fetchGroups: PropTypes.func.isRequired,
    employer: PropTypes.object.isRequired,
    groups: PropTypes.array,
    isLoading: PropTypes.bool.isRequired
  };

  static defaultProps = {
    submitButtonLabel: "Save",
    emailDisabled: true,
    groups: []
  };

  componentDidMount() {
    const sort = { field: "created_at", order: SORT_ASC };
    const limit = "all";
    this.props.fetchGroups(this.props.employerID, sort, null, limit);
  }

  _submit = values => {
    const {
      createEmployee,
      editEmployee,
      formName,
      employeeID,
      employerID,
      employee,
      onSuccess
    } = this.props;

    let data = values;

    // Doing this + deleting employee_status because employee_status was only used as a proxy for setting
    // deactivated_on, and employee_status is no longer recognized on the backend. We should change the key name once
    // we introduce effective_on to this form
    if (data.employee_status === "inactive") {
      data.deactivated_on = moment().format("YYYY-MM-DD");
      data.effective_on = null;
    } else {
      data.effective_on = moment().format("YYYY-MM-DD");
      data.deactivated_on = null;
    }

    delete data.employee_status;

    switch (formName) {
      case "employeeEdit":
        const personalIds = employee && employee.personal_ids;
        const personalId =
          personalIds && personalIds[0] ? personalIds[0].id : null;
        if (values.personal_id) {
          data = Object.assign({}, data, {
            personal_ids_attributes: [
              {
                id: personalId,
                employer_id: employerID,
                pid: values.personal_id
              }
            ]
          });
        }
        return editEmployee(data, formName, employeeID, onSuccess);
      case "employeeCreate":
        if (values.personal_id) {
          data.personal_ids_attributes = [
            {
              id: personalId,
              employer_id: employerID,
              pid: values.personal_id
            }
          ];
        }
        return createEmployee(data, formName, employerID, onSuccess);
      default:
        return null;
    }
  };

  render() {
    const {
      handleSubmit,
      handlePrev,
      submitButtonLabel,
      emailDisabled,
      employeeID,
      employer,
      groups,
      isLoading
    } = this.props;

    const groupSelectLocked =
      employeeID &&
      (get(employer, "locked?") || get(employer, "any_locked_groups?"));

    return (
      <div>
        <form onSubmit={handleSubmit(this._submit)}>
          <div>
            <Field
              name="personal_id"
              component={TextInput}
              floatingLabelText="Personal Id *"
              floatingLabelShrinkStyle={styles.inputLabel}
              underlineStyle={styles.inputUnderline}
              style={styles.width70}
              validate={[required, maxLength50]}
            />
          </div>
          <div>
            <Field
              name="email"
              component={TextInput}
              floatingLabelText={emailDisabled ? "Email" : "Email *"}
              floatingLabelShrinkStyle={
                emailDisabled ? null : styles.inputLabel
              }
              underlineStyle={styles.inputUnderline}
              validate={[required, email]}
              disabled={emailDisabled}
              fullWidth={true}
            />
          </div>
          <div>
            <Field
              name="first_name"
              component={TextInput}
              floatingLabelText="First Name *"
              floatingLabelShrinkStyle={styles.inputLabel}
              underlineStyle={styles.inputUnderline}
              style={styles.width70}
              validate={[required, maxLength50]}
            />
          </div>
          <div>
            <Field
              name="last_name"
              component={TextInput}
              floatingLabelText="Last Name *"
              floatingLabelShrinkStyle={styles.inputLabel}
              underlineStyle={styles.inputUnderline}
              style={styles.width70}
              validate={[required, maxLength50]}
            />
          </div>

          <div>
            {isLoading ? (
              <CircularProgress size={40} />
            ) : (
              <Field
                name="group_id"
                component={SelectInput}
                floatingLabelText="Group *"
                floatingLabelShrinkStyle={styles.inputLabel}
                underlineStyle={styles.inputUnderline}
                style={Object.assign({}, styles.inline30, styles.spaceRight)}
                validate={required}
                disabled={groupSelectLocked}
              >
                {groups.map(group => {
                  return (
                    <MenuItem
                      key={group.id}
                      value={group.id}
                      primaryText={group.name}
                    />
                  );
                })}
              </Field>
            )}
            {groupSelectLocked && (
              <div style={styles.disabledInputMessage}>
                Contributions are currently being processed.
                <br />
                Cannot change group at this time.
              </div>
            )}
          </div>
          <div>
            <Field
              name="vested_started_on"
              component={DateInput}
              floatingLabelText="Employment Start Date"
              floatingLabelShrinkStyle={styles.inputLabel}
              underlineStyle={styles.inputUnderline}
              textFieldStyle={styles.width50}
              format={date => date && formatDateTimezone(date)}
              normalize={date =>
                date ? moment(date).format("YYYY/MM/DD") : null
              }
            />
          </div>
          <div>
            <Field
              name="employee_status"
              component={Checkbox}
              label="Active"
              style={styles.checkbox}
              format={val => val === "active"}
              parse={val => (val ? "active" : "inactive")}
            />
          </div>
          {employeeID === undefined && (
            <div>
              <Field
                name="invite_now"
                component={Checkbox}
                label="Invite Now"
                style={styles.checkbox}
              />
            </div>
          )}

          <div />
          <div style={styles.formButtonsBar}>
            <FlatButton
              label="Cancel"
              type="button"
              onClick={handlePrev}
              style={{ marginRight: "12px" }}
            />
            <RaisedButton
              label={submitButtonLabel}
              type="submit"
              buttonStyle={styles.raisedButton}
              style={styles.raisedButton}
              primary={true}
            />
          </div>
        </form>
      </div>
    );
  }
}

EmployeeForm = reduxForm({
  // destroyOnMount=false and forceUnregisterOnUnmount=true required for stepper
  // wizard so that form data is not lost across steps.
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true
})(EmployeeForm);

const mapStateToProps = (state, ownProps) => {
  return {
    form: ownProps.formName,
    employer: selectEmployerByID(state, ownProps.employerID),
    groups: selectGroupsByEmployer(state, ownProps.employerID),
    isLoading: state.groups.isFetching
  };
};
const mapDispatchToProps = (_dispatch, _ownProps) => {
  return { createEmployee, editEmployee, fetchGroups };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps()
)(EmployeeForm);
