import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import compose from "recompose/compose";

import {
  fetchEmployer as fetchEmployerAction,
  fetchEmployerStats as fetchEmployerStatsAction
} from "./employerActions";
import { selectEmployerByID, selectEmployerStatsByID } from "./selectors";

export class Employer extends Component {
  componentDidMount() {
    const { match, fetchEmployer, fetchEmployerStats } = this.props;
    const { employerID } = match.params;
    fetchEmployerStats(employerID);

    if (employerID) {
      fetchEmployer(employerID);
    }
  }

  componentDidUpdate(previousProps) {
    const pEmployerId = previousProps.employerID;
    const employerId = this.props.employerID;

    if (pEmployerId !== employerId) {
      this.props.fetchEmployer(employerId);
    }
  }

  render() {
    const {
      employerID,
      employer,
      employerStats,
      employerStatsLoading,
      render
    } = this.props;
    return render({
      employerID,
      employer,
      employerStats,
      employerStatsLoading
    });
  }
}

Employer.propTypes = {
  employer: PropTypes.object,
  employerStats: PropTypes.object,
  employerStatsLoading: PropTypes.bool.isRequired,
  fetchEmployerStats: PropTypes.func.isRequired,
  fetchEmployer: PropTypes.func.isRequired,
  render: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired
};

Employer.defaultProps = {
  employerStats: {
    benefitStats: {},
    advisorStats: {}
  },
  employer: {}
};

function mapStateToProps(state, props) {
  const { employerID } = props.match.params;
  return {
    employerID,
    employer: selectEmployerByID(state, employerID),
    employerStats: selectEmployerStatsByID(state, employerID),
    employerStatsLoading: state.employerStats.isFetching
  };
}

const enhance = compose(
  connect(
    mapStateToProps,
    {
      fetchEmployerStats: fetchEmployerStatsAction,
      fetchEmployer: fetchEmployerAction
    }
  )
);

export default enhance(Employer);
