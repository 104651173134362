import jwt_decode from "jwt-decode";
import LogRocket from "logrocket";
import {
  TOKEN,
  ROLE,
  EMPLOYER_ID,
  PARTNER_ID,
  BENEFITS_ADMIN,
  TWO_FACTOR_ENABLED,
  ENTITY,
  USER_NAME,
  USER_ID,
  USER_EMAIL,
  INTERCOM_HASH,
  SESSIONS
} from "../constants";

export const clearAuthDetails = () => {
  localStorage.removeItem(TOKEN);
  localStorage.removeItem(ROLE);
  localStorage.removeItem(EMPLOYER_ID);
  localStorage.removeItem(PARTNER_ID);
  localStorage.removeItem(TWO_FACTOR_ENABLED);
  localStorage.removeItem(ENTITY);
  localStorage.removeItem(USER_NAME);
  localStorage.removeItem(USER_EMAIL);
  localStorage.removeItem(INTERCOM_HASH);
  localStorage.removeItem(USER_ID);
  localStorage.removeItem(SESSIONS);
};

export const setAuthDetails = authToken => {
  const decodedToken = jwt_decode(authToken);
  const decodedRole = decodedToken[ROLE];
  const decodedTwoFactorEnabled = decodedToken[TWO_FACTOR_ENABLED];
  const decodedEntity = decodedToken[ENTITY];
  const decodedUserName = decodedToken[USER_NAME];
  const decodedUserEmail = decodedToken[USER_EMAIL];
  const decodedIntercomHash = decodedToken[INTERCOM_HASH];
  const decodedUserID = decodedToken["sub"];

  localStorage.setItem(TOKEN, authToken);
  localStorage.setItem(ROLE, decodedRole);
  localStorage.setItem(TWO_FACTOR_ENABLED, decodedTwoFactorEnabled);
  localStorage.setItem(ENTITY, decodedEntity);
  localStorage.setItem(USER_NAME, decodedUserName);
  localStorage.setItem(USER_EMAIL, decodedUserEmail);
  localStorage.setItem(INTERCOM_HASH, decodedIntercomHash);
  localStorage.setItem(USER_ID, decodedUserID);
  localStorage.removeItem(SESSIONS);

  if (decodedRole === BENEFITS_ADMIN) {
    localStorage.setItem(EMPLOYER_ID, decodedToken[EMPLOYER_ID]);
  } else {
    localStorage.setItem(PARTNER_ID, decodedToken[PARTNER_ID]);
  }
  return {
    role: decodedRole,
    userRole: decodedRole,
    twoFactorEnabled: decodedTwoFactorEnabled,
    entity: decodedEntity,
    userName: decodedUserName,
    userEmail: decodedUserEmail,
    userID: decodedUserID,
    partnerID: decodedToken[PARTNER_ID],
    employerID: decodedToken[EMPLOYER_ID]
  };
};

export const getUserContext = () => {
  const userName = localStorage.getItem(USER_NAME);
  const userEmail = localStorage.getItem(USER_EMAIL);
  const partnerID = localStorage.getItem(PARTNER_ID);
  const employerID = localStorage.getItem(EMPLOYER_ID);
  const entityID = partnerID || employerID;
  const userRole = localStorage.getItem(ROLE);
  const entity = localStorage.getItem(ENTITY);
  const userID = localStorage.getItem(USER_ID);

  return {
    userID,
    userName,
    userEmail,
    entity,
    userRole,
    entityID
  };
};

export const getIntercomPayload = () => {
  const userName = localStorage.getItem(USER_NAME);
  const userEmail = localStorage.getItem(USER_EMAIL);
  const userID = localStorage.getItem(USER_ID);
  const userRole = localStorage.getItem(ROLE);
  const entityName = localStorage.getItem(ENTITY);
  const intercomHash = localStorage.getItem(INTERCOM_HASH);

  return {
    user_id: userID,
    email: userEmail,
    name: userName,
    role: userRole,
    entity_name: entityName,
    user_hash: intercomHash
  };
};

export const bootLogRocket = (userID, userName) => {
  // Only boot logrocket if key exists and is in a production environment
  if (
    process.env.NODE_ENV === "production" &&
    process.env.REACT_APP_LOG_ROCKET_KEY
  ) {
    LogRocket.init(process.env.REACT_APP_LOG_ROCKET_KEY);
    LogRocket.identify(userID, {
      name: userName
    });
  }
};
