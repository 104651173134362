import { replace, upperCase, floor, round } from "lodash";

export const ellipsis = (v, maxlen = 24) =>
  v && v.length > 24 ? `${v.slice(0, 23)}...` : v;

export const formatCurrency = (number, cents = false) => {
  const currencyValue = cents ? number / 100 : number;
  const rounded = round(currencyValue);
  return Number(rounded).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0
  });
};

// Prepends partner's root_url (if they have it) or the current hostname to '/hello/'. For decoration purposes only.
export const formatLandingPageRoot = rootUrl => {
  if (rootUrl == null) {
    return `${window.location.origin}/hello/`;
  } else {
    return `${rootUrl}/hello/`;
  }
};

export const formatDateTimezone = date => {
  const tempDate = new Date(date);
  return new Date(
    // current time in milliseconds + the time difference
    // between UTC time and local time(in minutes) * 60 * 1000
    tempDate.getTime() + tempDate.getTimezoneOffset() * 60000
  );
};

export const formatAddress = (
  streetOne = "",
  streetTwo = "",
  city = "",
  state = "",
  zip = ""
) => {
  return `${streetOne}, ${streetTwo} ${city}, ${state} ${zip}`;
};

export const formatWordsUppercase = (formatString, matchWords) => {
  // takes replaceWords array and makes regex expression to match on
  // for single word match, still supply it as an array. e.g. ["id"]
  const re = new RegExp(matchWords.join("|"), "gi");
  return replace(formatString, re, match => upperCase(match));
};

export const toPercent = (num1, num2) => {
  return `${floor((num1 / num2) * 100)}%`;
};
