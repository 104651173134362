import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { connect } from "react-redux";
import compose from "recompose/compose";
import moment from "moment";
import { Link } from "react-router-dom";
import FlatButton from "material-ui/FlatButton";
import EditContent from "material-ui/svg-icons/editor/mode-edit";
import CircularProgress from "@material-ui/core/CircularProgress";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import {
  SYSTEM_ADMIN,
  PARTNER_ADMIN,
  PARTNER_MANAGER,
  EMPLOYERS_ADMIN,
  EMPLOYER_MANAGER,
  SUPPORT_REP
} from "constants/userRoles";
import { SLGCard, InfoBlock, InfoField, ImpersonationButton } from "components";
import SendSvg from "components/svg/sendSvg";
import { formatDateTimezone } from "helpers/formattingHelpers";
import { WithPermission } from "auth";
import { openModal } from "app/uiActions";
import { selectEntityIdentifiers } from "partners/selectors";
import { EmployeeEditModal } from "./modals";
import { sendEmployeeInvite, fetchEmployee } from "../actions";
import { selectEmployeeByID } from "employees/selectors";
import ContributionList from "../contribution/ContributionList";
import NotesList from "./notes/NotesList";

const styles = {
  container: { display: "flex", flexDirection: "column" },
  columnContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "3%"
  },
  cardTitle: {
    marginBottom: "10px",
    fontSize: "1.5em",
    fontWeight: "bold"
  },
  infoBlockContainer: { display: "flex", flexWrap: "wrap" },
  buttonIcon: { height: "20px" },
  editButton: { color: "#00acc1" },
  addLabel: { color: "#ffffff" },
  textLink: { cursor: "pointer", textDecoration: "none", color: "#00acc1" }
};

const KeyboardBackspace = styled(KeyboardBackspaceIcon)`
  color: white;
`;

export class EmployeeShow extends React.Component {
  static propTypes = {
    employee: PropTypes.object,
    employeeID: PropTypes.string.isRequired,
    employerName: PropTypes.string,
    sendEmployeeInvite: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
    employerID: PropTypes.string,
    fetchEmployee: PropTypes.func.isRequired
  };

  static defaultProps = {
    employee: null,
    employerName: "",
    employerID: ""
  };

  componentDidMount() {
    const { employee, fetchEmployee, employeeID } = this.props;
    if (!employee) {
      fetchEmployee(employeeID);
    }
  }

  getInviteValue() {
    const { employee } = this.props;
    return employee.invitation_status;
  }

  render() {
    const {
      employee,
      employeeID,
      employerID,
      openModal,
      sendEmployeeInvite,
      entityIdentifiers
    } = this.props;
    const formatDate = date => {
      return date && moment(formatDateTimezone(date)).format("MMMM DD, YYYY");
    };
    const loadingIcon = <CircularProgress size={25} thickness={2} />;

    let personalId;
    if (employee && employee.personal_ids && employee.personal_ids.length > 0) {
      personalId = employee.personal_ids[0].pid;
    }

    return (
      <div style={styles.container}>
        <div style={styles.columnContainer}>
          <SLGCard
            title={`${entityIdentifiers.employeeIdentifier} INFO`.toUpperCase()}
            flexPercent={60}
            style={{ marginRight: "10px" }}
            button={
              <span>
                <Link to={`/employers/${employerID}/employees/list`}>
                  <FlatButton
                    labelStyle={{ color: "#ffffff" }}
                    label={`Back to ${entityIdentifiers.employeeIdentifier}s`}
                    icon={<KeyboardBackspace />}
                  />
                </Link>
                {employee && (
                  <React.Fragment>
                    {!employee.invitation_accepted_at && (
                      <WithPermission
                        value={[
                          SYSTEM_ADMIN,
                          PARTNER_ADMIN,
                          EMPLOYERS_ADMIN,
                          EMPLOYER_MANAGER,
                          SUPPORT_REP
                        ]}
                      >
                        <FlatButton
                          labelStyle={{ color: "#ffffff" }}
                          label="Send Invite"
                          icon={<SendSvg style={{ fill: "#ffffff" }} />}
                          onClick={() => sendEmployeeInvite(employee)}
                        />
                      </WithPermission>
                    )}
                    <WithPermission
                      value={[
                        SYSTEM_ADMIN,
                        PARTNER_ADMIN,
                        EMPLOYERS_ADMIN,
                        EMPLOYER_MANAGER
                      ]}
                    >
                      <FlatButton
                        label="Edit"
                        labelStyle={{ color: "#ffffff" }}
                        icon={
                          <EditContent
                            color="white"
                            style={styles.buttonIcon}
                          />
                        }
                        onClick={() =>
                          openModal(
                            <EmployeeEditModal selectedEmployee={employee} />
                          )
                        }
                        style={{ overflow: "inherit" }}
                      />
                    </WithPermission>
                  </React.Fragment>
                )}
              </span>
            }
          >
            {employee ? (
              <React.Fragment>
                <div style={styles.cardTitle}>
                  {employee.full_name || employee.email}
                </div>
                <div style={styles.infoBlockContainer}>
                  <InfoBlock flexPercent={50}>
                    <InfoField header="Personal ID" value={personalId} />
                    <InfoField header="Email" value={employee.email} />
                    <InfoField
                      header="Group"
                      link={`/employers/${employerID}/groups/${employee.group_id}`}
                      value={employee.group_name}
                    />
                    <InfoField
                      header="Active"
                      value={
                        employee.employee_status === "active" ? "Yes" : "No"
                      }
                    />
                    <InfoField
                      header="Employment Start Date"
                      value={formatDate(employee.vested_started_on)}
                    />
                  </InfoBlock>
                  <InfoBlock flexPercent={50}>
                    <InfoField header="Invite" value={this.getInviteValue()} />
                    <InfoField
                      header="Created On"
                      value={formatDate(employee.created_at)}
                    />
                    <InfoField
                      header="Updated On"
                      value={formatDate(employee.updated_at)}
                    />
                  </InfoBlock>
                </div>
                <WithPermission
                  value={[
                    SYSTEM_ADMIN,
                    PARTNER_ADMIN,
                    EMPLOYERS_ADMIN,
                    EMPLOYER_MANAGER,
                    SUPPORT_REP
                  ]}
                >
                  <ImpersonationButton employeeID={employee.id} />
                </WithPermission>
              </React.Fragment>
            ) : (
              loadingIcon
            )}
          </SLGCard>
        </div>
        <div style={styles.columnContainer}>
          <ContributionList employeeId={employeeID} />
        </div>
        <div style={styles.columnContainer}>
          <WithPermission
            value={[
              SYSTEM_ADMIN,
              PARTNER_ADMIN,
              PARTNER_MANAGER,
              EMPLOYERS_ADMIN,
              EMPLOYER_MANAGER,
              SUPPORT_REP
            ]}
          >
            <NotesList employeeId={employeeID} />
            {/* <SLGCard
              title="NOTES"
              button={
                <FlatButton
                  label="New Note"
                  labelStyle={styles.addLabel}
                  icon={<AddIcon color="white" style={styles.buttonIcon} />}
                  onClick={() =>
                    openModal(<NoteCreateModal selectedEmployee={employee} />)
                  }
                  style={{ overflow: "inherit" }}
                />
              }
              flexPercent={60}
            >
              <NotesList employeeID={employeeID} />
            </SLGCard> */}
          </WithPermission>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    employee: selectEmployeeByID(state, props.employeeID),
    entityIdentifiers: selectEntityIdentifiers(state)
  };
};
const mapDispatchToProps = (_dispatch, _ownProps) => {
  return { openModal, sendEmployeeInvite, fetchEmployee };
};
const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps()
  )
);

export default enhance(EmployeeShow);
