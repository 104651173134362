import React from "react";
import PropTypes from "prop-types";

import Card from "packages/mui-dashboard/components/Card/Card.jsx";
import CardBody from "packages/mui-dashboard/components/Card/CardBody.jsx";
import Table from "packages/mui-dashboard/components/Table/Table.jsx";

import {
  VaultCardHeaderIcon,
  StyledGridContainer,
  StyledGridItem,
  Loading
} from "components";

/*
 * Desc: A card containg both a table and a graph
 * Ex:
 * <TableGraph
 *   title: "Enrollment"
 *   data=[['val1a', 'val1b'],['val2a', 'val2b']]
 *   icon: <School />
 *    graph={
 *      <FlexibleXYPlot
 *        xType="ordinal"
 *        xDistance={100}
 *        fill="#ff7843"
 *        stroke="#ff7843"
 *      >
 *      <VerticalGridLines />
 *      <HorizontalGridLines />
 *        <XAxis />
 *        <YAxis />
 *        <BarSeries data={greenData} barWidth={0.25} />
 *        <LabelSeries data={labelData} getLabel={d => d.x} />
 *      </FlexibleXYPlot>
 *    }
 * />
 */

const TableGraph = props => {
  const { icon, title, tableData, graph, isLoading } = props;
  return (
    <Card>
      <VaultCardHeaderIcon icon={icon} title={title} color="info" />
      <CardBody>
        <StyledGridContainer justify="space-between">
          {!isLoading ? (
            <React.Fragment>
              <StyledGridItem xs>
                <Table tableData={tableData} />
              </StyledGridItem>
              {graph && <StyledGridItem xs>{graph}</StyledGridItem>}
            </React.Fragment>
          ) : (
            <Loading />
          )}
        </StyledGridContainer>
      </CardBody>
    </Card>
  );
};

TableGraph.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  icon: PropTypes.element.isRequired,
  graph: PropTypes.element,
  tableData: PropTypes.arrayOf(PropTypes.array),
  isLoading: PropTypes.bool
};

TableGraph.defaultProps = { tableData: [], isLoading: false, graph: null };

export default TableGraph;
