import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { get } from "lodash";
import moment from "moment";

import { TextField as TextInput } from "redux-form-material-ui";
import RaisedButton from "material-ui/RaisedButton";
import FlatButton from "material-ui/FlatButton";

import { required, maxLength280 } from "helpers";
import { selectEmployeeNoteByID } from "employees/selectors";

const styles = {
  inputUnderline: { borderColor: "#258ffa" },
  inputLabel: { color: "#00acc1" },
  raisedButton: { borderRadius: "3px" },
  halfWidth: { width: "75%" },
  formButtonsBar: { marginTop: "20px", float: "right" },
  buttonIcon: { height: "20px" },
  inline30: { display: "inline-block", width: "30%" },
  spaceRight: { marginRight: "5%" }
};

export class NoteForm extends Component {
  render() {
    const {
      handleSubmit,
      handlePrev,
      submitButtonLabel,
      formName,
      currentNote
    } = this.props;

    const createdOn =
      get(currentNote, "created_at") &&
      moment(get(currentNote, "created_at")).format("MMMM DD, YYYY");

    const updatedOn =
      get(currentNote, "updated_at") &&
      moment(get(currentNote, "updated_at")).format("MMMM DD, YYYY");

    const authorName = `${get(currentNote, "admin_users.first_name")} ${get(
      currentNote,
      "admin_users.last_name"
    )}`;

    return (
      <div>
        <form onSubmit={handleSubmit}>
          <div>
            <Field
              name="note"
              component={TextInput}
              floatingLabelText="Note *"
              floatingLabelShrinkStyle={styles.inputLabel}
              floatingLabelFixed={true}
              underlineStyle={styles.inputUnderline}
              fullWidth={true}
              multiLine={true}
              rows={4}
              rowsMax={4}
              validate={[required, maxLength280]}
            />
          </div>
          {/* Only show these values when editing */}
          {formName === "employeeNoteEdit" && (
            <div>
              <TextInput
                value={createdOn}
                floatingLabelText="Created On"
                floatingLabelFixed={true}
                underlineShow={false}
                style={Object.assign({}, styles.inline30, styles.spaceRight)}
                disabled={true}
              />
              <TextInput
                value={updatedOn}
                floatingLabelText="Updated On"
                floatingLabelFixed={true}
                underlineShow={false}
                style={Object.assign({}, styles.inline30, styles.spaceRight)}
                disabled={true}
              />
              <TextInput
                value={authorName}
                floatingLabelText="Author"
                floatingLabelFixed={true}
                underlineShow={false}
                style={styles.inline30}
                disabled={true}
              />
            </div>
          )}
          <div style={styles.formButtonsBar}>
            <FlatButton
              label="Cancel"
              type="button"
              onClick={handlePrev}
              style={{ marginRight: "12px" }}
            />
            <RaisedButton
              label={submitButtonLabel}
              type="submit"
              buttonStyle={styles.raisedButton}
              style={styles.raisedButton}
              primary={true}
            />
          </div>
        </form>
      </div>
    );
  }
}

NoteForm.propTypes = {
  formName: PropTypes.string.isRequired,
  submitButtonLabel: PropTypes.string,
  currentNote: PropTypes.object
};

NoteForm.defaultProps = {
  submitButtonLabel: "Save"
};

function mapStateToProps(state, props) {
  return {
    form: props.formName,
    currentNote: selectEmployeeNoteByID(state, get(props, "initialValues.id"))
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true
  })(NoteForm)
);
