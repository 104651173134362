import React from "react";
import * as R from "ramda";
import compose from "recompose/compose";
import { format } from "date-fns";
import { connect } from "react-redux";
import UploadIcon from "@material-ui/icons/CloudUpload";
import Tooltip from "@material-ui/core/Tooltip";

/** legacy code, will remove once new form is done */
import { openModal } from "app/uiActions";
import EligibilityFileUploadDialog from "../modals/EligibilityFileUpload";
import { EmployeeCreateModal } from "../modals";
import { batchCreateEligibleEmployeesRequest } from "employers/actions/bulkUploadActions";
import { selectEntityIdentifiers } from "partners/selectors";
import { allowEmployerManager } from "services/permission/service";
import useDownloadFile from "components/containers/utils/useDownloadFile";
import Button from "components/buttons/action/CardActionButton";
import withStartIcon from "components/buttons/action/withStartIcon";
import NewButton from "components/buttons/action/New";
import DownloadButton from "components/buttons/action/Download";

const UploadButton = withStartIcon(UploadIcon)(Button);
const PageAction = ({
  employerId,
  employer,
  openModal,
  batchCreateEligibleEmployeesRequest,
  stateProps: { requestData },
  entityIdentifiers,
  ...props
}) => {
  const { download } = useDownloadFile();
  const ultiproEnabled = R.pathOr(false, ["ultipro_ready"], employer);

  const handleEligibilityFileUploadClick = () => {
    openModal(
      <EligibilityFileUploadDialog
        onUploadClick={handleEligibilityFileUpload}
        employerID={employerId}
      />
    );
  };
  const handleEligibilityFileUpload = (file, employeesList) => {
    batchCreateEligibleEmployeesRequest(employerId, file, employeesList);
  };

  const handleDownloadClick = () => {
    const saveAs = `${entityIdentifiers.employeeIdentifier}s_${format(
      new Date(),
      "yyyy-MM-dd"
    )}.csv`;
    const pathName = entityIdentifiers.employeeIdentifier
      ? entityIdentifiers.employeeIdentifier.toLowerCase()
      : "employee";
    const path = `/${pathName}s.csv`;
    const filter = { "0": { employer_id_eq: employerId } };
    download({ path, saveAs, fileType: "csv", params: { filter } });
  };

  const handleNewClick = () => {
    const handleSuccess = () => requestData();

    openModal(
      <EmployeeCreateModal employerID={employerId} onSuccess={handleSuccess} />
    );
  };

  const AddEmployee = React.forwardRef((localProps, ref) => (
    <span {...localProps} ref={ref}>
      <NewButton
        disabled={ultiproEnabled || props.disabled}
        onClick={handleNewClick}
      >
        {`New ${entityIdentifiers.employeeIdentifier}`}
      </NewButton>
    </span>
  ));

  return allowEmployerManager() ? (
    <>
      <UploadButton
        disabled={props.disabled}
        onClick={handleEligibilityFileUploadClick}
      >
        Bulk Upload
      </UploadButton>
      <DownloadButton disabled={props.disabled} onClick={handleDownloadClick}>
        {`Download ${entityIdentifiers.employeeIdentifier} CSV`}
      </DownloadButton>
      {ultiproEnabled ? (
        <Tooltip
          title={`${entityIdentifiers.employeeIdentifier} creation is disabled due to Ultimate Integration`}
        >
          <AddEmployee />
        </Tooltip>
      ) : (
        <AddEmployee />
      )}
    </>
  ) : null;
};

const mapStateToProps = state => {
  const entityIdentifiers = selectEntityIdentifiers(state);
  return { entityIdentifiers };
};
const mapDispatchToProps = () => {
  return { openModal, batchCreateEligibleEmployeesRequest };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps()
  )
)(PageAction);
