import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import compose from "recompose/compose";
import moment from "moment";

import FlatButton from "material-ui/FlatButton";
import EditContent from "material-ui/svg-icons/editor/mode-edit";

import { fetchAdminUsers as fetchAdminUsersAction } from "../adminUsers/adminUserActions";
import { AdminUserEditModal } from "../adminUsers/modals";
import { openModal as openModalAction } from "../app/uiActions";
import { SLGTableColumn, SLGList } from "../components";
import { WithPermission } from "../auth";
import {
  SYSTEM_ADMIN,
  PARTNER_ADMIN,
  EMPLOYERS_ADMIN,
  EMPLOYER_MANAGER
} from "../constants";
import { makeSelectAdminUsersByEmployer } from "../adminUsers/selectors";
import SearchForm from "../components/SearchForm";

const styles = {
  header: { display: "flex", justifyContent: "space-between" },
  rowField: { textAlign: "center" },
  columnHeader: { textAlign: "center" },
  inactive: { color: "#FF0000" },
  editButton: { color: "#00acc1" }
};

export class AdminUsersList extends Component {
  /**
   * Fetches Benefits Admins with the search query.
   *
   * TODO: Abstract query params from all tables and query functions into the redux store so they become composable
   * and work together.
   *
   * @param {string} searchTerm
   * @return {Promise}
   */
  fetchAdminUsersQuery = searchTerm => {
    const { adminUserType, fetchAdminUsers, employerID } = this.props;

    return fetchAdminUsers({ adminUserType, employerID }, null, 1, searchTerm);
  };

  static propTypes = {
    adminUsers: PropTypes.array,
    total: PropTypes.number.isRequired,
    isLoading: PropTypes.bool.isRequired,
    fetchAdminUsers: PropTypes.func.isRequired,

    // type of admin_user desired to be returned e.g. system_admin, employers_admin, etc.
    adminUserType: PropTypes.string
  };

  static defaultProps = {
    adminUsers: []
  };

  render() {
    const {
      adminUsers,
      fetchAdminUsers,
      openModal,
      employerID,
      total,
      isLoading,
      adminUserType
    } = this.props;
    const params = [];

    // See AdminUserActions props
    // Setting these up in the same object because that's what is required of the fetch action
    const typeEmployer = employerID ? { employerID } : {};
    if (adminUserType) {
      typeEmployer.adminUserType = adminUserType;
    }

    params.push(typeEmployer);

    return (
      <div>
        <SearchForm onSubmit={this.fetchAdminUsersQuery} />
        <SLGList
          fetchData={fetchAdminUsers}
          params={params}
          data={adminUsers}
          total={total}
          isLoading={isLoading}
          paginate={false}
        >
          <SLGTableColumn
            field="first_name"
            title="Name"
            sortable={true}
            render={val => `${val.first_name} ${val.last_name}`}
          />
          <SLGTableColumn field="email" title="Email" sortable={true} />
          <SLGTableColumn field="type" title="Role" />
          <SLGTableColumn
            field="active"
            rowStyle={styles.rowField}
            headerStyle={styles.columnHeader}
            render={val =>
              val.active ? "Yes" : <span style={styles.inactive}>No</span>
            }
          />
          <SLGTableColumn
            field="invitation_accepted_at"
            title="Registered"
            rowStyle={styles.rowField}
            headerStyle={styles.columnHeader}
            render={val =>
              val.invitation_accepted_at
                ? moment(val.invitation_accepted_at).format("MM/DD/YYYY")
                : "No"
            }
          />
          <SLGTableColumn
            rowStyle={styles.rowField}
            headerStyle={styles.columnHeader}
            render={val => (
              <WithPermission
                value={[
                  SYSTEM_ADMIN,
                  PARTNER_ADMIN,
                  EMPLOYERS_ADMIN,
                  EMPLOYER_MANAGER
                ]}
              >
                <FlatButton
                  label="Edit"
                  labelStyle={styles.editButton}
                  icon={
                    <EditContent
                      color={styles.editButton.color}
                      style={styles.buttonIcon}
                    />
                  }
                  onClick={() =>
                    openModal(<AdminUserEditModal adminUser={val} />)
                  }
                  style={{ overflow: "inherit" }}
                />
              </WithPermission>
            )}
          />
        </SLGList>
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  const selectAdminUsersByEmployer = makeSelectAdminUsersByEmployer();
  return {
    adminUsers: selectAdminUsersByEmployer(state, props.employerID),
    total: state.adminUsers.total,
    isLoading: state.adminUsers.isFetching
  };
}

const enhance = compose(
  connect(
    mapStateToProps,
    {
      fetchAdminUsers: fetchAdminUsersAction,
      openModal: openModalAction
    }
  )
);

export default enhance(AdminUsersList);
