import React from "react";
import PropTypes from "prop-types";
import SLGModalForm from "components/modals/SLGModalForm";
import { GroupForm } from "../forms";

export const GroupCreateModal = ({
  onClose,
  onSuccess,
  employerID,
  employerPayEnabled
}) => {
  const formName = "groupCreate";

  return (
    <SLGModalForm
      title="New Group"
      formName={formName}
      close={onClose}
      contentStyle={{ width: "700px" }}
    >
      <GroupForm
        formName={formName}
        label="Group"
        employerID={employerID}
        employerPayEnabled={employerPayEnabled}
        onSuccess={onSuccess}
      />
    </SLGModalForm>
  );
};

GroupCreateModal.propTypes = {
  employerID: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  employerPayEnabled: PropTypes.bool
};

GroupCreateModal.defaultProps = {
  employerPayEnabled: false
};

export default GroupCreateModal;
