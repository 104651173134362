import React from "react";
import * as R from "ramda";

import {
  centsToMoneyString,
  toDisplayDateStringFromProp,
  centsToMoneyStringFromProp
} from "services/formatters";
import { PageComponent, withProps } from "components/containers/ListPage";
import withEmployerIdFromRouter from "components/containers/utils/withEmployerIdFromRouter";
import withSort from "components/containers/withSort";
import withSearch from "components/containers/withSearch";
import withGetFetch from "components/containers/withGetFetch";
import combineInvoices from "./utils/combineInvoices";
import ActionsCell from "./components/ActionsCell";
import NameCell from "./components/NameCell";

const formatCreatedAt = toDisplayDateStringFromProp("created_at");
const formatFunds = centsToMoneyStringFromProp("total_pdrs_cents");
const formatFees = centsToMoneyStringFromProp("total_fees_cents");
const formatDues = v =>
  centsToMoneyString(
    R.propOr(0, "total_pdrs_cents", v) + R.propOr(0, "total_fees_cents", v)
  );
const renderActionsCell = v => <ActionsCell value={v} />;
const renderNameCell = employerId => v => (
  <NameCell value={v} employerId={employerId} />
);

const columns = employerId => [
  {
    name: "invoice_name",
    label: "Name",
    render: renderNameCell(employerId)
  },
  {
    name: "total_pdrs_cents",
    disableSort: true,
    label: "Funds",
    render: formatFunds
  },
  {
    name: "total_fees_cents",
    disableSort: true,
    label: "Fees",
    render: formatFees
  },
  { name: "id", label: "Due", disableSort: true, render: formatDues },
  { name: "status", label: "Status" },
  { name: "created_at", label: "Created On", render: formatCreatedAt },
  { name: "id", label: "Actions", disableSort: true, render: renderActionsCell }
];

const createGetSearchFilterWith = employerId =>
  R.applySpec({
    "0": { employer_id_eq: R.always(employerId) },
    "1": {
      status_cont: R.identity
    }
  });
function extraProps(props) {
  return {
    title: "Invoices",
    path: "/invoices",
    columns: columns(props.employerId),
    getSearchFilter: createGetSearchFilterWith(props.employerId)
  };
}

const getRows = props => ({ rows: combineInvoices(props.rows) });
export default R.compose(
  withEmployerIdFromRouter,
  withProps(extraProps),
  withSort,
  withSearch,
  withGetFetch,
  withProps(getRows)
)(PageComponent);
