import * as R from "ramda";

export const SYSTEM_ADMIN = "system_admin";
export const PARTNER_ADMIN = "partner_admin";
export const EMPLOYERS_ADMIN = "employers_admin";
export const BENEFITS_ADMIN = "benefits_admin";
export const PARTNER_MANAGER = "partner_manager";
export const EMPLOYER_MANAGER = "employer_manager";
export const SUPPORT_REP = "support_rep";

const getRole = () => localStorage.getItem("role");
export const admins = [SYSTEM_ADMIN, PARTNER_ADMIN, EMPLOYERS_ADMIN];
export const benefitsAdmin = [BENEFITS_ADMIN];
export const managers = [PARTNER_MANAGER, EMPLOYER_MANAGER];
export const allowWith = roles => R.includes(getRole(), roles);
export const adminsWith = R.concat(admins);
export const without = R.without;
export const allowToPartnerAdmin = () =>
  allowWith([SYSTEM_ADMIN, PARTNER_ADMIN]);
export const allowAdmins = () => allowWith(admins);
export const allowToPartnerManager = () =>
  allowWith(adminsWith([PARTNER_MANAGER]));
export const allowToEmployerManager = () =>
  allowWith(adminsWith([BENEFITS_ADMIN, PARTNER_MANAGER, EMPLOYER_MANAGER]));
export const allowToManagers = () => allowWith(adminsWith(managers));
export const allowEmployerManager = () =>
  allowWith(adminsWith([BENEFITS_ADMIN, EMPLOYER_MANAGER]));
export const allowToSupportRep = () =>
  allowWith(
    adminsWith([BENEFITS_ADMIN, PARTNER_MANAGER, EMPLOYER_MANAGER, SUPPORT_REP])
  );
