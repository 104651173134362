import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { get } from "lodash";
import { Link } from "react-router-dom";
import { Card, CardText, CardHeader } from "material-ui/Card";
import getMuiTheme from "material-ui/styles/getMuiTheme";
import FlatButton from "material-ui/FlatButton";
import RaisedButton from "material-ui/RaisedButton";
import EditContent from "material-ui/svg-icons/editor/mode-edit";
import AddIcon from "material-ui/svg-icons/content/add";
import DeleteIcon from "material-ui/svg-icons/action/delete";
import SendIcon from "material-ui/svg-icons/content/send";
import { theme } from "app";
import { openModal } from "app/uiActions";
import { SLGTableColumn, SLGList, InfoIcon } from "components";
import { DeleteConfirmationModal } from "components/modals";
import { fetchGroups, deleteGroup } from "groups/actions";
import { sendInvitesRequest } from "groups/actions/ultiproActions";
import {
  SYSTEM_ADMIN,
  PARTNER_ADMIN,
  PARTNER_MANAGER,
  EMPLOYERS_ADMIN,
  EMPLOYER_MANAGER,
  SORT_ASC
} from "constants/index";
import { WithPermission } from "auth";
import { GroupCreateModal, GroupEditModal } from "groups/components/modals";
import { selectGroupsByEmployer } from "groups/selectors/index";
import { selectEntityIdentifiers } from "partners/selectors";
import { formatCurrency } from "helpers";

const styles = {
  cardTitle: { fontWeight: "bold", fontSize: "1.5em" },
  header: { display: "flex", justifyContent: "space-between" },
  textLink: { cursor: "pointer", textDecoration: "none" },
  editButton: { color: "#00acc1" },
  deleteButton: { color: "#9d1607" },
  disabledButton: { color: "#0000004d" },
  buttonColumn: { textAlign: "right", width: "10%" },
  iconColumnHeader: { width: "3%" },
  iconColumn: { overflow: "visible", padding: 0 }
};

export const GroupsList = ({
  groups,
  total,
  isLoading,
  employer,
  employerID,
  employerLocked,
  employerPayEnabled,
  fetchGroups,
  deleteGroup,
  onGroupSelect,
  openModal,
  sendInvitesRequest,
  entityIdentifiers
}) => {
  const params = employerID ? [employerID] : [];
  const primaryColor = getMuiTheme(theme).palette["primary1Color"];
  const ultiproEnabled = employer && employer.ultipro_ready;

  return (
    <Card>
      <CardHeader
        title={
          <span
            style={styles.cardTitle}
          >{`${entityIdentifiers.employeeIdentifier} Groups`}</span>
        }
        style={styles.header}
      >
        <WithPermission
          value={[
            SYSTEM_ADMIN,
            PARTNER_ADMIN,
            EMPLOYERS_ADMIN,
            EMPLOYER_MANAGER
          ]}
        >
          <RaisedButton
            primary={true}
            buttonStyle={styles.raisedButton}
            style={styles.raisedButton}
            icon={<AddIcon />}
            onClick={() =>
              openModal(
                <GroupCreateModal
                  employerID={employerID}
                  employerPayEnabled={employerPayEnabled}
                />
              )
            }
            label="New Group"
            disabled={isLoading || employerLocked}
          />
        </WithPermission>
      </CardHeader>
      <CardText>
        <SLGList
          fetchData={fetchGroups}
          params={params}
          data={groups}
          total={total}
          isLoading={isLoading}
          noDataText="No Groups"
          sort={{ field: "created_at", order: SORT_ASC }}
        >
          <SLGTableColumn
            sortable={true}
            field="name"
            title="Name"
            render={val => {
              return (
                <Link
                  to={`/employers/${employerID}/groups/${val.id}`}
                  style={{ ...styles.textLink, color: primaryColor }}
                >
                  {val.name}
                </Link>
              );
            }}
          />
          <SLGTableColumn
            field="pay_enabled?"
            title="Pay"
            render={val => (val["pay_enabled?"] ? "Yes" : "No")}
          />
          <SLGTableColumn field="employees_count" title="EE's" />
          <SLGTableColumn field="active_employees_count" title="Active EE's" />
          <SLGTableColumn
            title="Pay EE's"
            field="pay_employees_count"
            render={val => (val["pay_enabled?"] ? val.pay_employees_count : 0)}
          />
          <SLGTableColumn
            field="services_attributes[0].match_amount_cents"
            title="Amount"
            render={val => {
              const contributionAmountCents = get(
                val,
                "services_attributes.[0].match_amount_cents"
              );
              return contributionAmountCents
                ? formatCurrency(contributionAmountCents, true)
                : "N/A";
            }}
          />
          <SLGTableColumn
            field="services_attributes[0].lifetime_cap_cents"
            title="Lifetime Cap"
            render={val => {
              const lifetimeCapCents = get(
                val,
                "services_attributes.[0].lifetime_cap_cents"
              );
              return lifetimeCapCents
                ? formatCurrency(lifetimeCapCents, true)
                : "N/A";
            }}
          />
          {ultiproEnabled && (
            <SLGTableColumn
              title="Send Invites"
              render={val => {
                return (
                  <FlatButton
                    icon={
                      <SendIcon
                        color={styles.editButton.color}
                        style={styles.buttonIcon}
                      />
                    }
                    onClick={() => {
                      sendInvitesRequest(employerID, val.id);
                    }}
                    style={{ overflow: "inherit" }}
                  />
                );
              }}
            />
          )}
          <SLGTableColumn
            rowStyle={styles.buttonColumn}
            render={val => {
              const locked = employerLocked || val["locked?"];

              return (
                <WithPermission
                  value={[
                    SYSTEM_ADMIN,
                    PARTNER_ADMIN,
                    EMPLOYERS_ADMIN,
                    EMPLOYER_MANAGER
                  ]}
                >
                  <FlatButton
                    label="Edit"
                    labelStyle={
                      locked ? styles.disabledButton : styles.editButton
                    }
                    icon={
                      <EditContent
                        color={
                          locked
                            ? styles.disabledButton.color
                            : styles.editButton.color
                        }
                        style={styles.buttonIcon}
                      />
                    }
                    onClick={() =>
                      openModal(
                        <GroupEditModal
                          group={val}
                          employerID={employerID}
                          employerPayEnabled={employerPayEnabled}
                        />
                      )
                    }
                    style={{ overflow: "inherit" }}
                    disabled={locked}
                  />
                </WithPermission>
              );
            }}
          />
          <SLGTableColumn
            rowStyle={styles.buttonColumn}
            render={val => {
              const locked = employerLocked || val["locked?"];

              return val.name === "default" ? null : (
                <WithPermission
                  value={[
                    SYSTEM_ADMIN,
                    PARTNER_ADMIN,
                    EMPLOYERS_ADMIN,
                    EMPLOYER_MANAGER
                  ]}
                >
                  <FlatButton
                    label="Delete"
                    icon={<DeleteIcon style={styles.deleteColor} />}
                    labelPosition="after"
                    style={locked ? styles.disabledButton : styles.deleteButton}
                    onClick={() =>
                      openModal(
                        <DeleteConfirmationModal
                          title="Delete Group"
                          deleteAction={() => {
                            deleteGroup(val.id, employerID);
                          }}
                          resourceName="group"
                          name={val.name}
                          message={
                            'Deleting this group will move all assigned users to "Default" group.'
                          }
                        />
                      )
                    }
                    disabled={locked}
                  />
                </WithPermission>
              );
            }}
          />
          <SLGTableColumn
            headerStyle={styles.iconColumnHeader}
            rowStyle={styles.iconColumn}
            render={val => {
              return employerLocked || val["locked?"] ? (
                <WithPermission
                  value={[
                    SYSTEM_ADMIN,
                    PARTNER_ADMIN,
                    PARTNER_MANAGER,
                    EMPLOYERS_ADMIN,
                    EMPLOYER_MANAGER
                  ]}
                >
                  <InfoIcon
                    tooltipPosition="top-left"
                    iconStyle={styles.disabledButton}
                    tooltip={
                      <span>
                        Contributions are currently being processed.
                        <br />
                        Cannot update groups at this time.
                      </span>
                    }
                  />
                </WithPermission>
              ) : null;
            }}
          />
        </SLGList>
      </CardText>
    </Card>
  );
};

GroupsList.propTypes = {
  groups: PropTypes.array,
  employer: PropTypes.object,
  employerID: PropTypes.string,
  employerLocked: PropTypes.bool,
  employerPayEnabled: PropTypes.bool,
  total: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  fetchGroups: PropTypes.func.isRequired,
  onGroupSelect: PropTypes.func.isRequired,
  deleteGroup: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired
};

GroupsList.defaultProps = {
  groups: []
};

const mapStateToProps = (state, props) => {
  const entityIdentifiers = selectEntityIdentifiers(state);

  return {
    groups: selectGroupsByEmployer(state, props.employerID),
    total: state.groups.total,
    isLoading: state.groups.isFetching,
    entityIdentifiers
  };
};

const mapDispatchToProps = (_dispatch, _ownProps) => {
  return {
    openModal,
    fetchGroups,
    deleteGroup,
    sendInvitesRequest
  };
};

const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps()
  )
);

export default enhance(GroupsList);
