import { put, takeLatest, all, call } from "redux-saga/effects";
import { showNotification } from "../app/uiActions";
import { push } from "react-router-redux";
import slgRestClient from "../rest/slgRestClient";
import { CREATE, UPDATE } from "../rest/types";
import {
  EMAIL_RESET_PASSWORD,
  EMAIL_RESET_PASSWORD_SUCCESS,
  EMAIL_RESET_PASSWORD_FAILURE,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE
} from "./passwordActions";
import log from "../services/logging/service";

function requestEmailResetPassword(data) {
  return slgRestClient(CREATE, "passwords", { data })
    .then(resp => resp.data)
    .catch(e => log.error(e.message));
}

function* emailResetPassword(action) {
  try {
    yield call(requestEmailResetPassword, action.payload.data);
    yield put({ type: EMAIL_RESET_PASSWORD_SUCCESS });
    yield put(showNotification("Password reset link sent"));
  } catch (e) {
    yield put({ type: EMAIL_RESET_PASSWORD_FAILURE, message: e.message });
    yield put(
      showNotification(
        "An error occurred while requesting your password reset link",
        "warning"
      )
    );
    log.error(e.message);
  }
}

function requestResetPassword(data) {
  return slgRestClient(UPDATE, "passwords", { data })
    .then(resp => resp.data)
    .catch(e => log.error(e.message));
}

function* resetPassword(action) {
  try {
    yield call(requestResetPassword, action.payload.data);
    yield put({ type: RESET_PASSWORD_SUCCESS });
    yield put(showNotification("Password reset successfully!"));
    yield put(push("/"));
  } catch (e) {
    yield put({ type: RESET_PASSWORD_FAILURE, message: e.message });
    yield put(
      showNotification(
        "An error occurred while resetting your password",
        "warning"
      )
    );
    log.error(e.message);
  }
}

export default function* passwordSaga() {
  yield all([
    takeLatest(EMAIL_RESET_PASSWORD, emailResetPassword),
    takeLatest(RESET_PASSWORD, resetPassword)
  ]);
}
