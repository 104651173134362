import {
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_ERROR,
  AUTH_CHECK,
  AUTH_GET_PERMISSIONS
} from "./types";

import { ROLE, TOKEN } from "../constants";
import {
  clearAuthDetails,
  setAuthDetails,
  getUserContext,
  bootLogRocket
} from "../helpers/authHelpers";

import Cookies from "universal-cookie";

const apiHost = process.env.REACT_APP_API_HOST;

export default (type, params) => {
  // called when the user attempts to log in
  if (type === AUTH_LOGIN) {
    const { email, password } = params;
    const cookies = new Cookies();
    const attributes = { email, password };

    if (cookies.get("partner_device_id") !== undefined) {
      attributes["partner_device_id"] = cookies.get("partner_device_id");
    }

    const request = new Request(`${apiHost}/sessions`, {
      method: "POST",
      body: JSON.stringify({ data: { attributes } }),
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/vnd.slg+json"
      })
    });

    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }

        return response;
      })
      .then(response => {
        const authToken = response.headers.get("Authorization");

        const res = setAuthDetails(authToken);
        bootLogRocket(res.userID, res.userName);

        return Promise.resolve(res);
      });
  }
  // called when the user clicks on the logout button
  if (type === AUTH_LOGOUT) {
    // Hit DELETE /sessions which will clear the user's device_info
    if (localStorage.getItem("two_factor_enabled") === "true") {
      const request = new Request(`${apiHost}/sessions`, {
        headers: new Headers({
          Accept: "application/vnd.slg+json",
          Authorization: localStorage.getItem(TOKEN),
          "Content-Type": "application/json"
        }),
        method: "DELETE"
      });

      return fetch(request).then(() => {
        return Promise.resolve();
      });
    }

    clearAuthDetails();
    return Promise.resolve();
  }
  // called when the API returns an error
  if (type === AUTH_ERROR) {
    const { status } = params;
    const user_role = localStorage.getItem(ROLE);
    if (status === 403 && user_role === "two_factor") {
      console.log(
        "This action is forbidden because the user needs to verify via 2fa! <DURING AN AUTH_ERROR>"
      );
      return Promise.resolve();
    }

    if (status === 401 || status === 403) {
      clearAuthDetails();
      return Promise.reject();
    }

    return Promise.resolve();
  }
  // called when the user navigates to a new location
  if (type === AUTH_CHECK) {
    // Check for token existence or hit api? -- Figure out how to handle this
    // May not care unless there accessing resources on api
    const token = localStorage.getItem(TOKEN);

    if (!token) {
      clearAuthDetails();
      return Promise.reject();
    }

    const userContext = getUserContext();
    bootLogRocket(userContext.userID, userContext.userName);

    return Promise.resolve(userContext);
  }

  if (type === AUTH_GET_PERMISSIONS) {
    const user_role = localStorage.getItem(ROLE);
    return Promise.resolve(user_role);
  }

  return Promise.reject("Unknown method");
};
