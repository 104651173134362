import { push } from "react-router-redux";
import {
  call,
  put,
  takeEvery,
  all,
  takeLatest,
  fork
} from "redux-saga/effects";
import { destroy } from "redux-form";
import { assign, replace, differenceBy } from "lodash";

import { FETCH_PARTNERS_SUCCESS } from "../partners/partnerActions";
import {
  DOWNLOAD_EMPLOYER_REPORT_SUCCESS,
  DOWNLOAD_EMPLOYER_REPORT_FAILURE,
  FETCH_EMPLOYERS_SUCCESS,
  FETCH_EMPLOYERS_FAILURE,
  FETCH_EMPLOYERS,
  FETCH_EMPLOYER,
  FETCH_EMPLOYER_SUCCESS,
  FETCH_EMPLOYER_FAILURE,
  FETCH_EMPLOYER_STATS,
  FETCH_EMPLOYER_STATS_SUCCESS,
  FETCH_EMPLOYER_STATS_FAILURE,
  CREATE_EMPLOYER,
  CREATE_EMPLOYER_SUCCESS,
  CREATE_EMPLOYER_FAILURE,
  EDIT_EMPLOYER,
  EDIT_EMPLOYER_SUCCESS,
  EDIT_EMPLOYER_FAILURE,
  FETCH_FUNDS_REQUESTS,
  FETCH_FUNDS_REQUESTS_SUCCESS,
  FETCH_FUNDS_REQUESTS_FAILURE
} from "./employerActions";
import { showNotification, closeAllModals } from "../app/uiActions";
import { FETCH_ERROR } from "../auth/authActions";
import slgRestClient from "../rest/slgRestClient";
import { GET_LIST, GET_ONE, CREATE, UPDATE } from "../rest/types";
import {
  formatWordsUppercase,
  getObjectDiff,
  renameAttributes
} from "../helpers";
import { SORT_DESC, PER_PAGE } from "../constants";

function _submissionErrorNotification(errorMessage) {
  const upperMessage = formatWordsUppercase(errorMessage, ["ID", "SSO", "URL"]);
  return showNotification(upperMessage + ", update and resubmit.", "warning");
}

function* downloadEmployerReportSuccess() {
  yield put(showNotification("Report successfully downloaded!"));
}

function* downloadEmployerReportFailure({ error }) {
  yield put(
    showNotification(
      "An error occurred while downloading your report",
      "warning"
    )
  );
  console.error(error);
}

// API Call (Might make sense to separate at a later date)
function requestEmployer(employerID) {
  const params = {
    id: employerID
  };

  return slgRestClient(GET_ONE, "employers", params).then(resp => {
    return resp.data;
  });
}

function* fetchEmployer(action) {
  try {
    const employer = yield call(requestEmployer, action.payload.employerID);
    yield put({ type: FETCH_EMPLOYER_SUCCESS, payload: employer });
  } catch (e) {
    yield put({ type: FETCH_EMPLOYER_FAILURE, message: e.message });
    yield put({ type: FETCH_ERROR, e });
    yield put(
      showNotification("An error occurred while fetching employer", "warning")
    );
    console.log(e);
  }
}

// API Call (Might make sense to separate at a later date)
function requestEmployers(
  partnerID,
  ignorePagination,
  sort,
  page = 1,
  query,
  fields
) {
  sort = sort || { field: "id", order: SORT_DESC };

  const pagination = {};

  if (ignorePagination) {
    // TODO: This needs to be removed !!!!!!!!!!
    pagination.limit = "all";
  } else {
    pagination.page = page;
    pagination.perPage = PER_PAGE;
  }
  const params = {
    // ransack search predicate syntax
    filter: [{ partner_id_eq: partnerID }],
    pagination,
    sort,
    fields
  };

  if (query) {
    // ransack search predicate syntax
    const queryObject = { name_cont: query };
    params.filter.push(queryObject);
  }
  return slgRestClient(GET_LIST, "employers", params).then(resp => {
    return { data: resp.data, total: resp.total };
  });
}

function* fetchEmployers(action) {
  try {
    const employers = yield call(
      requestEmployers,
      action.payload.partnerID,
      action.payload.ignorePagination,
      action.payload.sort,
      action.payload.page,
      action.payload.query,
      action.payload.fields
    );
    yield put({ type: FETCH_EMPLOYERS_SUCCESS, payload: employers });
  } catch (e) {
    yield put({ type: FETCH_EMPLOYERS_FAILURE, message: e.message });
    yield put({ type: FETCH_ERROR, e });
    yield put(
      showNotification("An error occurred while fetching employers", "warning")
    );
    console.log(e);
  }
}

function* fetchPartnersEmployers({ payload }) {
  const data = payload.partners;
  const partners = data.data;
  const total =
    payload.data && Array.isArray(payload.data) ? payload.data.length : 0;
  const limit = total > 25 ? 25 : total;

  for (let i = 0; i < limit; i = i + 1) {
    const partnerID = partners[i].id;
    const payload = {
      partnerID
    };
    yield fork(fetchEmployers, { payload });
  }
}

function requestEmployerBenefitStats(employerID) {
  var requestHeaders = new Headers({
    Accept: "application/vnd.slg+json",
    "Content-Type": "application/json",
    Authorization: localStorage.getItem("token")
  });

  return fetch(
    `${process.env.REACT_APP_API_HOST}/employers/${employerID}/benefit_stats`,
    {
      method: "GET",
      headers: requestHeaders
    }
  )
    .then(response => response.text())
    .then(text => {
      let json = JSON.parse(text);
      return { ...json["data"], benefitStatsLoaded: true };
    });
}

function requestEmployerAdvisorStats(employerID) {
  var requestHeaders = new Headers({
    Accept: "application/vnd.slg+json",
    "Content-Type": "application/json",
    Authorization: localStorage.getItem("token")
  });

  return fetch(
    `${process.env.REACT_APP_API_HOST}/employers/${employerID}/advisor_stats`,
    {
      method: "GET",
      headers: requestHeaders
    }
  )
    .then(response => response.text())
    .then(text => {
      let json = JSON.parse(text);
      return { ...json["data"], advisorStatsLoaded: true };
    });
}

function* fetchEmployerStats(action) {
  try {
    const employerID = action.payload.employerID;
    const advisorStats = yield call(requestEmployerAdvisorStats, employerID);
    const benefitStats = yield call(requestEmployerBenefitStats, employerID);

    yield put({
      type: FETCH_EMPLOYER_STATS_SUCCESS,
      payload: { advisorStats, benefitStats, employerID }
    });
  } catch (e) {
    yield put({ type: FETCH_EMPLOYER_STATS_FAILURE, message: e.message });
    yield put(
      showNotification(
        "An error occurred while fetching employer stats",
        "warning"
      )
    );
    console.log(e);
  }
}

function requestEmployerCreate(data) {
  const createData = renameAttributes(data, [
    "federated_employers",
    "contacts",
    "billing_address"
  ]);
  return slgRestClient(CREATE, "employers", { data: createData }).then(
    resp => resp.data
  );
}

function* createEmployer(action) {
  try {
    const employerCreate = yield call(
      requestEmployerCreate,
      action.payload.data
    );
    yield put({ type: CREATE_EMPLOYER_SUCCESS, payload: employerCreate });
    yield put(destroy(action.formName));
    yield put(closeAllModals());
    yield put(push(`/employers/${employerCreate.id}/dashboard`));
    yield put(showNotification("Employer created successfully"));
  } catch (e) {
    yield put({ type: CREATE_EMPLOYER_FAILURE, message: e.message });
    yield put(
      _submissionErrorNotification(
        replace(e.message, /Landing Page Slug/, "Custom Landing Page URL")
      )
    );
    console.log(e);
  }
}

function requestFundsRequests(employerID, sort, page = 1, query) {
  const sortParams = sort || { field: "id", order: SORT_DESC };

  const params = {
    // ransack search predicate syntax
    filter: [{ employer_id_eq: employerID }],
    pagination: { page, perPage: PER_PAGE }, // Should match api
    sort: sortParams
  };

  if (query) {
    // ransack search predicate syntax
    const queryObject = {
      short_code_cont: query,
      status_cont: query,
      m: "or"
    };
    params.filter.push(queryObject);
  }

  return slgRestClient(GET_LIST, "invoices", params).then(resp => {
    return { data: resp.data, total: resp.total };
  });
}

function* fetchFundsRequests(action) {
  try {
    const employerID = action.payload.employerID;

    const fundsRequests = yield call(
      requestFundsRequests,
      employerID,
      action.payload.sort,
      action.payload.page,
      action.payload.query
    );

    yield put({
      type: FETCH_FUNDS_REQUESTS_SUCCESS,
      payload: { ...fundsRequests, employerID }
    });
  } catch (e) {
    yield put({ type: FETCH_FUNDS_REQUESTS_FAILURE, message: e.message });
    yield put(
      showNotification(
        "An error occurred while fetching Funds Requests",
        "warning"
      )
    );
    console.log(e);
  }
}

function requestEmployerEdit(initialFields, data) {
  // get federated employer ids to destroy
  const destroyed = differenceBy(
    initialFields.federated_employers,
    data.federated_employers,
    "id"
  ).map(val => ({ ...val, _destroy: true }));

  const changedData = {
    ...getObjectDiff(initialFields, data),
    "pay_enabled?": data["pay_enabled?"]
  };

  if (changedData.federated_employers) {
    changedData.federated_employers = changedData.federated_employers.concat(
      destroyed
    );
  }

  const updateData = renameAttributes(changedData, [
    "federated_employers",
    "contacts",
    "billing_address"
  ]);

  return slgRestClient(
    UPDATE,
    "employers",
    assign({ id: data.id }, { data: updateData, previousData: initialFields })
  ).then(resp => resp.data);
}

function* editEmployer(action) {
  try {
    const employerEdit = yield call(
      requestEmployerEdit,
      action.initialFields,
      action.payload.data
    );
    yield put({ type: EDIT_EMPLOYER_SUCCESS, payload: employerEdit });
    yield put(destroy(action.formName));
    yield put(closeAllModals());
    yield put(showNotification("Employer edited successfully"));
  } catch (e) {
    yield put({ type: EDIT_EMPLOYER_FAILURE, message: e.message });
    yield put(
      _submissionErrorNotification(
        replace(e.message, /Landing Page Slug/, "Custom Landing Page URL")
      )
    );
    console.log(e);
  }
}

export default function* employerSaga() {
  yield all([
    takeEvery(DOWNLOAD_EMPLOYER_REPORT_SUCCESS, downloadEmployerReportSuccess),
    takeEvery(DOWNLOAD_EMPLOYER_REPORT_FAILURE, downloadEmployerReportFailure),
    takeLatest(FETCH_EMPLOYERS, fetchEmployers),
    takeLatest(FETCH_EMPLOYER_STATS, fetchEmployerStats),
    takeLatest(FETCH_EMPLOYER, fetchEmployer),
    takeLatest(CREATE_EMPLOYER, createEmployer),
    takeLatest(EDIT_EMPLOYER, editEmployer),
    takeLatest(FETCH_FUNDS_REQUESTS, fetchFundsRequests),
    takeLatest(FETCH_PARTNERS_SUCCESS, fetchPartnersEmployers)
  ]);
}
